<template>
  <div class="box">
    <div class="banner">
      <img src="../img/images/product_banner.png" />
      <div class="bannerBox">
        <div class="bannerTitle">
          <span class="keyTxt">产品与服务</span>
        </div>
        <div class="bannerFront">
          一站式服务平台+专业化服务体系，全方位解决客户ICT管理问题。
        </div>
      </div>
    </div>
    <div class="content" style="background: #fbfcff">
      <div class="contentbox" style="background: #fff">
        <div class="teps">
          <div v-for="(item, index) in list" :key="index" style="display: flex">
            <div class="tepsBox" @click="showContent(item, index + 1)">
              <div
                class="tepsImgs"
                :class="{ choiceImg: index == 1, tepsImg: index == 0 }"
              >
                <img
                  :src="require(`../img/index_icon_part2_icon${index + 2}.png`)"
                />
              </div>
              <div class="tepsName">
                {{ item }}
              </div>
            </div>
            <div
              class="lineS"
              :class="{ line: index == 0 }"
              v-if="index !== 4"
            ></div>
          </div>
        </div>
        <div class="tepsFootBox"></div>
      </div>
      <!-- 产品优势 -->
      <div class="products">
        <div class="pro-introduce">
          <div class="pro-title">
            <div class="pro-font">产品介绍</div>
            <div
              style="
                color: #8f94b2;
                font-family: JI-Sludge;
                font-size: 18px;
                margin-top: 12px;
                width: 250px;
              "
            >
              PRODUCT INTRODUCTION
            </div>
          </div>
          <!-- <div v-if="teps == 1" class="pro-content">
            <div class="content-bg"></div>
            <div style="font-size: 20px">拓展平台</div>
            <div style="color: #8f94b2; margin-top: 20px; line-height: 2">
              拓展平台计算机网络的拓扑结构是引用拓扑学中研究与大小，形状无关的点、线关系的方法。把网络中的计算机和通信设备抽象为一个点，把传输介质抽象为一条线，由点和线组成的几何图形就是计算机网络的拓扑结构。网络的拓扑结构反映出网中各实体的结构关系，是建设计算机网络的第一步，是实现各种网络协议的基础，它对网络的性能，系统的可靠性与通信费用都有重大影响。拓扑在计算机网络中即是指连接各结点的形式与方法。
              把网络中的工作站和服务器等网络单元抽象为“点”。网络中的电缆等抽象为“线”。影响网络性能、系统可靠性、通信费用。
            </div>
          </div> -->
          <div v-if="teps == 1" class="pro-content">
            <div class="content-bg"></div>
            <div class="title">监控平台</div>
            <div class="content">
              监控平台主要为应用监控和设备监控，对企业的关键业务的应用和设备进行监测，实时获取应用和设备的健康信息、性能信息，提高企业应用和设备的可用性，保证关键核心业务的正常运转。
            </div>
          </div>
          <div v-if="teps == 2" class="pro-content">
            <div class="content-bg"></div>
            <div class="title">展示平台</div>
            <div class="content">
              数据可视化展示平台，以表格、图表等多种方式对企业的数据进行统计和分析，并直观的提现，帮助企业挖掘数据中的价值，保证企业数据管理有效运转，协助企业经营者辅助决策。
            </div>
          </div>
          <div v-if="teps == 3" class="pro-content">
            <div class="content-bg"></div>
            <div class="title">安管平台</div>
            <div class="content">
              对设备进行标准化以及规范化的管理，24小时实时监测设备状态，设备异常智能告警，根据设备和应用进行在线巡检、漏洞扫描、补丁修复等安全措施。
            </div>
          </div>
          <div v-if="teps == 4" class="pro-content">
            <div class="content-bg"></div>
            <div class="title">资产平台</div>
            <div class="content">
              帮助医院实现各类资产的统一管理，并对医疗设备从科室入出库、维修保养到报废处置进行全生命周期的数字化管理，全面满足医院规范管理和质量控制的更多要求，建立资产和耗材备件台账，规范资产借用、归还、转科、维修、报废等处置流程。
            </div>
          </div>
          <div v-if="teps == 5" class="pro-content">
            <div class="content-bg"></div>
            <div class="title">工单平台</div>
            <div class="content">
              基于智能化、数字化的工单平台，支持全程服务可视、可管、可回溯，实现服务商、云端专家快速响应。支持基于通话自动创建工单，支持一键创建工单；支持工单服务内容互动、服务内容确认，快速完成服务内容；做到服务全程留痕、信息充分共享、进度实时掌握。
            </div>
          </div>
        </div>
        <div class="pro-advantage">
          <div :class="Animate1 ? ' myanimation1' : ''" ref="proTitleBox">
            <div class="pro-font">产品优势</div>
            <div class="pro-subtitle">ADVANTAGES</div>
          </div>
          <div
            class=""
            :class="Animate2 ? 'monitor myanimation' : 'monitor'"
            ref="animateBox"
          >
            <div class="monitor-content" style="margin-bottom: 20px">
              <div>
                <img src="@/img/index_icon_part2_icon2.png" alt="" />
              </div>
              <div>
                <div class="monitor-title">服务标准化</div>
                <div class="monitor-time">
                  参照ITSS等标准，及服务专家经验，将ICT服务过程进行标准化拆解，形成标准服务目录，实现服务过程标准化管理。
                </div>
              </div>
            </div>
            <div class="monitor-content" style="margin-bottom: 20px">
              <div>
                <img src="@/img/index_icon_part2_icon2.png" alt="" />
              </div>
              <div>
                <div class="monitor-title">流程可视化</div>
                <div class="monitor-time">
                  依托于平台强大的工单系统，联接用户、生态服务伙伴及云端专家服团队。实现全过程可视、可管、可控、可考核、可追溯，保障服务质量。
                </div>
              </div>
            </div>
            <div class="monitor-content">
              <div>
                <img src="@/img/index_icon_part2_icon2.png" alt="" />
              </div>
              <div>
                <div class="monitor-title">服务专业化</div>
                <div class="monitor-time">
                  联接客户、生态服务伙伴及云端专家团队，实现专业服务快速可达，使客户可低成本地享受贴身的、快速的、可靠的的ICT专业服务。
                </div>
              </div>
            </div>
            <div class="monitor-content">
              <div>
                <img src="@/img/index_icon_part2_icon2.png" alt="" />
              </div>
              <div>
                <div class="monitor-title">管理智能化</div>
                <div class="monitor-time">
                  通过对服务过程评价的统计，可灵活对生态服务伙伴服务周期内的服务质量，进行综合智能评估，并向用户提供服务报表；通过支持智慧项目、需求及排班管理，实现服务全流程智慧管理。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 流程介绍 -->
      <div class="process">
        <div :class="Animate3 ? ' myanimation' : ''" ref="ceTitleBox">
          <div class="pro-font title">申请使用流程介绍</div>
          <div class="pro-subtitle">PROCEDURE</div>
        </div>
        <div
          class=""
          :class="Animate4 ? 'procedure myanimation' : 'procedure'"
          ref="procedureBox"
        >
          <div class="left-box">
            <img src="../img/product_left_img.png" alt="" />
            <div class="apply-btn" onClick="__egeelTalk()">申请试用</div>
          </div>

          <div class="apply">
            <div class="tip" v-for="item in applyList" :key="item.id">
              <div class="circular">{{ item.id }}</div>
              <div>{{ item.tip }}</div>
            </div>
            <div class="dotted-box"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" onClick="__egeelTalk()">
      <img src="../img/index/icon_service@2x.png" />
      <span>在线咨询</span>
    </div>
    <ContactDialog :visible="contactVisible" @cancel="contactVisible = false" />
  </div>
</template>

<script>
import { isElementNotInViewport } from "../common/utils.js";
import ContactDialog from "./children/ContactDialog.vue";
export default {
  components: {
    ContactDialog,
  },
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      contactVisible: false,
      teps: 1,
      list: ["监控平台", "展示平台", "安管平台", "资产平台", "工单平台"],
      applyList: [
        {
          id: 1,
          tip: "申请使用流程介绍",
        },
        {
          id: 2,
          tip: "填写公司相关资料",
        },
        {
          id: 3,
          tip: "获取测试账号",
        },
        {
          id: 4,
          tip: "产品试用",
        },

        {
          id: 8,
          tip: "正式使用产品",
        },

        {
          id: 7,
          tip: "获取授权",
        },

        {
          id: 6,
          tip: "系统部署安装",
        },

        {
          id: 5,
          tip: "签约合同",
        },
      ],
    };
  },
  created() {
    var that = this;
    that.test();
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.proTitleBox);
      this.Animate2 = !isElementNotInViewport(this.$refs.animateBox);
      this.Animate3 = !isElementNotInViewport(this.$refs.ceTitleBox);
      this.Animate4 = !isElementNotInViewport(this.$refs.procedureBox);
    },
    test() {
      document
        .getElementsByTagName("head")[0]
        .appendChild(document.createElement("script")).src =
        (location.protocol.toLocaleLowerCase() == "https:"
          ? "https:"
          : "http:") + "//v.fmkefu.com/cs/js?u=4482_4535&c=auto";
      setTimeout(() => {
        $('img[type="talk"]').parent().parent().css("z-index", "-1000");
      }, 1000);
    },
    // 平台右边内容切换
    showContent(item, index) {
      this.teps = index;
    },
    go() {
      this.$router.push({ path: "/Expert" });
    },
    // 在线客服
    showAgent() {
      var that = this;
      that.contactVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
//产品优势 title
.myanimation {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.myanimation1 {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 20px;
  bottom: 10%;
  z-index: 999;
  top: 465px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  height: 140px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  cursor: pointer;

  img {
    width: 41px;
    height: 41px;
  }
  span {
    width: 16px;
    line-height: 1.1;
  }
}
.box {
  /* padding-bottom: 100px; */
  width: 100%;
  /* border: 1px solid red; */
}
// .banner {
//   width: 1903px;
//   height: 280px;
// }
// .bannerBox {
//   padding: 0px 0 0 359px;
//   margin-top: -200px;
// }
// .bannerFront {
//   width: 410px;
//   height: 18px;
//   font-size: 16px;
//   font-family: PingFang HK;
//   font-weight: 400;
//   color: #ffffff;
//   line-height: 44px;
//   opacity: 0.6;
//   margin-top: 23px;
// }
.banner {
  width: 1903px;
  height: 400px;
  padding-top: 70px;
  background: #eceff8;
  // padding-top: 70px;
  // background: url(../img/index/index_banner@2x.png) no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  /* height: 100%; */
  // width: 750px;
  // padding: 0px 0 0 360px;
  /* border: 1px solid red; */
  width: 63%;
  margin: 0 auto;
  margin-top: -310px;
}
.bannerTitle {
  font-size: 48px;
  font-family: Source Han Sans SC;
  font-weight: 800;
  color: #101635;
}
.keyTxt {
  color: #3e68ff;
}
.bannerFront {
  /* width: 410px;
  height: 18px; */
  width: 63%;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #101635;
  padding: 26px 15px 58px 0;
  line-height: 1.7;
}
.contentbox {
  width: 100%;
  height: 222px;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
}
.contentBox {
  margin-top: 91px;
  display: flex;
}
.teps {
  padding: 0 368px;
  // width: 54%;
  /* height: 0; */
  // margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.tepsBox {
  height: 104px;
  width: 75px;
  /* border: 1px solid red; */
  text-align: center;
  cursor: pointer;
}
.tepsImgs {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
.tepsImgs img {
  position: relative;
  top: 14px;
}
.tepsImg {
  border: 1px solid #3e68ff;
}
.choiceImg {
  background: #fafbff;
  box-shadow: 0px 10px 20px 0px rgba(184, 188, 204, 0.3);
  border-radius: 50%;
}
.tepsName {
  width: 73px;
  height: 17px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
}
.lineS {
  background-color: #e6ebff;
  height: 2px;
  width: 100px;
  margin: 35px 23px 0px 23px;
}
.line {
  background-color: #3e68ff;
  /* height: 2px;
	width: 100px;
	margin: 35px 23px 0px 23px; */
}
.tepsFootBox {
  height: 24px;
  width: 55.2%;
  border-left: 1px solid #e6ebff;
  border-bottom: 1px solid #e6ebff;
  border-right: 1px solid #e6ebff;
  /* border: 1px solid red; */
  margin: 15px auto;
  border-radius: 1px;
}
.products {
  width: 100%;
  padding-bottom: 100px;
  background: #fbfcff;
}
.pro-introduce {
  // width: 62%;
  // padding: 90px 360px;
  padding: 90px 0;
  width: 63%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // padding: 90px 0;
}
.pro-subtitle {
  color: #8f94b2;
  font-family: JI-Sludge;
  font-size: 18px;
  margin-top: 12px;
}
.pro-font {
  width: 112px;
  font-size: 28px;
  color: #3d4566;
}
.pro-content {
  width: 65%;
  /*height: 100%; */
  border: 1px solid #e6ebfa;
  // box-shadow: 0px 0px 10px #c9ccd4;
  background: #fff;
  padding: 48px 38px 63px 38px;
  position: relative;
  z-index: 1;
  /* flex: 3; */
  .title {
    font-size: 20px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
  }
  .content {
    margin-top: 20px;
    line-height: 2;
    font-size: 16px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
  }
}
.content-bg {
  display: inline-block;
  width: 161px;
  height: 200px;
  background-image: url("../img/product_part1_deco.png");
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.pro-advantage {
  width: 63%;
  // padding: 0 360px;
  margin: 0 auto;
}
.monitor {
  margin-top: 61px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.monitor-content {
  box-sizing: border-box;
  width: 580px;
  // width: 577px;
  height: 200px;
  display: flex;
  padding: 32px 30px 54px 41px;
  background: #ffffff;
  border: 1px solid #e6ebfa;
}
.monitor-content img {
  margin-right: 18px;
  /* width: 66px; */
  width: 44px;
  height: 44px;
}
.monitor-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
}
.monitor-time {
  width: 420px;
  height: 48px;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;

  /* overflow: hidden; */
}
/* 流程 */
.process {
  width: 63%;
  // padding: 0 360px;
  margin: 0 auto;
  // background: #fbfcff;
}
.procedure {
  display: flex;
  padding: 30px 0 210px 26px;
  .left-box {
    position: relative;
  }
}
/* 按钮 */
.apply-btn {
  position: absolute;
  left: 103px;
  bottom: 36px;
  width: 144px;
  height: 48px;
  line-height: 48px;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 24px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.title {
  width: 226px;
}
.apply {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 368px;
  height: 280px;
  // border: 1px dotted #5f79ff;
  // border-top: none;
  // border-radius: 0px 0px 20px 20px;
  margin-top: 50px;

  .tip {
    display: flex;
    margin-bottom: 42px;
    font-size: 20px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
  }
  .dotted-box {
    position: absolute;
    width: 363px;
    height: 242px;
    border: 1px dotted #5f79ff;
    border-top: none;
    border-radius: 0px 0px 20px 20px;
    left: 151px;
    top: 22px;
  }
}
.circular {
  width: 20px;
  height: 20px;
  background: #e1e7ff;
  border: 1px solid #3e68ff;
  border-radius: 50%;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #436cff;
  text-align: center;
  margin: 0 42px 0 140px;

  // margin-right: 42px;
}
.apply-content {
  width: 598px;
  height: 48px;
}
@media screen and (max-width: 1669px) {
  .monitor-content {
    width: 48%;
    height: 245px;
  }
  .monitor-time {
    width: 70%;
  }
}
@media screen and (max-width: 479px) {
  .banner {
    width: 230%;
    height: 200px;
  }
  .tepsFootBox {
    width: 160%;
    margin: 15px 0 0 30%;
  }
}
</style>
