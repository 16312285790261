/**
 * Created by bootdo.
 */
import Vue from 'vue'
import axios from "axios";
// import store from "../vuex/store";
// import router from "../router";
import { bus } from "../bus.js";
import { Message } from "element-ui";
import baseUrl from "./baseUrl";
import VueRouter from 'vue-router'
Vue.use(VueRouter)
let base = baseUrl;
let requests = {
    url: "",
    data: "",
    params: ""
}; //请求防抖需要收集请求中的集合，并控制过滤
// Vue.prototype.$qs = qs;

axios.defaults.withCredentials = true;
axios.defaults.timeout = 1800000;
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
//  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';//配置请求头

//添加一个请求拦截器
axios.interceptors.request.use(
    config => {
        if (
            requests.url == config.url &&
            requests.data == JSON.stringify(config.data) &&
            ((config.params && requests.params == JSON.stringify(config.params)) ||
                !config.params) && [
                baseUrl + "/sso/user/search-user-page",
                baseUrl + "/project/project/getProjectDemandList",
                baseUrl + "/data/resources/information/getOtherGraphInfo"
            ].indexOf(config.url) == -1
        ) {
            console.log(config.url, "防抖");
            return;
        }
        requests = {
            url: config.url,
            data: JSON.stringify(config.data),
            params: JSON.stringify(config.params)
        };
        if (localStorage.getItem("access-token")) {
            if (
                config.url.indexOf("/log/log/downLog") > -1 ||
                config.url.indexOf("/operating/api/customer/contract/downloadFile") >
                -1 ||
                config.url.indexOf("/admin/emergency/plan/exportExcel") > -1 ||
                config.url.indexOf("/admin/inspection/plan/downloadTemplate") > -1 ||
                config.url.indexOf("/admin/scheduling/export") > -1 ||
                config.url.indexOf("/data/resources/information/exportExcel") > -1 ||
                config.url.indexOf(
                    "/data/resources/information/exportConfigurationExcel"
                ) > -1 ||
                config.url.indexOf("/admin/emergency/incident/export") > -1 ||
                config.url.indexOf("/admin/scheduling/downloadExcel") > -1 ||
                config.url.indexOf("/sso/user/downloadTemplate") > -1 ||
                (config.params && config.params.blob)
            ) {
                config.responseType = "blob";
            }
            config.headers.Authorization = window.localStorage.getItem(
                "access-token"
            );
            if (config.url.indexOf("/sso/user/batchImport") > -1) {
                config.headers["Content-Type"] = "multipart/form-data;";
            }
            if (config.url.indexOf("/project/projectProgressTaskt/export") > -1) {
                config.headers["Content-Type"] = "application/x-www-form-urlencoded;";
            }
            if (config.method === "post") {
                // config.params = {
                //   access_token: window.localStorage.getItem("access-token").substr(7)
                // };
            } else if (config.method === "get") {
                config.params = {
                    access_token: window.localStorage.getItem("access-token").substr(7),
                    ...config.params
                };
            }
        }
        if (
            config.url.indexOf("data/juyuanTrapLog/page") < 0 &&
            config.url.indexOf("admin/message/getMessageList") < 0 &&
            config.url.indexOf("admin/operating/transfer/getMessageList") < 0 &&
            config.url.indexOf("admin/operating/transfer/queryOrder") < 0
        ) {
            // store.state.loading = true;
        }
        if (config.url.indexOf('/admin/resourcesdownload/download4site') > -1) {
            config.responseType = "blob";
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
// 添加一个响应拦截器
axios.interceptors.response.use(
    function(response) {
        requests = {
            url: "",
            data: "",
            params: ""
        };
        // store.state.loading = false;
        if (response.data.resultCode) {
            if (
                response.data.resultCode === "4006" ||
                response.data.resultCode === "0001" ||
                response.data.resultCode === "0004"
            ) {
                Message.closeAll();
                Message({
                    type: "error",
                    message: "登录已失效，请重新登录！",
                    duration: 3000
                });
                localStorage.clear();
                router.push("/login");
            } else if (
                ["0000", "0011", "9105", "8004", "8021"].indexOf(
                    response.data.resultCode
                ) == -1
                // response.data.resultCode !== "0000" &&
                // response.data.resultCode !== "0011" && response.data.resultcode != '9105'
            ) {
                Message({
                    type: "error",
                    message: response.data.resultMessage,
                    duration: 3000
                });
            }
        }

        return response;
    },
    function(error) {
        // store.state.loading = false;
        if (error.response) {
            requests = "";
            // if(error.response.data.status==500){

            // }else{
            let errorMsg =
                error.response.data.resultMessage || error.response.data.error;
            if (error.response.headers.resultcode === "0003") {
                errorMsg = "您暂无权限访问。";
            }
            Message({
                type: "error",
                message: errorMsg,
                duration: 3000
            });
            // }
        } else {
            // Message({
            //   type: "error",
            //   message: "请求出现异常",
            //   duration: 3000
            // });
        }
        // return Promise.reject(error);
    }
);

// //请求中的URL集合
// function stopRepeatRequest(reqList, config, errorMessage) {
//   if (reqList.length) {
//     let arr = reqList.filter(
//       item =>
//         item.url == config.url &&
//         JSON.stringify(item.data) == JSON.stringify(config.data) &&
//         (!config.params ||
//           JSON.stringify(config.params) == JSON.stringify(item.params))
//     );
//     if (arr.length) {
//       console.log(errorMessage, 49);
//       return true;
//     }
//   }
//   reqList.push({
//     url: config.url,
//     data: config.data,
//     params: config.params || undefined
//   });
// }
// //单个请求结束后的URL
// function allowRequest(reqList, config) {
//   if (reqList.length) {
//     requests = reqList.filter(item => {
//       return (
//         item.url != config.url &&
//         (JSON.stringify(item.data) != JSON.stringify(config.data) ||
//           (config.params &&
//             JSON.stringify(config.params) != JSON.stringify(item.params)))
//       );
//     });
//   }
// }

//通用方法 8084
export const POST = (url, params) => {
    return axios.post(`${base}${url}`, params).then(res => res.data);
};

export const GET = (url, params) => {
    return axios
        .get(`${base}${url}`, {
            params: params
        })
        .then(res => res.data);
};

export const PUT = (url, params) => {
    return axios.put(`${base}${url}`, params).then(res => res.data);
};

export const DELETE = (url, params) => {
    return axios
        .delete(`${base}${url}`, {
            params: params
        })
        .then(res => res.data);
};

export const PATCH = (url, params) => {
    return axios.patch(`${base}${url}`, params).then(res => res.data);
};
export const POST2 = (url, params) => {
    return axios
        .post(`${base}${url}`, params, {
            timeout: 300000
        })
        .then(res => res.data);
};
export const POST3 = (url, params) => {
    return axios
        .post(`${base}${url}`, params, {
            timeout: 60000
        })
        .then(res => res.data);
};