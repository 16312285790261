<template>
  <div class="box">
    <div class="content">
      <!-- 公司简介 -->
      <div class="contentbox contentbox1 contentbox3">
        <div class="contentTitlebox">
          <div class="contentTitle" style="padding-top: 82px">公司简介</div>
          <div class="contentTitleS">introduction</div>
          <div class="pro-advantage pro-intro">
            <div class="intro-box">
              <p class="p-one">
                全栈云服(重庆)科技有限公司成立于2021年，总部位于重庆市两江新区大竹林街道星光大道62号海王星科技园内。
              </p>
              <p>
                全栈云服向客户提供专业、高效、安全的信息化统一闭环服务，自主研发云服平台软件，联接了用户、生态伙伴、技术专家等，实现对客户资产、服务、工单、质量等的统一管理，满足客户及时获得专业标准的服务，平台构建行业服务标准体系，通过IT手段拉通各类技术工程师和专家，让技术专家通过远程落地客户。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="contentbox contentbox1">
        <div class="contentTitlebox">
          <div class="contentTitle">企业文化</div>
          <div class="contentTitleS">CORPORATE CULTURES</div>
        </div>
        <!-- 可提供的服务 -->
        <!-- 企业文化 -->
        <div class="pro-advantage">
          <div class="monitor">
            <div class="monitor-content" style="margin-bottom: 40px">
              <div class="contentBox">
                <div><img src="../img/images/about_icon1@2x.png" /></div>

                <div class="contentRight">
                  <div class="title">使命</div>
                  <div class="monitor-time">
                    专注与解决ICT基础资源领域，致力于提供专业、高效、安全的ICT全栈服务平台，为客户解决问题。
                  </div>
                </div>
              </div>
            </div>
            <div class="monitor-content" style="margin-bottom: 40px">
              <div class="contentBox">
                <div><img src="../img/images/about_icon2@2x.png" /></div>

                <div class="contentRight">
                  <div class="title">愿景</div>
                  <div class="monitor-time">让优质服务触手可及。</div>
                </div>
              </div>
            </div>
            <div
              class=""
              :class="
                Animate1 ? 'monitor-content myanimation' : 'monitor-content'
              "
              ref="oneBox"
              style="margin-bottom: 40px"
            >
              <div class="contentBox">
                <div><img src="../img/images/about_icon3@2x.png" /></div>

                <div class="contentRight">
                  <div class="title">理念</div>
                  <div class="monitor-time">
                    以技术服务人才为本，依托数字化技术和服务体系构建信息化服务领域价值链、供应链枢纽，打造信息化服务产业链核心平台。
                  </div>
                </div>
              </div>
            </div>
            <div
              class=""
              :class="
                Animate1 ? 'monitor-content myanimation' : 'monitor-content'
              "
              ref="oneBox"
              style="margin-bottom: 40px"
            >
              <div class="contentBox">
                <div><img src="../img/images/about_icon4@2x.png" /></div>

                <div class="contentRight">
                  <div class="title">责任</div>
                  <div class="monitor-time">
                    为客户创造价值，提高效率，降低成本。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 企业资质 -->
      <div class="contentbox contentbox1 contentbox2">
        <div
          class=""
          :class="Animate2 ? 'contentTitlebox myanimation1' : 'contentTitlebox'"
          ref="twoBox"
        >
          <div class="contentTitle" style="padding-top: 75px">企业资质</div>
          <div class="contentTitleS">ENTERPRISE QUALIFICATION</div>
          <div class="pro-qual">
            <!-- <div class="img-box" v-for="(item, index) in imgArr" :key="index">
              <div class="img">
                <img :src="item.image" />
              </div>
            </div> -->
            <div class="swiper">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <swiper :options="swiperOption" ref="mySwiper" class="swiper">
                    <swiper-slide
                      v-for="(item, index) in imgArr"
                      :key="index"
                      class="img-box"
                    >
                      <div class="img">
                        <img :src="item.image" />
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
                <!-- <div class="prev"></div> -->
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 合作伙伴 -->
      <div class="contentbox contentbox1 contentbox4">
        <div
          class=""
          :class="Animate3 ? 'contentTitlebox myanimation' : 'contentTitlebox'"
          ref="threeBox"
        >
          <div class="contentTitle contactTitle">联系我们</div>
          <div class="contentTitleS">Contact us</div>
        </div>
        <!-- :class="Animate3 ? 'contentTitlebox myanimation' : 'contentTitlebox'"
          ref="threeBox" -->
        <div
          class=""
          :class="Animate4 ? 'pro-advantage myanimation1' : 'pro-advantage'"
          ref="fourBox"
        >
          <div class="pro-qual pro-contact">
            <div class="contact-way">
              <div><img src="../img/images/contac_1@2x.png" /></div>
              <div>support@fullstackcs.com</div>
            </div>
            <div class="contact-way">
              <div><img src="../img/images/contact_icon2@2x.png" /></div>
              <div>023-65792053</div>
            </div>
            <div class="contact-way">
              <div><img src="../img/images/contact_icon3@2x.png" /></div>
              <div>重庆市两江新区大竹林街道星光大道62号海王星科技园内</div>
            </div>
          </div>
          <!-- 引入地图 -->
          <!-- <div class="map" id="map"></div> -->
          <baidu-map
            :center="center"
            :zoom="zoom"
            @ready="handler"
            class="map"
            ak="QrKw6BpO274b6mXQ5T1uSrMt2kZGLE5E"
          >
            <!-- 原 粤lng: 113.953998, lat: 22.550413 ,lng: 106.516346, lat: 29.620283-->
            <bm-marker
              :position="{ lng: 106.516346, lat: 29.620283 }"
              :dragging="true"
              :icon="{
                url: require('../img/images/icon_location@2x.png'),
                backgroundPosition: center,
                top: 0,
                size: { width: 41, height: 47 },
                opts: {
                  imageSize: { width: 41, height: 47 },
                },
              }"
              animation="BMAP_ANIMATION_BOUNCE"
            >
            </bm-marker
          ></baidu-map>
        </div>
      </div>
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" onClick="__egeelTalk()">
      <img src="../img/index/icon_service@2x.png" />
      <span>在线咨询</span>
    </div>
  </div>
</template>

<!-- <script type="text/javascript" src="https://api.map.baidu.com/api?v=2.0&ak=QrKw6BpO274b6mXQ5T1uSrMt2kZGLE5E"></script> -->
<script>
import { isElementNotInViewport } from "../common/utils.js";
import { swiper, swiperSlide } from "vue-awesome-swiper";

import "../../node_modules/swiper/dist/css/swiper.css";
import image1 from "@/img/images/client.jpg";
import image2 from "@/img/images/IT.jpg";
import image3 from "@/img/images/jingying.jpg";
import image4 from "@/img/images/cuiruoxing.jpg";

import { BaiduMap, BmMarker } from "vue-baidu-map";
export default {
  name: "App",
  components: {
    swiper,
    swiperSlide,
    BaiduMap,
    BmMarker,
  },
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      center: { lng: 0, lat: 0 },
      zoom: 3,
      imgArr: [
        {
          image: image1,
        },
        {
          image: image2,
        },
        {
          image: image3,
        },
        {
          image: image4,
        },
      ],
      // 轮播
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 25,
        loop: false,
        nextButton: ".swiper-button-next", //绑定下一页的控制按钮
        prevButton: ".swiper-button-prev", //绑定上一页的控制按钮
        autoplay: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
      // return this.$refs.bannerSwiper.swiper;
    },
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.oneBox);
      this.Animate2 = !isElementNotInViewport(this.$refs.twoBox);
      this.Animate3 = !isElementNotInViewport(this.$refs.threeBox);
      this.Animate4 = !isElementNotInViewport(this.$refs.fourBox);
    },
    handler({ BMap, map }) {
      // this.center.lng = 113.953477;
      // this.center.lat = 22.551217;
      // this.zoom = 15;
      this.center.lng = 106.516;
      this.center.lat = 29.62036;
      this.zoom = 17;
    },
    // 最新新闻-查看详情
    viewDetail() {},
  },
};
</script>

<style lang="scss" scoped="scoped">
//产品优势 title
.myanimation {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.myanimation1 {
  animation: load1 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load1 {
  0% {
    opacity: 0;
    transform: translateY(150px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 20px;
  bottom: 10%;
  z-index: 999;
  top: 465px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  height: 140px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  cursor: pointer;

  img {
    width: 41px;
    height: 41px;
  }
  span {
    width: 16px;
    line-height: 1.1;
  }
}
.BMap_Marker {
  div {
    position: absolute;
    top: 0 !important;
  }
}
.box {
  // padding-bottom: 100px;
  width: 100%;
  /* border: 1px solid red; */
}
.banner {
  // width: 1903px;
  height: 540px;

  /* background: url(../../assets/client/icon8.png) no-repeat;
  background-size: 100% 100%; */
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  /* height: 100%; */
  width: 660px;
  padding: 0px 0 0 360px;
  /* border: 1px solid red; */
  margin-top: -200px;
}
.bannerTitle {
  font-size: 48px;
  font-family: Source Han Sans SC;
  font-weight: 800;
  color: #101635;
}
.keyTxt {
  color: #3e68ff;
}
.bannerFront {
  /* width: 410px;
  height: 18px; */
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #101635;
  margin-top: 26px;
}

.title1 {
  font-size: 32px;
  font-family: DOUYU;
  font-weight: normal;
  color: #ffffff;
  margin: 168px 0 0 360px;
}

.title2 {
  margin: 22px 0 0 359px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #ffffff;
}

.contentbox {
  width: 100%;
  height: 600px;
}

.btn {
  width: 144px;
  height: 48px;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 24px;
  margin: 59px 0 0 360px;
}

.dian {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  float: left;
  margin: 20px 0 0 20px;
}

.font {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  margin-right: 60px;
}

.More {
  margin-left: 51px;
}

.contentTitle {
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
  padding-top: 98px;
  /* margin-top: 98px; */
}

.contentTitleS {
  font-size: 18px;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #8f94b2;
  margin-top: 12px;
}

.contentBox {
  // margin-top: 91px;
  display: flex;
  flex-wrap: wrap;
}

.contentBoxS {
  height: 170px;
  width: 210px;
  margin: 0 50px 0 50px;
}

.contentBoxSs {
  margin-left: 20%;
  display: flex;
}
.contentbox1 {
  text-align: center;
  height: 700px;
  background: #eef6ff;
  /* border-bottom: 1px solid #e1e7ff; */
}
.contentbox2 {
  height: 660px;
  background: #ffffff;
}
.contenttitle {
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 44px;
}

.contentDetails {
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 30px;
  text-align: left;
}
// 公司简介
.contentbox3 {
  height: 540px;
  padding-top: 70px;
  background-image: url("~@/img/images/about_banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.pro-intro {
  padding-top: 35px !important;
  .intro-box {
    box-sizing: border-box;
    width: 100%;
    height: 280px;
    background: linear-gradient(180deg, #f3f5f8, #ffffff);
    border: 2px solid #ffffff;
    box-shadow: -4px -7px 20px 0px rgba(255, 255, 255, 0.8),
      10px 17px 60px 0px rgba(127, 140, 161, 0.3);
    border-radius: 4px;
    // padding: 70px 90px;
    padding: 66px 90px;
    font-size: 18px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #3d4566;
    text-align: left;
    p {
      line-height: 1.7;
    }
    .p-one {
      margin-bottom: 30px;
    }
  }
}

/* 可提供的服务 */
.pro-advantage {
  width: 63%;
  // padding: 0 360px;
  margin: 0 auto;
}
.monitor {
  margin-top: 61px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.monitor-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // padding: 0 40px 45px;
  padding: 0 40px;
  padding-top: 25px;
  width: 580px;
  // width: 572px;
  height: 180px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 2px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    4px 7px 20px 0px rgba(55, 99, 170, 0.1);
  border-radius: 6px;
  .title {
    font-size: 20px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
  }
}
.contentBox {
  display: flex;
  justify-content: space-between;
  img {
    width: 72px;
    height: 72px;
  }
}
.contentTop {
  // padding: 0 5px 13px;
  height: 55px;
  line-height: 55px;
}
.contentRight {
  display: flex;
  flex-direction: column;
  width: 82%;
  text-align: left;
  padding-top: 5px;
}
.contentTop img {
  width: 55px;
  height: 55px;
}
.monitor-time {
  /* height: 48px; */
  line-height: 1.5;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  // color: #3d4566;
  color: #8f94b2;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  padding-top: 22px;
}
//  企业资质
.pro-qual {
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
  width: 61.5%;
  margin: 0 auto;
  // padding: 0 360px 89px;
}
.img-box {
  box-sizing: border-box;
  // padding: 18px 15px;
  // width: 280px;
  // height: 340px;
  // line-height: 388px;
  // width: 275px !important;
  height: 388px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 2px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    8px 14px 60px 0px rgba(55, 99, 170, 0.16);
  border-radius: 6px;
  border: 2px solid #ffffff;
  border-radius: 6px;
  text-align: center;
  // margin-left: 27px;
  .img {
    // width: 251px;
    // height: 353px;
    width: 231px;
    height: 343px;
    margin: auto;
    padding: 18px 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.pro-qual .swiper {
  position: relative;
  // max-width: 1113px;
  width: 100%;
  margin: 0 auto;
  // padding: 20px 30px;
}
.swiper-wrapper {
  // display: flex;
  // justify-content: space-between !important;
  // position: relative;
  overflow: hidden;
  .img-box:nth-child(4) {
    // margin-left: 0;
    margin-right: 35px !important;
  }
  .swiper-container {
    overflow: hidden !important;
    padding: 30px;
  }
}
.swiper-container {
  overflow: initial !important;
  // padding: 30px;
}
.pro-qual .swiper .swiper-button-prev,
.pro-qual .swiper .swiper-button-next {
  width: 64px;
  height: 64px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  // border: 1px solid red;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 50%;
  // width: 35px;
  // height: 100px;
  // background: #5e81ff;
  right: -80px;
  background-size: 11px 20px;
  background-repeat: no-repeat;
  background-position: 9px 30px;
}
.pro-qual .swiper .swiper-button-prev {
  left: -81px !important;
}
.swiper-button-disabled {
  opacity: 0.35 !important;
  cursor: auto !important;
  pointer-events: none !important;
}
.pro-qual .swiper .swiper-button-prev {
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2027%2044%27%3E%3Cpath%20d%3D%27M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z%27%20fill%3D%27%233E68FF%27%2F%3E%3C%2Fsvg%3E);
  background-position: center center;
}

.pro-qual .swiper .swiper-button-next {
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2027%2044%27%3E%3Cpath%20d%3D%27M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z%27%20fill%3D%27%233E68FF%27%2F%3E%3C%2Fsvg%3E);
  background-position: center center;
}

// 联系我们
.contactTitle {
  padding-top: 80px;
}
.contentbox4 {
  height: 960px;
  background: #eef6ff;
  .pro-contact {
    box-sizing: border-box;
    padding: 54px 0 40px;
    width: 100%;
    .contact-way {
      display: flex;
      align-items: center;
      width: 23%;
      height: 109px;
      background: linear-gradient(180deg, #f3f5f8, #ffffff);
      border: 2px solid #ffffff;
      box-shadow: -4px -7px 20px 0px #ffffff,
        4px 7px 20px 0px rgba(55, 99, 170, 0.1);
      border-radius: 4px;
      padding: 0 70px 0 30px;
      text-align: left;
      line-height: 1.7;
      div {
        font-size: 16px;
        font-family: PingFang HK;
        font-weight: 400;
        color: #3d4566;
      }
      img {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
    }
  }
  .map {
    width: 100%;
    height: 400px;
    // background: red;
  }
}
.cloudBox {
  width: 55%;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  margin: 80px auto;
  justify-content: space-between;
}

.cloudLeft {
  width: 250px;
  height: 200px;
  /* margin-left: 10%; */
}

.cloudLeft img {
  width: 100%;
  height: 100%;
}

.cloudRight {
  /* border: 1px solid; */
  width: 543px;
  height: 176px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 32px;
  /* margin-left: 179px; */
}

.cooperationBanner {
  display: flex;
  width: 100%;
  height: 500px;
  background-image: url(../img/index_part3_logos_png.png);
}

.cooperationBox {
  width: 524px;
  height: 284px;
  background: #f9faff;
  /* border: 1px solid red; */
  text-align: center;
  margin: 108px auto;
  /* 不透明度 */
  opacity: 0.8;
}

.cooperationTitle {
  margin-top: 94px;
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  /* line-height: 44px; */
}

.cooperationTitleEnglish {
  margin-top: 13px;
  font-size: 14px;
  font-family: JI-Sludge;
  font-weight: 600;
  color: #8f94b3;
  /* line-height: 44px; */
}

.cooperationFoot {
  margin-top: 29px;
  font-size: 12px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  /* line-height: 44px; */
}
@media screen and (max-width: 1669px) {
  .monitor-content {
    width: 48%;
    height: 190px;
  }
  // 企业资质
  .img-box .img {
    width: 90%;
    height: 80%;
    padding-top: 22%;
    padding-left: 6px;
  }
  .contentbox1 {
    // height: 760px;
  }
}
@media screen and (max-width: 479px) {
  .banner {
    width: 160%;
    height: 200px;
    position: relative;
  }
  .banner {
    width: 230%;
    height: 160px;
  }
  .bannerBox {
    height: 100%;
    padding: 0px 0 0 20px;
    margin-top: -120px;
  }
  .banner img {
    width: 100%;
    height: 100%;
    position: absolute;
    /* clip: rect( 0 500px 0 500px); */
  }
  .contentTitle {
    font-size: 15px;
    text-align: center;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    line-height: 44px;
    margin-top: 24px;
  }
  .contentBox {
    width: 100%;
    height: 390px;
    margin-top: 24px;
  }
  .contentBoxS {
    height: 85px;
    width: 100%;
    margin: 0 25px 0 25px;
  }
  .contentBoxSs {
    margin: 0 5% 0;
  }
  .contentbox1 {
    width: 100%;
    /* text-align: center; */
  }
  .contenttitle {
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 600;
    color: #3d4566;
    line-height: 44px;
  }
  .contentDetails {
    font-size: 12px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    line-height: 28px;
    text-align: center;
  }
  .teps {
    width: 100%;
    height: 0;
    margin: 59px auto;
    display: flex;
  }
  .tepsBox {
    height: 52px;
    width: 52px;
    /* border: 1px solid red; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tepsBox:first-child {
    margin-left: 10px;
  }
  .tepsImgs {
    height: 52px;
    width: 52px;
    border-radius: 50%;
  }

  .tepsImgs img {
    position: relative;
    top: 5px;
  }
  .choiceImg {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #fafbff;
    box-shadow: 0px 10px 20px 0px rgba(184, 188, 204, 0.3);
    border-radius: 50%;
  }

  .choiceImg img {
    position: relative;
    top: 5px;
  }

  .tepsName {
    width: 73px;
    height: 44px;
    font-size: 15px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    line-height: 44px;
  }
  .tepsFootBox {
    width: 160%;
    margin: 15px 0 0 30%;
  }

  /* 云 拓扑平台区域 */
  .cloudBox {
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    margin: 24px auto;
    /* 分配弹性元素方式 均匀排列每个元素，首：起点 尾：终点 */
    justify-content: space-between;
  }
  .cloudLeft {
    width: 150px;
    height: 100px;
    /* margin-left: 10%; */
  }

  .cloudRight {
    /* border: 1px solid; */
    width: 543px;
    height: 200px;
    font-size: 12px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    line-height: 32px;
    margin-bottom: 20px;
  }
  /* 底部合作banner区域 */
  .cooperationBanner {
    width: 100%;
    height: 50%;
    /* transform: scale(0.5); */
  }
  /* 最新新闻区域 */
  .newsBox {
    width: 100%;
  }
  .contentbox .contentboxHeight {
  }
  .newsRightBox {
    width: 110px;
    height: 180px;
  }
  .rightBoxfoot {
    margin-top: 5px;
  }
}
</style>
