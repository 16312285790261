<template>
  <div class="foot">
    <!-- 联系我们 在线客服 暂时隐藏 @click="showAgent"-->
    <div class="banner">
      <div class="title">您遇到任何疑问或难题，请随时联系我们</div>
      <div onClick="__egeelTalk()" class="btn">
        <div class="font">
          <div class="dian"></div>
          联系我们
        </div>
      </div>
    </div>
    <div class="footBox">
      <div class="footer-info">
        <div class="leftBox">
          <!-- <div class="titleBox">
            <div class="title" v-for="(item, index) in titleArr" :key="index">
              {{ item }}
            </div>
          </div> -->
          <div class="imgBox">
            <img src="../img/all_logo_white.png" />
          </div>
          <div class="font1">全栈云服(重庆)科技有限公司</div>
        </div>
        <div class="rightBox">
          <div class="record-num">
            Copyright@2021全栈云服(重庆)科技有限公司. All right reserved
            <a href="https://beian.miit.gov.cn/" target="_blank" class="fr"
              >渝ICP备2021004781号-2
            </a>
          </div>
        </div>
        <!-- <div class="rightBox">
          <div class="QR">
            <img src="../img/images/erwerma.png" />
          </div>
        </div> -->
      </div>
      <!-- <div class="line"></div> 
      <div class="font2">
        <div>
          <div class="imgBox">
            <img src="../img/all_logo_white.png" />
          </div>
          <div class="font1">全栈云服(重庆)科技有限公司</div>
        </div>
        <div class="record-num">
          Copyright@2021全栈云服(重庆)科技有限公司. All right reserved 渝ICP备2021004781号-2 
        </div>
      </div>-->
    </div>
    <ContactDialog :visible="contactVisible" @cancel="contactVisible = false" />
    <!-- 暂时隐藏聊天室 -->
    <!-- <chat-room
      class="chat_room"
      @showOrHide="closeWindow"
      :showChat="showOrHide"
      id="dragBox"
      ref="chatBox"
    >
    </chat-room>
    -->
  </div>
  <!-- <div class="titleBox">
          <div class="title">产品与服务</div>

           <div class="titleSon">运维服务</div>
          <div class="titleSon">集成交付</div>
          <div class="titleSon">咨询规则</div>
          <div class="titleSon">制定培训</div> 
        </div>
        <div class="titleBox">
          <div class="title">解决方案</div>
        </div>
        <div class="titleBox">
          <div class="title">技术支持</div>

           <div class="titleSon">文档下载</div>
          <div class="titleSon">工具下载</div>
          <div class="titleSon">软件下载</div>
          <div class="titleSon">学习培训</div>
          <div class="titleSon">知识库</div> 
        </div>
        <div class="titleBox">
           <div class="title">关于我们</div> 
           <div class="titleSon">公司介绍</div>
          <div class="titleSon">加入我们</div>
          <div class="titleSon">联系我们</div>
          <div class="titleSon">新闻中心</div> 
        </div> -->
</template>

<script>
import API from "@/api/api_surpport.js";
import ContactDialog from "../pages/children/ContactDialog.vue";
import chatRoom from "../pages/children/RongImCom.vue";
export default {
  name: "WebFoot",
  components: {
    ContactDialog,
    chatRoom,
  },
  data() {
    return {
      contactVisible: false,
      titleArr: ["走进云服", "产品与服务", "解决方案", "技术支持"],
      showOrHide: false,
      kfID: "",
      companyName: "",
    };
  },
  created() {
    var that = this;
    // that.test();
  },
  mounted() {
    // this.getClinetId();
  },
  methods: {
    concatUs() {
      window.open("https://v.egeel.vip/cs/c/46v4xpx6", "_blank");
      // setTimeout(() => {
      //   $('img[type="talk"]').parent().parent().css("display", "none");
      // }, 1000);
    },
    test() {
      document
        .getElementsByTagName("head")[0]
        .appendChild(document.createElement("script")).src =
        (location.protocol.toLocaleLowerCase() == "https:"
          ? "https:"
          : "http:") + "//v.fmkefu.com/cs/js?u=4482_4535&c=auto";
    },
    // 获取客服id
    getClinetId() {
      const that = this;
      API.getKFid({}).then((res) => {
        if (res.code === "0000") {
          that.kfID = res.data.customerServiceCode;
          that.companyName = res.data.customerName;

          // that.kfID = "KF017",
          // that.companyName = "长沙凝思";
          localStorage.setItem("customerName", res.data.customerName);
          // localStorage.setItem(
          //   "customerServiceCode",
          //   res.data.customerServiceCode
          // );
        }
      });
    },
    // 在线客服
    // 展示客服的盒子且通知子页面去获取token
    showAgent() {
      this.showOrHide = true;
      // that.kfID = "KF017",
      //     that.companyName = "长沙凝思";
      // this.$refs.chatBox.getToken(this.kfID, this.companyName);
      // this.$refs.chatBox.getToken("KF017", "长沙凝思");
    },
    closeWindow() {
      this.showOrHide = false;
    },
    // showAgent() {
    //   var that = this;
    //   that.contactVisible = true;
    // },
    // go() {
    //   this.$router.push({ path: "/Expert" });
    // },
  },
};
</script>

<style lang="scss" scoped="scoped">
.foot {
  width: 100%;
  height: 200px;
  background: #101635;
  position: relative;
  bottom: -140px; //在线客服 暂时隐藏
}
.chat_room {
  position: fixed;
  z-index: 1000;
  // left: 1475px;
  // top: 300px;
  right: 10px;
  bottom: 10px;
}
.footBox {
  /* width: 100%; */
}
.line {
  width: 100%;
  height: 1px;
}
.footer-info {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 62%;
  // height: 360px;
  margin: 0 auto;
}
.leftBox {
  display: flex;
  flex-direction: column;
  width: 32%;
}

.imgBox {
  width: 202px;
  height: 38px;
  margin: 64px 0 30px;
}

.imgBox img {
  width: 100%;
  height: 100%;
}

.rightBox {
  /* width: 50%; */
  display: flex;
  .record-num {
    text-align: center;
    padding-top: 60px;
    margin: 0 auto;
    font-size: 13px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #fefefe;
    opacity: 0.2;

    .fr {
      text-decoration: none;
      font-size: 13px;
      font-family: PingFang HK;
      font-weight: 400;
      color: #fefefe;
    }
  }
}

.font1 {
  font-size: 15px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #fefefe;
  /* position: absolute;
  bottom: 166px; */
}

.font2 {
  display: flex;
}

.titleBox {
  display: flex;
  justify-content: space-between;
  width: 80%;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #fefefe;
  line-height: 30px;
  text-align: center;
}

.title {
  cursor: pointer;
}

.titleSon {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #fefefe;
  line-height: 30px;
  opacity: 0.4;
  cursor: pointer;
}

.QR {
  width: 140px;
  height: 140px;
  padding-top: 15%;
  margin: 0 auto;
}
.QR img {
  width: 100%;
  height: 100%;
}

.banner {
  text-align: center;
  width: 100%;
  height: 200px;
  background-image: url(../img/index_part5_bg.png);
  position: absolute;
  bottom: 200px;
}

.title {
  /* border: 1px solid red; */
  margin-top: 53px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  // line-height: 44px;
}

.btn {
  width: 144px;
  height: 48px;
  background: linear-gradient(0deg, #e7ecff, #f8faff);
  box-shadow: 0px 3px 4px 0px rgba(208, 208, 211, 0.3),
    0px 1px 3px 0px rgba(219, 221, 229, 0.4);
  border-radius: 24px;
  margin: 24px auto;
  cursor: pointer;
}
.btn:hover {
  opacity: 0.8;
  transition: 0.5s;
  // background: red;
}
.dian {
  width: 6px;
  height: 6px;
  background: #4971ff;
  border-radius: 50%;
  float: left;
  margin: 20px 0 0 20px;
  /* border: 1px solid red; */
}

.font {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #4971ff;
  line-height: 48px;
  margin-right: 17px;
}
@media screen and (max-width: 479px) {
  .foot {
    width: 100%;
    // height: 509px;
    background: #101635;
    /* position: absolute; */
    /* bottom: 0; */
  }
  .leftBox {
    width: 20%;
  }
}
</style>
