<template>
  <!-- <el-dialog
    :visible.sync="visible"
    width="1100px"
    :show-close="false"
    :before-close="cancel"
    class="news-dialog"
  > -->
  <div class="box">
    <div class="pro-advantage">
      <div class="contentOne">
        <div class="contentOneHead">
          <div class="fang"></div>
          <div class="contentOneName">
            {{ newsData.title }}
          </div>
        </div>
      </div>
      <div class="contentTwo">
        <div class="contentOneBody">
          <!-- <div class="box1">
            <div class="fbsj">新闻来源：&nbsp;:&nbsp;</div>
            <div class="time">{{ newsData.updatedBy }}</div>
          </div> -->
          <div class="box1">
            <div class="fbsj">发布时间：&nbsp;&nbsp;</div>
            <div class="time">{{ newsData.pushDate }}</div>
          </div>
        </div>
        <!-- <div class="contentTwoTitle ldjj">漏洞简介</div> -->
        <div class="contentTwoContent" v-imgtoken="newsData.content">
          <!-- <quill-editor
            style="height: 300px"
            v-model="newsData.content"
            :readOnly="true"
          >
          </quill-editor> -->
        </div>
        <!-- <div class="contentTwoTitle ldgg">漏洞公告</div>
        <div class="contentTwoContent">
          目前厂商已发布升级补丁以修复漏洞，补丁获取链接：
          <div @click="go()" class="URL">
            https://search.abb.com/library/Download.aspx?DocumentID=8DBD000062&LanguageCode=en&DocumentPartId=&Action=Launch
          </div>
        </div>
        <div class="contentTwoTitle bb">漏洞简介</div>
        <div class="contentTwoContent">adobe photoshop CC2015以下</div> -->
      </div>
      <div class="deco"></div>
      <!-- 右上角定位  -->
      <!-- <div class="close_box">
     
      <img
        class="close_img"
        src="~@/img/images/icon_close@2x.png"
        alt=""
        @click="cancel"
      />
    </div> -->
    </div>
  </div>
  <!-- </el-dialog> -->
</template>

<script>
import API from "@/api/api_surpport";
// 引入富文本编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor, Quill } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      // newsVisible: false,
      // name: "",
      // province: "",
      // data: "",
      newsId: 0,
      newsData: {},
    };
  },
  watch: {
    "$route.query.id": function (newVal, oldVal) {
      this.newsId = newVal;
      this.get();
    },
  },
  async created() {
    await this.get();
  },
  mounted() {
    // this.get();
  },
  methods: {
    async get() {
      var that = this;
      that.newsId = this.$route.query.id;
      const res = await API.newsDetail({ id: that.newsId }).then((res) => {
        if (res.code == "0000") {
          that.newsData = res.data;
        }
      });
    },
    cancel() {
      this.$emit("cancel");
    },
    // go() {
    //   window.location.href =
    //     "https://search.abb.com/library/Download.aspx?DocumentID=8DBD000062&LanguageCode=en&DocumentPartId=&Action=Launch";
    // },
    // cancel() {
    //   this.$emit("cancel");
    // },
  },
};
</script>

<style lang="scss" scoped="scoped">
/* .Bigbox {
  background-color: #f5f6fa;
  padding-bottom: 243px;
} */
/deep/ .news-dialog {
  min-height: 400px;
}
/deep/.el-dialog__header {
  padding: 35px 20px 10px;
}

/deep/.el-dialog__body {
  padding: 0px 20px;
}
// .deco {
//   position: absolute;
//   left: 0;
//   top: 78px;
//   width: 20px;
//   height: 28px;
//   background: #3e68ff;
// }
// .close_img {
//   width: 32px;
//   height: 32px;
//   position: absolute;
//   top: 30px;
//   right: 30px;
//   cursor: pointer;
// }
.box {
  box-sizing: border-box;
  width: 100%;
  background: #f7f9fd;
  // padding: 6rem 0 5.3rem;
  // padding: 6rem 0 18.3rem;
  padding: 16rem 0 18.3rem; //加上nav高度
  // border: 1px solid red;
}
.pro-advantage {
  box-sizing: border-box;
  width: 91.47%;
  margin: 0 auto;

  padding: 6.3rem 0 8.9rem;
  background: #fff;
}
.breadcrumb >>> .el-breadcrumb__inner {
  color: #3e68ff;
}

.contentOne {
  width: 91.47%;
  background: #ffffff;
}

.contentOneHead {
  /* padding-top: 46px; */
  display: flex;
  /* border: 0.0625rem solid; */
}

.fang {
  width: 1rem;
  // height: 9.4rem;
  background: #426cff;
}

.contentOneName {
  margin-left: 2.2rem;
  font-size: 3.6rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
}

.contentOneBody {
  margin-top: 30px;
  font-size: 2.8rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3d4566;
  line-height: 1.8;
  /* border: 1px solid blue; */
}

.box1 {
  display: flex;
  margin: 6.4rem 0 12rem;
  font-size: 2.4rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
}

.contentTwo {
  width: 91.47%;
  margin: 0 auto;
  // padding: 6.3rem 3.2rem 8.9rem;
  background: #ffffff;
}

.contentTwoTitle {
  padding-left: 39px;
  font-size: 24px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 28px;
}

.ldjj {
  padding-top: 48px;
}

.ldgg {
  margin-top: 81px;
}
.bb {
  margin-top: 78px;
}

.contentTwoContent {
  margin-top: 45px;
  // margin-left: 40px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3d4566;
  line-height: 32px;

  /deep/ p {
    // text-indent: 2em;//首行缩进
    margin-bottom: 7rem;
    img {
      width: 100% !important;
      height: 100% !important;
      margin: 0 auto;
    }
  }
}
.URL {
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3e68ff;
  line-height: 32px;
  cursor: pointer;
}
</style>
