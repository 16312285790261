<template>
  <div class="box">
    <div class="banner">
      <img src="../img/images/solution_banner.png" />
      <div class="bannerBox">
        <div class="bannerTitle">
          <span class="keyTxt">智慧服务</span>
        </div>
        <div class="bannerFront">
          全栈云服智慧服务平台为客户提供服务标准化全生命周期管理，基于工单的服务全流程管理，服务过程可视、可管、可追溯；云服云端专业服务团队，提供远程协助，服务快速可达；对现网设施设备提供统一监控管理，支持资产全生命周期管理，支持设备、告警纳管；提供智能巡检、漏洞扫描、补丁管理、项目管理及职能值班管理等，为客户提供便捷服务管理。
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 四项化 -->
      <div class="contentbox contentbox1 contentbox2">
        <div class="contentTitlebox">
          <div class="pro-advantage pro-qual">
            <div
              class="img-box"
              v-for="(item, index) in manageArr"
              :key="index"
            >
              <div class="img">
                <img src="../img/images/solution_icon1.png" />
              </div>
              <div class="title">{{ item.title }}</div>
              <p>{{ item.content }}</p>
            </div>
          </div>
          <div
            class=""
            :class="
              Animate1
                ? 'pro-advantage pro-qual pro-platform myanimation'
                : 'pro-advantage pro-qual pro-platform'
            "
            ref="oneBox"
          >
            <div class="service-platform">
              <div><img src="../img/solution/CHART1@2x.png" /></div>
              <div class="content-box">
                <div
                  v-for="(i, index) in serviceArr"
                  :key="index"
                  class="content"
                >
                  <div class="img">
                    <img src="../img/solution/icon@2x.png" />
                  </div>
                  <div>{{ i }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 闭环流程图 -->
      <div class="contentbox contentbox3">
        <div class="contentTitlebox">
          <div
            class=""
            :class="
              Animate2
                ? 'pro-advantage pro-intro myanimation'
                : 'pro-advantage pro-intro'
            "
            ref="twoBox"
          >
            <img src="../img/solution/bihuan@2x.png" />
          </div>
        </div>
      </div>

      <div class="contentbox contentbox4">
        <!-- 五个切换 -->
        <div class="pro-advantage">
          <div
            class=""
            :class="Animate3 ? 'manage-box myanimation' : 'manage-box'"
            ref="threeBox"
          >
            <div class="left">
              <div
                @click="changeClass(index)"
                v-for="(item, index) in arr"
                :class="{ choice: index == nowIndex }"
                :key="index"
                class="choiceBox"
              >
                <div class="name">{{ item.name }}</div>
              </div>
              <!-- @click="showAgent" -->
              <!-- 原试用直接弹窗对话 -->
              <!-- <div class="apply-btn" onClick="__egeelTalk()">申请试用</div> -->
              <a
                href="http://yun.fullstackcs.com/register"
                target="_blank"
                style="text-decoration: none !important"
                ><div class="apply-btn"><span>申请试用</span></div></a
              >
              <div class="apply-btn load-btn">
                <span>App下载</span>
                <div class="codeBox">
                  <!-- <img src="../img/images/codeImg.png" /> -->
                  <div class="codeImg">
                    <vue-qr
                      class="codeIMG"
                      :size="100"
                      :margin="0"
                      :auto-color="true"
                      :dot-scale="1"
                      :text="qrHref"
                      :logoSrc="qrIcon"
                    />
                  </div>
                  <span>全栈云服-智慧服务</span>
                </div>
              </div>
            </div>
            <div v-if="nowIndex == 0" class="right-box">
              <div
                class="right right1"
                v-for="(i, index) in rightArr"
                :key="index"
              >
                <div class="cont">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name">{{ item.title }}</div>
                    <p class="content">
                      {{ item.content }}
                    </p>
                  </div>
                </div>

                <div class="img">
                  <img src="../img/solution/s1_F1@2x.png" />
                </div>
                <div class="inner_box">
                  <img class="inner" src="../img/solution/tuoyuan1@2x.png" />
                </div>
              </div>
              <div class="outer_box">
                <img class="outer" src="../img/solution/tuoyuan2@2x.png" />
              </div>
            </div>
            <div v-if="nowIndex == 1" class="right-box">
              <div
                class="right right1"
                v-for="(i, index) in contentArr[0]"
                :key="index"
              >
                <div class="cont">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name content">{{ item }}</div>
                    <!-- <p class="content">
                      {{ item.content }}
                    </p> -->
                  </div>
                </div>

                <div class="img img1">
                  <img src="../img/solution/S1_F2@2x.png" />
                </div>
                <div class="inner_box">
                  <img class="inner" src="../img/solution/tuoyuan1@2x.png" />
                </div>
              </div>
              <div class="outer_box">
                <img class="outer" src="../img/solution/tuoyuan2@2x.png" />
              </div>
            </div>
            <div v-if="nowIndex == 2" class="right-box">
              <div
                class="right right1 right4"
                v-for="(i, index) in contentArr[1]"
                :key="index"
              >
                <div class="cont cont4">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name content">{{ item }}</div>
                    <!-- <p class="content">
                      {{ item.content }}
                    </p> -->
                  </div>
                </div>

                <div class="img img2">
                  <img src="../img/solution/S1_F3@2x.png" />
                </div>
                <div class="inner_box">
                  <img class="inner" src="../img/solution/tuoyuan1@2x.png" />
                </div>
              </div>
              <div class="outer_box">
                <img class="outer" src="../img/solution/tuoyuan2@2x.png" />
              </div>
            </div>
            <div v-if="nowIndex == 3" class="right-box">
              <div
                class="right right1 right3"
                v-for="(i, index) in contentArr[2]"
                :key="index"
              >
                <div class="cont">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name content">{{ item }}</div>
                    <!-- <p class="content">
                      {{ item.content }}
                    </p> -->
                  </div>
                </div>

                <div class="img img3">
                  <img src="../img/solution/S1_F4@2x.png" />
                </div>
                <div class="inner_box">
                  <img class="inner" src="../img/solution/tuoyuan1@2x.png" />
                </div>
              </div>
              <div class="outer_box">
                <img class="outer" src="../img/solution/tuoyuan2@2x.png" />
              </div>
            </div>
            <div v-if="nowIndex == 4" class="right-box">
              <div class="right right5">
                <div class="title">
                  支持一键式发起远程协助，可监管，处置过程通过录音录屏可追溯
                </div>
                <div>
                  <img class="right5-img" src="../img/solution/S1_F5@2x.png" />
                </div>
                <div class="inner_box">
                  <img class="inner" src="../img/solution/tuoyuan1@2x.png" />
                </div>
              </div>
              <div class="outer_box">
                <img class="outer" src="../img/solution/tuoyuan2@2x.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" onClick="__egeelTalk()">
      <img src="../img/index/icon_service@2x.png" />
      <span>在线咨询</span>
    </div>
    <ContactDialog :visible="contactVisible" @cancel="contactVisible = false" />
  </div>
</template>

<script>
import { isElementNotInViewport } from "../common/utils.js";
import ContactDialog from "./children/ContactDialog.vue";
import VueQr from "vue-qr";
export default {
  name: "App",
  components: {
    ContactDialog,
    VueQr,
  },
  data() {
    return {
      qrIcon: require("../img/images/logo.jpg"),
      qrHref:
        window.location.host == "192.168.82.5:8060" ||
        window.location.host == "http://test.fullstackcs.com"
          ? "http://testit.fullstackcs.com" + "/clientDown?type=saas"
          : "http://itss.fullstackcs.com" + "/clientDown?type=saas",
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      contactVisible: false,
      manageArr: [
        {
          title: "服务标准化",
          content:
            "参照ITSS等标准，及服务专家经验，将ICT服务过程进行标准化拆解，形成标准服务目录等，实现服务过程标准化管理",
        },
        {
          title: "流程可视化",
          content:
            "服务流程管理，依与系统强大的工单系统，实现服务在客户、服务机构的链接。全过程可视、可管可控、可追溯，保障服务质量。",
        },
        {
          title: "服务专业化",
          content:
            "智慧服务管理，实现客户、本地第三方服务机构及云端专业服务团队的能力链接，实现专业服务快速可达，享受专业、便捷的ICT服务。",
        },
        {
          title: "管理智能化",
          content:
            "通过对服务过程、服务商服务周期综合能力智能评估，提供服务商服务报表；支持智慧项目、需求及排班管理，实现服务全流程智慧管理。",
        },
      ],
      serviceArr: [
        "服务标准化全生命周期管理",
        "基于工单的服务全流程管理，服务过程可视、可管、可追溯",
        "云端专业服务团队托底，远程协助，服务快速到达",
        "一键远程协助，可监管，可追溯",
        "应急事件透明管理，一键发起应急响应、应急演练",
        "资产全生命周期管理，支持设备、告警纳管",
        "智能巡检、漏洞扫描、补丁管理",
        "智能项目及需求管理",
        "智能排班管理",
        "智能服务团队服务综合评价",
        "小程序、APP支持，移动办公无忧",
      ],
      arr: [
        {
          name: "服务内容标准化",
        },
        {
          name: "服务全周期管理",
        },
        {
          name: "标准服务过程管理",
        },
        {
          name: "云端专业服务快速可达",
        },
        {
          name: "一键式快速快速远程自助",
        },
      ],
      nowIndex: 0,
      rightArr: [
        {
          name: "全栈云服基于ISS等标准,总结团队10+年专业CT服务经验,分级ICT服务过程,梳理标准化服务流程",
          tip: [
            {
              title: "服务目录流程",
              content:
                "将ICT服务过程标准化拆解为服务流程节点;服务流程中的服务动作、内容及标准形成服务目录,实现服务的量化。",
            },
            {
              title: "服务合同",
              content:
                "提供标准服务合同模板,将ICT服务过程目录在合同中体现,实现服务内容、责任的明确边界界定。",
            },
            {
              title: "服务任务计划",
              content:
                "将服务流程节点,拆解为任务细项,预定义责任人、完成时间智能化管理。",
            },
          ],
        },
      ],
      contentArr: [
        [
          {
            name: "服务全周期管理",
            tip: [
              "ICT服务标准化管理，将ICT专业服务从签订合同到服务合同期满的全过程进行标准化拆解，参考合同服务目录，形成标准化的服务流程。通过服务目录、服务流程、服务任务，实现服务过程可视、可管。",
              "按照服务计划，进行智能可视化管理。基于时间、事件触发等，实现服务管理。",
              "支持客户进行自定义修改。",
            ],
          },
        ],
        [
          {
            name: "标准服务过程管理",
            tip: [
              "基于工单系统，支持服务过程事前可计划，事中可视，事后可审计、可追踪；",
              "支持对服务过程，进行全面评估服务能力",
            ],
          },
        ],
        [
          {
            name: "云端专业服务快速可达",
            tip: [
              "本地站点服务团队快速现场服务",
              "云端专家团队24小时在线服务协助",
              "400电话24*365实时在线",
            ],
          },
        ],
      ],
    };
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.oneBox);
      this.Animate2 = !isElementNotInViewport(this.$refs.twoBox);
      this.Animate3 = !isElementNotInViewport(this.$refs.threeBox);
    },
    changeClass(index) {
      this.nowIndex = index;
    },
    // 在线客服
    showAgent() {
      var that = this;
      that.contactVisible = true;
    },
    // 最新新闻-查看详情
    viewDetail() {},
  },
};
</script>

<style lang="scss" scoped="scoped">
//产品优势 title
.myanimation {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 20px;
  bottom: 10%;
  z-index: 999;
  top: 465px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  height: 140px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  cursor: pointer;

  img {
    width: 41px;
    height: 41px;
  }
  span {
    width: 16px;
    line-height: 1.1;
  }
}
.box {
  // padding-bottom: 100px;
  width: 100%;
  /* border: 1px solid red; */
}

.banner {
  width: 1903px;
  height: 400px;
  padding-top: 70px;
  background: #eceff8;
  // padding-top: 70px;
  // background: url(../img/index/index_banner@2x.png) no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  /* height: 100%; */
  // width: 750px;
  // padding: 0px 0 0 360px;
  /* border: 1px solid red; */
  width: 63%;
  margin: 0 auto;
  margin-top: -310px;
}
.bannerTitle {
  font-size: 48px;
  font-family: Source Han Sans SC;
  font-weight: 800;
  color: #101635;
}
.keyTxt {
  color: #3e68ff;
}
.bannerFront {
  /* width: 410px;
  height: 18px; */
  width: 63%;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #101635;
  padding: 26px 15px 58px 0;
  line-height: 1.7;
}

.title1 {
  font-size: 32px;
  font-family: DOUYU;
  font-weight: normal;
  color: #ffffff;
  margin: 168px 0 0 360px;
}

.title2 {
  margin: 22px 0 0 359px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #ffffff;
}

.contentbox {
  width: 100%;
  height: 600px;
}

.btn {
  width: 144px;
  height: 48px;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 24px;
  margin: 59px 0 0 360px;
}

.dian {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  float: left;
  margin: 20px 0 0 20px;
}

.font {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  margin-right: 60px;
}

.More {
  margin-left: 51px;
}

.contentTitle {
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
  padding-top: 98px;
  /* margin-top: 98px; */
}

.contentTitleS {
  font-size: 18px;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #8f94b2;
  margin-top: 12px;
}

.contentBox {
  // margin-top: 91px;
  display: flex;
  flex-wrap: wrap;
}

.contentBoxS {
  height: 170px;
  width: 210px;
  margin: 0 50px 0 50px;
}

.contentBoxSs {
  margin-left: 20%;
  display: flex;
}
.contentbox1 {
  text-align: center;
  height: 700px;
  background: #eef6ff;
  /* border-bottom: 1px solid #e1e7ff; */
}
// 四项化
.contentbox2 {
  height: 1189px;
  background: #f9faff;
}
.contenttitle {
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 44px;
}

.contentDetails {
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 30px;
  text-align: left;
}
.pro-platform {
  // padding: 0 360px 0 !important;
  padding: 0 !important;
}
.service-platform {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  // width: 1200px;
  width: 100%;
  margin: 0 auto;
  height: 572px;
  background: #ffffff;
  border: 1px solid #e6ebfa;
  padding: 48px 31px 51px 37px;
  margin-bottom: 56px;
  img {
    width: 748px;
    height: 473px;
  }
  .content-box {
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #3d4566;
    padding: 20px 0 30px 40px;
    .content {
      padding-bottom: 13px;
      display: flex;
      text-align: left;
      line-height: 1.3;
      align-items: center;
      // flex-direction: column;
      // justify-content: space-between;
      .img {
        display: inline-block;
        margin-right: 7px;
        margin-top: 5px;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

// 闭环流程图
.contentbox3 {
  box-sizing: border-box;
  height: 752px;
  background: #ffffff;
  padding-top: 49px;
}
.pro-intro {
  padding-top: 35px;

  text-align: center;
  img {
    width: 1111px;
    height: 612px;
  }
}
.contentbox4 {
  height: 745px;
  background: #f7fbfe;
  padding-top: 104px;
}
/* 五个切换 */
.pro-advantage {
  // padding: 0 360px;
  width: 63%;
  margin: 0 auto;
}
.manage-box {
  display: flex;
  justify-content: space-between;
}

.choiceBox {
  width: 280px;
  height: 64px;
  line-height: 64px;
  background: #ebf2f9;
  border-radius: 6px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  margin-bottom: 20px;
  cursor: pointer;
}
.name {
  padding-left: 25px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
}

.choice {
  color: #ffffff;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
}
.apply-btn {
  width: 180px;
  height: 56px;
  line-height: 56px;
  background: #0cdec0;
  border: 1px solid #ffffff;
  box-shadow: 7px 7px 30px 0px rgba(12, 222, 192, 0.25);
  border-radius: 28px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #ffffff;
  margin-top: 56px;
  text-align: center;
  cursor: pointer;
}
.load-btn {
  position: relative;
  margin-top: 29px;
  &:hover {
    .codeBox {
      display: block;
    }
  }

  .codeBox:hover {
    display: block;
  }

  .codeBox {
    // display: block;
    display: none;
    width: 180px;
    height: 180px;
    // background: linear-gradient(
    //   20deg,
    //   #d6cafc,
    //   #e7e3fc,
    //   #eae6f1,
    //   #ebedff,
    //   #f1f6fa,
    //   #f3f2fc
    // );
    border-radius: 6px;
    padding: 20px;
    border: 1px solid #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(161, 167, 179, 0.3);
    background: linear-gradient(0deg, #f5f9ff, #e5f7fd);
    border: 1px solid #ffffff;
    // box-shadow: 0px 10px 30px 0px rgba(161, 167, 179, 0.3);
    text-align: center;
    font-size: 13px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #111834;
    position: absolute;
    top: -58px;
    left: 200px;
    padding: 22px 20px 0;
    box-sizing: border-box;
    z-index: 9;

    .codeIMG {
      width: 100px;
      height: 100px;
      margin-bottom: -12px;
      z-index: 99;
      // margin-left: 9px;
    }
    > span {
      font-size: 16px;
      font-family: PingFang HK;
      font-weight: 500;
      color: #3d4566;
      margin-top: 8px;
      display: inline-block;
    }
  }
}
.right {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 900px;
  height: 520px;
  background: #ebf2f9;
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin-left: 20px;
  padding: 60px 20px 0 40px;
  .title {
    font-size: 18px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
  }
  .inner_box {
    position: absolute;
    right: 0;
    top: 0;
    .inner {
      width: 260px;
      height: 220px;
    }
  }
}
.right-box {
  position: relative;
}
.outer_box {
  position: absolute;
  right: -44px;
  top: -54px;
  z-index: 0;
  .outer {
    width: 180px;
    height: 180px;
  }
}
.right1 {
  display: flex;
  .cont {
    width: 50%;
    margin-right: 40px;
  }
  .img {
    width: 381px;
    height: 370px;
    position: relative;
    z-index: 999;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .img1 {
    width: 502px;
    height: 437px;
  }
  .img2 {
    // width: 680px;
    // height: 437px;
    position: relative;
    left: 10%;
    height: 426px;
    width: 1204px;
  }
  .img3 {
    width: 517px;
    height: 451px;
  }

  .tip {
    margin-top: 38px;
    .tip-name {
      font-size: 18px;
      font-family: PingFang HK;
      font-weight: 500;
      color: #3d4566;
    }
    .tip-name::before {
      display: inline-block;
      content: "";
      width: 8px;
      height: 8px;
      border: 2px solid #5f79ff;
      border-radius: 50%;
      margin-right: 5px;
    }
    .content {
      font-size: 14px;
      font-family: PingFang HK;
      font-weight: 400;
      color: #8f94b2;
      margin-top: 15px;
      line-height: 1.5;
    }
  }
}
.right2 {
  .cont {
    margin-right: 60px;
  }
}
.right3 {
  .cont {
    margin-right: -120px;
  }
}

.right5 .right5-img {
  width: 800px;
  height: 275px;
  margin-top: 49px;
}
.right4 {
  .cont4 {
    width: 43%;
  }
  .img {
    width: 569px;
    height: 437px;
    position: absolute;

    left: 49%;
  }
}
//  企业资质
.pro-qual {
  display: flex;
  justify-content: space-between;
  // padding: 59px 360px 89px;
  // padding-top: 59px;
  padding-top: 95px;
  padding-bottom: 88px;
  width: 63%;
  margin: 0 auto;
}
.img-box {
  box-sizing: border-box;
  padding: 0 30px;
  // width: 285px;
  width: 280px;
  height: 360px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    4px 7px 20px 0px rgba(55, 99, 170, 0.1);
  border-radius: 10px;
  text-align: center;
  img {
    width: 160px;
    height: 160px;
  }
  .title {
    font-size: 20px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    margin: 18px 0 20px 0;
  }
  p {
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    line-height: 1.7;
    text-align: left;
  }
}

.cloudBox {
  width: 55%;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  margin: 80px auto;
  justify-content: space-between;
}

.cloudLeft {
  width: 250px;
  height: 200px;
  /* margin-left: 10%; */
}

.cloudLeft img {
  width: 100%;
  height: 100%;
}

.cloudRight {
  /* border: 1px solid; */
  width: 543px;
  height: 176px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 32px;
  /* margin-left: 179px; */
}

.cooperationBanner {
  display: flex;
  width: 100%;
  height: 500px;
  background-image: url(../img/index_part3_logos_png.png);
}

.cooperationBox {
  width: 524px;
  height: 284px;
  background: #f9faff;
  /* border: 1px solid red; */
  text-align: center;
  margin: 108px auto;
  /* 不透明度 */
  opacity: 0.8;
}

.cooperationTitle {
  margin-top: 94px;
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  /* line-height: 44px; */
}

.cooperationTitleEnglish {
  margin-top: 13px;
  font-size: 14px;
  font-family: JI-Sludge;
  font-weight: 600;
  color: #8f94b3;
  /* line-height: 44px; */
}

.cooperationFoot {
  margin-top: 29px;
  font-size: 12px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  /* line-height: 44px; */
}

@media screen and (max-width: 479px) {
  .banner {
    width: 160%;
    height: 200px;
    position: relative;
  }
  .banner {
    width: 230%;
    height: 160px;
  }
  .bannerBox {
    height: 100%;
    padding: 0px 0 0 20px;
    margin-top: -120px;
  }
  .banner img {
    width: 100%;
    height: 100%;
    position: absolute;
    /* clip: rect( 0 500px 0 500px); */
  }
  .contentTitle {
    font-size: 15px;
    text-align: center;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    line-height: 44px;
    margin-top: 24px;
  }
  .contentBox {
    width: 100%;
    height: 390px;
    margin-top: 24px;
  }
  .contentBoxS {
    height: 85px;
    width: 100%;
    margin: 0 25px 0 25px;
  }
  .contentBoxSs {
    margin: 0 5% 0;
  }
  .contentbox1 {
    width: 100%;
    /* text-align: center; */
  }
  .contenttitle {
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 600;
    color: #3d4566;
    line-height: 44px;
  }
  .contentDetails {
    font-size: 12px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    line-height: 28px;
    text-align: center;
  }
  .teps {
    width: 100%;
    height: 0;
    margin: 59px auto;
    display: flex;
  }
  .tepsBox {
    height: 52px;
    width: 52px;
    /* border: 1px solid red; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tepsBox:first-child {
    margin-left: 10px;
  }
  .tepsImgs {
    height: 52px;
    width: 52px;
    border-radius: 50%;
  }

  .tepsImgs img {
    position: relative;
    top: 5px;
  }
  .choiceImg {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #fafbff;
    box-shadow: 0px 10px 20px 0px rgba(184, 188, 204, 0.3);
    border-radius: 50%;
  }

  .choiceImg img {
    position: relative;
    top: 5px;
  }

  .tepsName {
    width: 73px;
    height: 44px;
    font-size: 15px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    line-height: 44px;
  }
  .tepsFootBox {
    width: 160%;
    margin: 15px 0 0 30%;
  }

  /* 云 拓扑平台区域 */
  .cloudBox {
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    margin: 24px auto;
    /* 分配弹性元素方式 均匀排列每个元素，首：起点 尾：终点 */
    justify-content: space-between;
  }
  .cloudLeft {
    width: 150px;
    height: 100px;
    /* margin-left: 10%; */
  }

  .cloudRight {
    /* border: 1px solid; */
    width: 543px;
    height: 200px;
    font-size: 12px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    line-height: 32px;
    margin-bottom: 20px;
  }
  /* 底部合作banner区域 */
  .cooperationBanner {
    width: 100%;
    height: 50%;
    /* transform: scale(0.5); */
  }
  /* 最新新闻区域 */
  .newsBox {
    width: 100%;
  }
  .contentbox .contentboxHeight {
  }
  .newsRightBox {
    width: 110px;
    height: 180px;
  }
  .rightBoxfoot {
    margin-top: 5px;
  }
}
</style>
