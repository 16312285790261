<template>
  <div class="box">
    <div class="banner">
      <div class="bannerBox">
        <div class="bannerHead">
          <div class="fwzd">服务站点</div>
          <div class="fwzdEnglish">Service network</div>
        </div>
        <div class="map">
          <div class="mapLeft">
            <Map></Map>
          </div>
        </div>
      </div>

      <!-- <img src="../img/banner.png" > -->
    </div>
    <!-- 服务体系/团队介绍 -->
    <div class="pro-advantage">
      <div class="pro-top">
        <div>
          <div class="pro-font">服务体系</div>
          <div
            style="
              color: #8f94b2;
              font-family: JI-Sludge;
              font-size: 18px;
              margin-top: 12px;
            "
          >
            SERVICE SYSTEM
          </div>
        </div>
        <div class="link">
          <p>服务宗旨：让优质的服务更贴近客户</p>
          <p>
            服务体系：线下7*10现场驻场服务，线上24*365专家全天支持，采用主动运维、应急服务等，解决运维服务人员能力不足问题
          </p>
        </div>
      </div>
      <div
        class=""
        :class="Animate1 ? 'modal myanimation' : 'modal'"
        ref="animateBox"
      >
        <div class="items">
          <div class="title">响应运维</div>
          <div class="content">
            <div style="margin-bottom: 10px">
              在线咨询<span style="margin-left: 15px">服务体系</span>
            </div>
            <div>现场服务<span style="margin-left: 15px">备件服务</span></div>
          </div>
          <img src="../img/DECOL@2x.png" class="itmesImg" alt="" />
        </div>
        <div class="items none-border">
          <div class="title">主动运维</div>
          <div class="content">
            <div style="margin-bottom: 10px">
              健康检查<span style="margin-left: 15px">驻场服务</span>
            </div>
            <div>事件保障<span style="margin-left: 15px">版本维护</span></div>
          </div>
          <img src="../img/DECO2LL.png" class="itmesImg" alt="" />
        </div>
        <div class="items none-border">
          <div class="title">应急响应</div>
          <div class="content">
            <div style="margin-bottom: 10px">
              应急响应<span style="margin-left: 15px">安全响应</span>
            </div>
            <div>溯源取证<span style="margin-left: 15px">灾难恢复</span></div>
          </div>
          <img src="../img/DECO3L@2x.png" class="itmesImg" alt="" />
        </div>
        <div class="items none-border">
          <div class="title">硬件保固</div>
          <div class="content">
            <div style="margin-bottom: 10px">
              设备保养<span style="margin-left: 15px">硬件保养</span>
            </div>
            <div>设备维修</div>
          </div>
          <img src="../img/DECO4L@2x.png" class="itmesImg" alt="" />
        </div>
      </div>
      <div
        class=""
        :class="Animate3 ? 'pro-top pro-top2 myanimation1' : 'pro-top pro-top2'"
        ref="titleBox"
      >
        <div>
          <div class="pro-font">团队介绍</div>
          <div
            style="
              color: #8f94b2;
              font-family: JI-Sludge;
              font-size: 18px;
              margin-top: 12px;
            "
          >
            OUR TEAM
          </div>
        </div>
        <div class="link">
          <p>
            公司团队主要涉及网络、安全、主机、系统、存储、云平台、数据库及机房基础设备类工程师
          </p>
        </div>
      </div>
      <div
        class=""
        :class="Animate2 ? 'list myanimation' : 'list'"
        ref="teamBox"
      >
        <div class="listItem">
          <div class="header">
            <div class="pepole">CT网络安全</div>
            <div class="num">40+</div>
          </div>
          <div class="personnel">
            <div class="perList">
              <div class="item">
                <div class="title">CCIE/HCIE/H3CTE</div>
                <div class="perNum">10+</div>
              </div>
              <div class="item">
                <div class="title">CCNP/HCIP/H3CSE</div>
                <div class="perNum">30+</div>
              </div>
            </div>
          </div>
        </div>
        <div class="listItem">
          <div class="header">
            <div class="pepole">IT服务存储系统</div>
            <div class="num">40+</div>
          </div>
          <div class="personnel">
            <div class="perList">
              <div class="item">
                <div class="title">Linux/Unix RHCE及以上</div>
                <div class="perNum">20+</div>
              </div>
              <div class="item">
                <div class="title">Linux/Unix AIX认证工程师</div>
                <div class="perNum">3</div>
              </div>
              <div class="item">
                <div class="title">虚拟化云平台华为/vMware/Redhat</div>
                <div class="perNum">10+</div>
              </div>
            </div>
          </div>
        </div>
        <div class="listItem">
          <div class="header">
            <div class="pepole">DT数据技术</div>
            <div class="num">10+</div>
          </div>
          <div class="personnel">
            <div class="perList">
              <div class="item">
                <div class="title">Oracle OCP以上</div>
                <div class="perNum">6</div>
              </div>
              <div class="item">
                <div class="title">InterSystems</div>
                <div class="perNum">2</div>
              </div>
              <div class="item">
                <div class="title">SQL类</div>
                <div class="perNum">10</div>
              </div>
            </div>
          </div>
        </div>
        <div class="listItem">
          <div class="header">
            <div class="pepole">RD研发工程师</div>
            <div class="num">26+</div>
          </div>
          <div class="personnel">
            <div class="perList">
              <div class="item">
                <div class="title">后端开发</div>
                <div class="perNum">20+</div>
              </div>
              <div class="item">
                <div class="title">前端</div>
                <div class="perNum">6+</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" onClick="__egeelTalk()">
      <img src="../img/index/icon_service@2x.png" />
      <span>在线咨询</span>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../common/utils.js";
import Map from "../components/MapW.vue";
export default {
  components: {
    Map,
  },
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
    };
  },

  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.animateBox);
      this.Animate2 = !isElementNotInViewport(this.$refs.teamBox);
      this.Animate3 = !isElementNotInViewport(this.$refs.titleBox);
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
//modal
.myanimation {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.myanimation1 {
  animation: load1 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load1 {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 20px;
  bottom: 10%;
  z-index: 999;
  top: 465px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  height: 140px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  cursor: pointer;

  img {
    width: 41px;
    height: 41px;
  }
  span {
    width: 16px;
    line-height: 1.1;
  }
}
.box {
  width: 100%;
  padding-bottom: 124px;
  background: #fbfcff;
  /* border: 1px solid #101635; */
}
.banner {
  width: 1903px;
  height: 700px;
  padding-top: 70px;
  background: linear-gradient(180deg, #4971ff, #335fff);
}
.bannerBox {
  // padding: 0 360px;
  margin: 0 auto;
  padding-top: 100px;
  /* border: 1px solid white; */
  // width: 60%;
}
.bannerHead {
  // padding: 0 360px;
  width: 63%;
  margin: 0 auto;
  /* border: 1px solid black; */
}
.fwzd {
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #fefefe;
  line-height: 44px;
}
.fwzdEnglish {
  font-size: 18px;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #ffffff;
  line-height: 44px;
}
/* 服务体系/团队介绍 */
.pro-advantage {
  // padding: 0 360px;
  box-sizing: border-box;

  // width: 62%;
  width: 63%;
  margin: 0 auto;
  /* TODo 改高度*/
  height: 1140px;
  .pro-top {
    display: flex;
    justify-content: space-between;
    text-align: right;
    align-items: flex-end;
    padding-top: 120px;
    .link {
      font-size: 14px;
      font-family: PingFang HK;
      font-weight: 400;
      color: #8a8c99;
    }
  }
  .pro-top2 {
    align-items: center;
  }
}
.pro-font {
  width: 112px;
  font-size: 28px;
  color: #3d4566;
}
.modal {
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 60px;

  .items {
    width: 342px;
    height: 202px;
    background: #ffffff;
    border: 1px solid #ebeef5;
    display: flex;
    flex-direction: column;
    padding: 34px 32px 34px 32px;
    position: relative;

    .title {
      font-size: 18px;
      font-family: PingFang HK;
      font-weight: 500;
      color: #3d4566;
      margin-bottom: 25px;
    }
    .itmesImg {
      position: absolute;
      width: 103px;
      height: 96px;
      right: 32px;
      bottom: 8px;
    }
    .content {
      div {
        font-size: 14px;
        font-family: PingFang HK;
        font-weight: 400;
        color: #8f94b2;
      }
    }
  }
  .none-border {
    border-left: none !important;
  }
}
.list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  .listItem {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 291px;
    background: #ffffff;
    border: 1px solid #ebeef5;
    // box-shadow: 0px 10px 30px 0px rgba(143, 162, 179, 0.2);
    .header {
      box-sizing: border-box;
      height: 82px;
      background: #f5f6fa;
      border-bottom: 1px solid #ebeef5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 18px;
      padding-right: 18px;
      .pepole {
        font-size: 18px;
        font-family: PingFang HK;
        font-weight: 500;
        color: #3d4566;
      }
      .num {
        font-size: 20px;
        font-family: Bebas;
        font-weight: 400;
        color: #3d4566;
      }
    }
    .personnel {
      // padding: 20px;
      padding: 17.5px;
      .perList {
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 21px;
          padding-right: 21px;
          margin-bottom: 10px;
          // width: 285px;
          height: 48px;
          background: #f5f6fa;
          .title {
            font-size: 13px;
            font-family: PingFang HK;
            font-weight: 400;
            color: #3d4566;
          }
          .perNum {
            font-size: 14px;
            font-family: PingFang HK;
            font-weight: 400;
            color: #3d4566;
          }
        }
      }
    }
  }
}
.map {
  /* border: 1px solid red; */
}
.mapLeft {
  // width: 500px;
  // height: 540px;
}
@media screen and (max-width: 479px) {
  .banner {
    width: 230%;
    height: 600px;
  }

  .map {
    margin-left: -200px;
  }
}
</style>
