<template>
	<div class="box">
		<div class="body">
			<div class="left">
				<div class="Photo">
					<img src="../../img/photo.png">
				</div>
				<div class="foot">
					<div class="name">
						王希子
					</div>
					<div class="position">
						安全网络专家
					</div>
				</div>
			</div>
			<div class="right">
				<div class="head">
					<div class="zjjj">
						专家简介
					</div>
					<div class="zjjjEnglish">
						introduction
					</div>
					<div class="introduction">
						善于书法，兼善隶、草、楷、行各体，精研体势，心摹手追，广采众长，备精诸体，冶于一炉，摆脱汉魏笔风，自成一家，影响深。风格平和自然，笔势委婉含蓄，遒美健秀。在书法史上，与钟繇并称"钟王"，与其子王献之合称“二王”。李志敏评价：“王羲之的书法既表现以老庄哲学为基础的简淡玄，又表现以儒家的中庸之道为基础的冲和。
					</div>
				</div>
				<div class="rightBody">
					<div class="rightBodyHead">
						<div class="fwal">
							服务案例
						</div>
						<div class="fwalEnglish">
							CASES
						</div>
					</div>
					<div v-for="(item,index) in arr" :key="index"  class="rightBodyBox">
						<div class="casesBox">
							<div class="casesHead">
								<div class="fang"></div>
								<div class="casesTitle">
									XX视讯一资源池四、五期集成交付
								</div>
							</div>
							<div class="casesBody">
								<div class="casesBodybox">
									<div class="front">
										涉及厂商:
									</div>
									<div class="after">
										&nbsp; &nbsp;华为、H3C、锐捷、中兴、CISC0、HE、DELL、浪潮、曙光、烽火、F5等。
									</div>
								</div>
								<div class="casesBodybox">
									<div class="front">
										涉及技术:
									</div>
									<div class="after">
										&nbsp; &nbsp;SDN数据中心、 Openstack、分布式存储、 Docker、SD_WAN、网络安全等。
									</div>
								</div>
								<div class="casesBodybox">
									<div class="front">
										主要工作:
									</div>
									<div class="after">
										&nbsp; &nbsp;深化设计、设备安装调试、业务搬迁、系统及应用部署、纳管、驻场运维等。
									</div>
								</div>
							</div>
							<div class="casesFoot">
								<div class="casesFootBox">
									<div class="yuan">
										<img src="../../img/support_icon_3.png" >
									</div>
									<div class="num">
										27
									</div>
									<div class="zi">
										亿总造价
									</div>
								</div>
								<div class="casesFootBox">
									<div class="yuan">
										<img src="../../img/support_icon_3.png" >
									</div>
									<div class="num">
										520
									</div>
									<div class="zi">
										个机架
									</div>
								</div>
								<div class="casesFootBox">
									<div class="yuan">
										<img src="../../img/support_icon_3.png" >
									</div>
									<div class="num">
										5000+
									</div>
									<div class="zi">
										服务器ARM+x86
									</div>
								</div>
							</div>
							
							<div class="casesFoot2">
								<div class="casesFootBox">
									<div class="yuan">
										<img src="../../img/support_icon_3.png" >
									</div>
									<div class="num">
										700+
									</div>
									<div class="zi">
										台网络设备
									</div>
								</div>
								<div class="casesFootBox">
									<div class="yuan">
										<img src="../../img/support_icon_3.png" >
									</div>
									<div class="num">
										60
									</div>
									<div class="zi">
										PB存储容量
									</div>
								</div>
								<div class="casesFootBox">
									<div class="yuan">
										<img src="../../img/support_icon_3.png" >
									</div>
									<div class="num">
										15000+
									</div>
									<div class="zi">
										应用VM+Docker
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default{
		data(){
			return{
				arr:[1,2,3]
			}
		},
	}
</script>

<style scoped="scoped">
	.box {
		background-color: #FBFCFF;
		padding-bottom: 210px;
	}

	.body {
		width: 63%;
		margin: 0px auto;
		padding-top: 80px;
		display: flex;
		min-width: 820px;
	}

	.left {
		width: 232px;
	}

	.Photo {
		width: 100%;
	}

	.Photo img {
		width: 100%;
	}

	.foot {
		width: 100%;
		height: 104px;
		background-color: #3E68FF;
		float: left;
	}

	.name {
		font-size: 28px;
		font-family: PingFang HK;
		font-weight: 500;
		color: #FFFFFF;
		margin: 11px 0 0 19px;
	}

	.position {
		font-size: 14px;
		font-family: PingFang HK;
		font-weight: 400;
		color: #FFFFFF;
		margin: 16px 0 0 19px;
	}

	.right {
		margin-left: 68px;
		width: 100%;
	}

	.zjjj {
		font-size: 28px;
		font-family: PingFang HK;
		font-weight: 500;
		color: #3D4566;
	}

	.zjjjEnglish {
		font-size: 18px;
		font-family: JI-Sludge;
		font-weight: 400;
		color: #8F94B2;
		margin-top: 12px;
	}

	.introduction {
		font-size: 16px;
		font-family: PingFang HK;
		font-weight: 00;
		color: #8F94B2;
		line-height: 32px;
		margin-top: 47px;
	}
	.rightBody{
		margin-top: 99px;
	}
	.fwal{
		font-size: 28px;
		font-family: PingFang HK;
		font-weight: 500;
		color: #3D4566;
		line-height: 44px;
	}
	.fwalEnglish{
		font-size: 18px;
		font-family: JI-Sludge;
		font-weight: 400;
		color: #8F94B2;
	}
	.rightBodyBox{
		border: 1px solid #E6EBFA;
		margin-top: 59px;
		background-color: #FFFFFF;
	}
	.casesHead{
		display: flex;
		margin-top: 40px;
	}
	.fang{
		width: 10px;
		height: 31px;
		background: #3E68FF;
	}
	.casesTitle{
		font-size: 24px;
		font-family: PingFang HK;
		font-weight: 500;
		color: #3D4566;
		margin-left: 28px;
	}
	.casesBody{
		margin-top: 40px;
		margin-left: 40px;
		height: 88px;
	}
	.casesBodybox{
		font-size: 16px;
		font-family: PingFang HK;
		font-weight: 500;
		color: #8F94B2;
		line-height: 30px;
		display: flex;
	}
	.front{
		font-weight: 600;
	}
	.casesFoot{
		margin-top: 40px;
		height: 97px;
		background-color: #E6EBFA;
		display: flex;
	}
	.casesFoot2{
		margin-top: 1px;
		height: 97px;
		background-color: #E6EBFA;
		display: flex;
	}
	.casesFootBox{
		width: 300px;
		height: 40px;
		margin-top: 24px;
		display: flex;
		border-right: 2px solid #EBEBEB;
	}
	.yuan{
		width: 48px;
		height: 48px;
		border-radius: 50%;
		background-color: #FFFFFF;
		margin-left: 40px;
	}
	.yuan img{
		margin: 11px 12px;
	}
	.num{
		font-size: 24px;
		font-family: Mont;
		font-weight: 600;
		color: #3D4566;
		line-height: 40px;
		margin-left: 10px;
	}
	.zi{
		font-size: 14px;
		font-family: PingFang HK;
		font-weight: 400;
		color: #3D4566;
		line-height: 40px;
	}
	@media screen and (max-width:479px) {
		.box{
			width: 230%;
		}
		.body {
			width: 100%;
			min-width: 0px;
		}
		.right {
			margin-left: 38px;
			width: 560px;
		}
		.foot {
			height: 104px;
			background-color: #3E68FF;
			float: left;
		}
		.casesBodybox{
			font-size: 12px;
		}
		.yuan{
			width: 28px;
			height: 28px;
			margin-top: 5px;
			margin-left: 0px;
		}
		.yuan img{
			width: 60%;
			height: 60%;
			margin: 6px 6px;
		}
		.num{
			font-size: 8px;
		}
		.zi{
			font-size: 7px;
		}
	}
</style>
