<template>
  <div v-if="showBox">
    <div
      class="mask"
      @touchmove.stop.prevent
      @click="changeMask"
      v-if="showMask"
    ></div>
    <div class="contentDig">
      <!-- <div class="mask" @touchmove.stop.prevent></div> -->
      <div class="title">
        {{ docData.name }}
      </div>
      <div class="content">
        {{ docData.description }}
      </div>
      <div class="downContent">
        <div
          class="downItem"
          v-for="(i, index) in docData.attachment"
          :key="index"
        >
          <div class="left">
            <div class="typeImg" v-if="docData.type == '1'">
              <img
                class="down_img"
                src="~@/img/images/support_icon_1@2x.png"
                alt=""
              />
            </div>
            <div class="typeImg" v-if="docData.type == '2'">
              <img
                class="down_img"
                src="~@/img/images/support_icon_2@2x.png"
                alt=""
              />
            </div>
            <div class="typeImg" v-if="docData.type == '3'">
              <img
                class="down_img"
                src="~@/img/images/support_icon_3@2x.png"
                alt=""
              />
            </div>
            <div class="fileName">{{ i.name }}</div>
          </div>
          <div class="right" @click="downFile(i)">下载</div>
          <!-- <div class="right">下载中..</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/api/api_surpport";
export default {
  data() {
    return {
      showMask: false,
      showBox: false,
      downLoading: false,
      data: this.docData,
    };
  },
  props: {
    // visible: {
    //   type: Boolean,
    // },
    docData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    changeMask() {
      this.showMask = false;
      this.showBox = false;
    },
    showDig() {
      this.showMask = true;
      this.showBox = true;
    },
    // downFile() {
    //   // 下载完成 关闭弹框
    //   // 下载中
    //   // this.downLoading = true
    //   //下载完成loading为false
    //   // this.downLoading = false
    //   this.changeMask();
    // },
    downFile(e) {
      // const params = new URLSearchParams();
      // var e = JSON.parse(demoFile)[0];
      API.downFile({
        fileName: e.name,
        fileUrl: e.url,
      }).then((res) => {
        if (res.resultCode) {
          return;
        }
        var allStr = res;
        var export_blob = new Blob([allStr]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([allStr]), e.name);
        } else {
          var save_link = document.createElement("a");
          save_link.href = window.URL.createObjectURL(export_blob);
          save_link.download = e.name;
          save_link.click();
          this.downLoading = true;
          // this.$message.success("下载成功！");
          setTimeout(() => {
            // this.$message.success("文件下载成功！");
          }, 500);
        }
        this.downLoading = false;
        this.changeMask();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2022;
}
.contentDig {
  position: fixed;
  z-index: 99999999;
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6.4rem 4.5rem 5.5rem 4.5rem;
  .title {
    font-size: 3.6rem;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    margin-bottom: 4.7rem;
  }
  .content {
    font-size: 2.8rem;
    font-family: PingFang HK;
    font-weight: 400;
    color: #3d4566;
    line-height: 5.6rem;
    margin-bottom: 8rem;
  }
  .downContent {
    display: flex;
    flex-direction: column;
    max-height: 48rem;
    overflow: auto;
    .downItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2.6rem 3rem;
      background: #f4f8fb;
      margin-bottom: 2rem;
      .left {
        display: flex;
        align-items: center;
        .typeImg {
          position: relative;
          width: 8.8rem;
          height: 8.8rem;
          line-height: 12rem;
          background: #ffffff;
          border-radius: 50%;
          margin-right: 1.4rem;
          text-align: center;
          display: table-cell;
          img {
            position: absolute;
            top: 1.4rem;
            right: 1.4rem;
            width: 5.8rem;
            height: 5.8rem;
          }
        }
        .fileName {
          font-size: 2.8rem;
          font-family: PingFang HK;
          font-weight: 400;
          color: #3d4566;
          max-width: 33rem;
          word-break: break-all;
        }
      }
      .right {
        width: 12.8rem;
        height: 7.2rem;
        background: linear-gradient(0deg, #3e68ff, #5c7fff);
        box-shadow: 0px 0.3rem 0.4rem 0px rgba(62, 104, 255, 0.3),
          0px 0.1rem 0.3rem 0px rgba(91, 127, 255, 0.4);
        border-radius: 3.6rem;
        font-size: 2.8rem;
        font-family: PingFang HK;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>