/**
 * 通知公告
 */

import * as API from './'

export default {
    // 查询数据字典
    findDictionaryList: params => {
        return API.POST("/admin/resourcesdownload/getType4site", params);
    },
    //分页
    //  文档列表
    resourcesList: params => {
        return API.POST('/admin/resourcesdownload/searchPage4site', params)
    },
    // 详情
    resourceDetail: params => {
        return API.POST('/admin/resourcesdownload/detail4site?id=' + params, params)
    },

    //下载文件
    downFile: (params) => {
        return API.POST(`/admin/resourcesdownload/download4site?fileName=${params.fileName}&fileUrl=${params.fileUrl}`);

    },
    // 新闻分页
    newsList: (params) => {
        return API.POST("/admin/webSite/newsInformation/getPage", params);
    },

    // 新闻详情
    newsDetail: params => {
        return API.GET("/admin/webSite/newsInformation/getInfo?id=" + params.id);
    },
    // 融云客服id
    getKFid: params => {
        return API.POST("/admin/operating/transfer/getCustomerServiceId", params);
    },
    // 融云客服token
    getIMtoken: params => {
        return API.POST("/admin/operating/transfer/getToken", params);
    },
    // 融云上传图片的接口
    sendImg: params => {
        return API.POST("/admin/operating/transfer/getRongYunImgFile", params);
    },
}