import { render, staticRenderFns } from "./DetailsDialog.vue?vue&type=template&id=76b1f523&scoped=true&"
import script from "./DetailsDialog.vue?vue&type=script&lang=js&"
export * from "./DetailsDialog.vue?vue&type=script&lang=js&"
import style0 from "./DetailsDialog.vue?vue&type=style&index=0&id=76b1f523&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76b1f523",
  null
  
)

export default component.exports