<template>
  <div>
    <!-- pc端 -->
    <HeadPc v-if="path == '/YuService' && !isMobile" />
    <HeadPc
      v-else-if="!isMobile && path != '/YuService'"
      :navChangeFlag="navChangeFlag"
    />
    <Head v-if="isMobile" />
    <router-view></router-view>
    <Foot v-if="isMobile" />
    <FootPc v-else></FootPc>
  </div>
</template>

<script>
import HeadPc from "./components/WebHead.vue"; //pc端
import Head from "./components/myTab.vue"; //手机端
import Foot from "./components/WebFoot.vue"; //手机端
import FootPc from "./components/WebFoot-pc.vue"; //pc端

export default {
  name: "App",
  components: {
    HeadPc,
    Head,
    Foot,
    FootPc,
    // banner
  },
  data() {
    return {
      navChangeFlag: false,
      flag: true,
      path: "",
      isMobile: false,
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        this.path = to.path;
        window.scrollTo(0, 0);
        if (to.path == "/YuService") {
          this.flag = true;
        } else {
          this.flag = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  // watch: {
  //   $route(to, from) {
  //     if (to.path == "/YuService") {
  //       this.flag = true;
  //     } else {
  //       this.flag = false;
  //     }
  //   },
  // },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
    // alert(this.path, "this.path");
    if (this._isMobile()) {
      if (this.path == "/PrivacyPolicy" || this.path == "/PrivacyPolicyM") {
        this.$router.push({ path: "/PrivacyPolicyM" });
      } else if (
        this.path == "/PrivacyPolicyQ" ||
        this.path == "/PrivacyPolicyMQ"
      ) {
        this.$router.push({ path: "/PrivacyPolicyMQ" });
      } else {
        this.$router.replace("/m");
      }
    } else {
      // alert("电脑端");
      if (this.path == "/PrivacyPolicy" || this.path == "/PrivacyPolicyM") {
        this.$router.push({ path: "/PrivacyPolicy" });
      } else if (
        this.path == "/PrivacyPolicyQ" ||
        this.path == "/PrivacyPolicyMQ"
      ) {
        this.$router.push({ path: "/PrivacyPolicyQ" });
      } else {
        this.$router.replace("/");
      }
    }
  },
  created() {
    this.flag = false;
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      return flag;
    },
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.flag) {
        this.navChangeFlag = false;
      } else if (scrollTop > 200 && !this.flag) {
        this.navChangeFlag = true;
      } else {
        this.navChangeFlag = false;
      }
      // 原
      // if (scrollTop > 220) {
      //   this.navChangeFlag = true;
      // } else {
      //   this.navChangeFlag = false;
      // }
    },
    // 滚动重置
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>

<style>
@import "./assets/fonts.css";
* {
  margin: 0;
  padding: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, PingFang SC,
    Hiragino Sans GB, noto sans, Microsoft YaHei, Helvetica Neue, Helvetica,
    Arial, sans-serif;
}

li {
  list-style: none;
}

img {
  vertical-align: top;
  border: none;
}
</style>
