<template>
  <div class="post-new">
    <div class="Page">
      <div
        id="myChartChina"
        ref="myEchart"
        style="position: relative; bottom: 43px"
      ></div>
      <div id="box">
        <div class="box_left">
          <div class="box_title">全部({{ city.length }})</div>
          <div v-for="(item, index) in city" :key="index" class="cityBox">
            <!-- <div v-if="((city)[index]).name!=((city)[index+1]).name&&index+1<city.length" class="city"> -->
            <!-- <div v-if="((city)[index]).name!=((city)[index-1]).name" class="city"> -->
            <div class="city">
              {{ city[index].name }}
            </div>
            <!-- <div class="city">
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</div> -->
            <div>
              <div
                v-for="(items, indexs) in item.children"
                :key="indexs + 'a'"
                class="positionBox"
              >
                <!--<div class="zhan">
                   {{((item.children)[indexs+'a']).site}} 
                  {{ items.site }}
                </div>-->
                <div class="positionLine"></div>
                <div class="xq">
                  {{ items.position }}
                  <!-- {{((item.children)[indexs+'a']).position}} -->
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="cityBox">
						<div class="city">
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</div>
						<div class="zhan">
							站点一
						</div>
						<div class="xq">
							重庆市江北区文化产业园2栋12楼232号
						</div>
					</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 引入基本模板
import echarts from "echarts";
import "../../node_modules/echarts/map/js/china.js"; // 引入中国地图数据
// import '@/assets/js/china.js' //引入中国地图数据
export default {
  name: "projectMap",
  components: {},
  data() {
    return {
      selectVal1: "",
      ifbox: true,
      TipsList: [],
      value3: "",
      city: [
        {
          name: "重庆",
          children: [
            {
              site: "站点一",
              position: "渝北区黄山大道中段53号双鱼座A座11层1110号",
            },
          ],
        },
        {
          name: "四川",
          children: [
            {
              site: "站点一",
              position: "成都市武侯区磨子桥街7号10层18号",
            },
          ],
        },
        {
          name: "湖南",
          children: [
            {
              site: "站点一",
              position: "长沙芙蓉区朝阳街道车站中路21号鸿运.凯旋国际",
            },
          ],
        },
        {
          name: "广东",
          children: [
            {
              site: "站点一",
              position: "福田区深南中路3003号北方大厦1018室",
            },
          ],
        },
        {
          name: "北京",
          children: [
            {
              site: "站点一",
              position: "北京市海淀区大钟寺13号院1号楼4层",
            },
          ],
        },
        {
          name: "上海",
          children: [
            {
              site: "站点一",
              position: "上海市松江区新浜镇新绿路398号",
            },
          ],
        },
      ],
      // city: [
      //   {
      //     name: "重庆市",
      //     children: [
      //       {
      //         site: "站点一",
      //         position: "重庆市江北区文化产业园2栋12楼232号",
      //       },
      //       {
      //         site: "站点一",
      //         position: "重庆市江北区文化产业园2栋12楼232号",
      //       },
      //     ],
      //   },
      //   {
      //     name: "上海市",
      //     children: [
      //       {
      //         site: "站点一",
      //         position: "重庆市江北区文化产业园2栋12楼232号",
      //       },
      //       {
      //         site: "站点一",
      //         position: "重庆市江北区文化产业园2栋12楼232号",
      //       },
      //     ],
      //   },
      // ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.ChinaMap();
    });
  },
  methods: {
    selectChange(e) {},
    ChinaMap() {
      var that = this;
      //模拟数据
      let data = [
        {
          name: "重庆",
          value: 90,
          num: 5,
          id: 8,
        },
        {
          name: "四川",
          value: 102,
          num: 15,
          id: 16,
        },
        {
          name: "广东",
          value: 140,
          num: 30,
          id: 20,
        },
        {
          name: "湖南",
          value: 150,
          num: 40,
          id: 30,
        },
        {
          name: "北京",
          value: 150,
          num: 40,
          id: 30,
        },
        {
          name: "上海",
          value: 150,
          num: 40,
          id: 30,
        },
      ];
      let geoCoordMap = {
        重庆: [106.5, 29.62],
        四川: [104.08, 30.639518],
        湖南: [113.016749, 28.198502],
        广东: [114.09134, 22.54602],
        北京: [116.348262, 39.969554],
        上海: [121.075046, 30.937771],
      };
      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
              //一定要有value这个字段,写法一定要geoCoord.concat(data[i].itemId),
              num: geoCoord.concat(data[i].num),
              id: geoCoord.concat(data[i].id),
            });
          }
        }
        return res;
      };
      let myChartChina = echarts.init(document.getElementById("myChartChina")); //这里是为了获得容器所在位置
      window.onresize = myChartChina.resize;
      myChartChina.setOption({
        //进行相关配置
        // backgroundColor: '#4971FF', //地图背景色
        geo: {
          //这个是重点配置区
          map: "china", //表示中国地图
          label: {
            normal: {
              show: true, //是否显示对应地名
              textStyle: {
                //字体颜色
                color: "#fff",
              },
            },
            emphasis: {
              show: false,
              textStyle: {
                color: "#ff007f",
              },
            },
          },
          roam: false, //是否可以缩放
          itemStyle: {
            normal: {
              borderWidth: 0.1, //地图边框宽度
              borderColor: "#698aff", //地图边框颜色
              areaColor: "#698aff", //地图颜色
            },
            emphasis: {
              //选中省份的颜色
              areaColor: "#4971FF",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 0,
              borderWidth: 1,
              shadowColor: "#fff",
            },
          },
        },
        //滑动显示数据
        //tooltip:{
        //trigger:'item',
        //formatter:function(params) {
        //return params.name + '已接入:'+params.data.num[2];
        //}
        //},
        series: [
          {
            name: "散点",
            type: "scatter",
            coordinateSystem: "geo",
            //color:function(params){
            //var colorlist = ['#F56C6C','#409EFF'];

            //return colorlist[0];
            //},//点的颜色
            data: convertData(data),
            symbolSize: 25, //点的大小
            symbol: "pin", //点的样式
            cursor: "pointer", //鼠标放上去的效果
            label: {
              normal: {
                //默认展示
                show: false,
              },
              emphasis: {
                //滑过展示
                show: false,
              },
            },
            itemStyle: {
              color: function (params) {
                var colorlist = ["#fff", "#409EFF"];

                return colorlist[0];
              }, //点的颜色
              emphasis: {
                // borderColor: '#5c8f6e',
                // borderWidth: 5
              },
            },
          },
          {
            type: "map",
            map: "china",
            geoIndex: 0,
            aspectScale: 0.75,
            tooltip: {
              show: true,
            },
          },
        ],
      });
      //if (myChartChina && typeof myChartChina === "object") {
      //myChartChina.setOption(myChartChina,true);
      //}
      // myChartChina.on('click', function(params) { //点击事件
      // 	if (params.componentType === 'series') {
      // 		if (params.data) {
      // 			that.TipsList = params.data
      // 			that.ifbox = true
      // 			that.boxPosition()
      // 		} else {
      // 			that.ifbox = false
      // 		}
      // 	}
      // });
    },
    //点击地点显示对应位置数据
    // boxPosition() {
    // 	var e = event || window.event;
    // 	var x = e.clientX - 233 + "px"
    // 	var y = e.clientY - 510 + "px"
    // 	clearTimeout(t)
    // 	var t = setTimeout(function() {
    // 		document.getElementById('box').style.top = y
    // 		document.getElementById('box').style.left = x
    // 	}, 50);
    // 	t
    // },
  },
};
</script>

<style scoped>
#myChartChina {
  /* width: 50%;
  height: 390px; */
  /* width: 840px;
  height: 460px; */
  /* bottom: -43px; */
  width: 890px;
  height: 510px;
}
.Page {
  /* width: 1140px; */
  padding: 0 360px 0 252px;
  display: flex;
  /* border: 1px solid black; */
}

#box {
  /* opacity: 0.6; */
  /* margin-top: 20px; */
  width: 50%;
  height: 418px;

  line-height: 29px;
  color: #fefefe;
  font-size: 14px;
  background: rgba(156, 172, 255, 0.1);
  border: 1px solid #9cacff;
}

.box_left {
  width: 96%;
  margin: 0 auto;
}

.box_title {
  width: 100%;
  height: 67px;
  border-bottom: 1px solid #9cacff;
  line-height: 67px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #fefefe;
}

.close-box {
  float: right;
  font-size: 15px;
}
.positionBox {
  width: 100%;
  display: flex;
}
.cityBox {
  display: flex;
  width: 100%;
  border-bottom: 1px dotted #9cacff;
}
.city {
  padding: 11px 40px;
}
.zhan {
  padding: 20px 30px;
}
.xq {
  padding: 11px 40px;
}

@media screen and (max-width: 479px) {
  .Page {
    width: 177%;
  }
  #myChartChina {
    width: 30%;
  }
  #box {
    width: 60%;
  }
}
</style>
