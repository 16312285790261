<template>
  <div class="Bigbox">
    <div class="box">
      <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="breadcrumb" :to="{ path: '/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb" :to="{ path: '/Support' }"
            >支持</el-breadcrumb-item
          >
          <el-breadcrumb-item class="breadcrumb" :to="{ path: '/Support' }"
            >咨询情报</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="contentOne">
        <div class="contentOneHead">
          <div class="fang"></div>
          <div class="contentOneName">
            {{ name }}
          </div>
        </div>
        <div class="contentOneBody">
          <div class="box1">
            <div class="fbsj">发布时间&nbsp;&nbsp;</div>
            <div class="time">
              {{ data }}
            </div>
          </div>
          <div class="box1">
            <div class="fbsj">更新时间&nbsp;:&nbsp;</div>
            <div class="time">
              {{ data }}
            </div>
          </div>
          <div class="box1">
            <div class="fbsj">危险等级&nbsp;:&nbsp;</div>
            <div class="time">中等</div>
          </div>
          <div class="box1">
            <div class="fbsj">CVE编号&nbsp;:&nbsp;</div>
            <div class="time">
              {{ province }}
            </div>
          </div>
        </div>
      </div>
      <div class="contentTwo">
        <div class="contentTwoTitle ldjj">漏洞简介</div>
        <div class="contentTwoContent">
          Hitachi Energy Fox61X和Hitachi Energy Xcm20都是瑞士日立能源（Hitachi
          Energy）公司的产品。Hitachi Energy
          Fox61X是一款出色的多业务多路复用器。用于扩展现有通信基础设施。Hitachi
          Energy
          Xcm20是一款任务关键型系统中通信网络的全混合多业务接入和传输平台。用于满足关键任务系统
          (Mcs) 的严格接入和传输网络要求。
        </div>
        <div class="contentTwoTitle ldgg">漏洞公告</div>
        <div class="contentTwoContent">
          目前厂商已发布升级补丁以修复漏洞，补丁获取链接：
          <div @click="go()" class="URL">
            https://search.abb.com/library/Download.aspx?DocumentID=8DBD000062&LanguageCode=en&DocumentPartId=&Action=Launch
          </div>
        </div>
        <div class="contentTwoTitle bb">漏洞简介</div>
        <div class="contentTwoContent">adobe photoshop CC2015以下</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      province: "",
      data: "",
    };
  },
  created() {
    // this.get();
  },
  methods: {
    // get() {

    //   this.name = this.$route.query.name;
    //   this.province = this.$route.query.province;
    //   this.data = this.$route.query.data;
    // },
    go() {
      window.location.href =
        "https://search.abb.com/library/Download.aspx?DocumentID=8DBD000062&LanguageCode=en&DocumentPartId=&Action=Launch";
    },
  },
};
</script>

<style scoped="scoped">
.Bigbox {
  background-color: #f5f6fa;
  padding-bottom: 243px;
}

.box {
  width: 63%;
  margin: 0px auto;
  padding-top: 80px;
  /* display: flex; */
  min-width: 820px;
  /* border: 1px solid red; */
}

.breadcrumb >>> .el-breadcrumb__inner {
  color: #3e68ff;
}

.contentOne {
  margin-top: 36px;
  height: 270px;
  background: #ffffff;
}

.contentOneHead {
  padding-top: 46px;
  display: flex;
  /* border: 0.0625rem solid; */
}

.fang {
  width: 20px;
  height: 28px;
  background: #3e68ff;
}

.contentOneName {
  margin-left: 20px;
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 28px;
}

.contentOneBody {
  margin-top: 30px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #8f94b2;
  line-height: 36px;
  /* border: 1px solid blue; */
}

.box1 {
  display: flex;
  margin-left: 40px;
}

.contentTwo {
  margin-top: 10px;
  height: 644px;
  background: #ffffff;
  padding-bottom: 128px;
}

.contentTwoTitle {
  padding-left: 39px;
  font-size: 24px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 28px;
}

.ldjj {
  padding-top: 48px;
}

.ldgg {
  margin-top: 81px;
}
.bb {
  margin-top: 78px;
}

.contentTwoContent {
  margin-top: 30px;
  margin-left: 40px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3d4566;
  line-height: 32px;
}
.URL {
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3e68ff;
  line-height: 32px;
  cursor: pointer;
}
@media screen and (max-width: 479px) {
  .Bigbox {
    width: 230%;
  }
}
</style>
