<template>
  <div class="box">
    <div class="banner-box">
      <div class="banner">
        <div class="bannerBox">
          <div class="bannerTitle">
            智慧<span class="keyTxt">一站式</span>站点管理解决方案
          </div>
          <div class="bannerFront">
            全栈云服致力于ICT服务标准化、可视化、专业化，实现客户ICT服务智能管理；链接客户、服务机构及专业云端专业服务团队，打通ICT服务的最后一公里
          </div>
        </div>
        <div class="banner-img">
          <!-- <img src="@/img/mobile/index_banner@3x.png" /> -->
          <span class="btn banner-btn" @click="toProgramme">
            <div class="font">
              <div class="dian"></div>
              <div class="txt">More</div>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="contentbox1">
        <div class="contentTitlebox">
          <div class="contentTitle">
            <p>全栈云服为客户提供</p>
            <p>全方位的服务和优质的产品</p>
          </div>
          <div class="contentTitleS contentTitleS1">OUR SERVICE</div>
        </div>
        <!-- 可提供的服务 -->
        <!-- 服务体系/团队介绍 -->
        <!-- class="pro-advantage" -->
        <!-- :class="Animate1 ? 'pro-advantage myanimation' : 'pro-advantage'"
          ref="contentbox"-->
        <div class="pro-advantage">
          <div class="monitor">
            <div class="monitor-content">
              <div class="contentTop">
                <div class="title">ICT服务生命周期管理服务</div>
                <img src="@/img/index_icon_part1_icon1.png" />
              </div>

              <div>
                <div class="monitor-time">
                  将ICT专业服务从签订合同到服务合同期满的全过程进行标准化拆解，参考合同服务目录，形成标准化的服务流程、计划，进行智能可视化管理；支持基于时间、事件触发、用户自定义制定服务计划等。
                </div>
              </div>
            </div>
            <div class="monitor-content">
              <div class="contentTop">
                <div class="title">智能服务团队管理服务</div>
                <img src="@/img/index_icon_part1_icon2.png" />
              </div>

              <div>
                <div class="monitor-time">
                  支持服务机构管理，按照不同服务机构进行组织数字化管理。支持服务工程师团队管理，并支持工程师一键发起工单、支持服务机构与工程师后服务评价体系；用户可自定义事件周期，自动输出服务机构周期内服务评估报告。
                </div>
              </div>
            </div>
            <!-- class="monitor-content" -->
            <div
              :class="
                Animate1 ? 'monitor-content myanimation' : 'monitor-content'
              "
              ref="animateBox"
            >
              <div class="contentTop">
                <div class="title">数字化工单服务</div>
                <img src="@/img/index_icon_part1_icon3.png" />
              </div>

              <div>
                <div class="monitor-time">
                  基于智能化、数字化的工单系统，支持全服务流程可视、可管、可回溯，实现服务商、云端专家快速响应。支持基于通话自动创建工单，支持一键创建工单；支持工单服务内容互动、服务内容确认，快速完成服务内容。
                </div>
              </div>
            </div>
            <!-- class="monitor-content"  -->
            <div
              :class="
                Animate1 ? 'monitor-content myanimation' : 'monitor-content'
              "
              ref="animateBox"
            >
              <div class="contentTop">
                <div class="title">远程协助服务</div>
                <img src="@/img/index_icon_part1_icon4.png" />
              </div>

              <div>
                <div class="monitor-time">
                  支持一键式发起远程协助，调度云端专家提供快速、专业的远程支持服务；提供服务过程留存，通过录音屏实现远程服务可追溯；本地站点服务团队可快速现场服务，云端专家团队24小时在线服务协助，400电话24*365实时在线。
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 原四列 -->
        <!-- <div class="contentBox">
          <div class="contentBoxS contentBoxSs">
            <div class="contentTop">
              <div class="contentImg">
                <img src="../img/index_icon_part1_icon1.png" />
              </div>
              <div class="contenttitle">运维服务</div>
            </div>

            <div class="contentDetails">
              提供线上、线下的运维服务。保障客户业务安全
            </div>
          </div>
          <div class="contentBoxS">
            <div class="contentImg">
              <img src="../img/index_icon_part1_icon2.png" />
            </div>
            <div class="contenttitle">集成交付</div>
            <div class="contentDetails">
              产品采购与交付服务解耦，降低成本及风险
            </div>
          </div>
          <div class="contentBoxS">
            <div class="contentImg">
              <img src="../img/index_icon_part1_icon3.png" />
            </div>
            <div class="contenttitle">咨询规划</div>
            <div class="contentDetails">
              凝聚大批行业资深专家及合作伙伴。帮助客户规划未来。
            </div>
          </div>
          <div class="contentBoxS">
            <div class="contentImg">
              <img src="../img/index_icon_part1_icon4.png" />
            </div>
            <div class="contenttitle">定制培训</div>
            <div class="contentDetails">
              帮助全栈ICT赋能，帮助客户提升综合能力。
            </div>
          </div>
        </div> -->
      </div>
      <!-- class="contentbox" :class="Animate1 ? 'contentbox myanimation' : 'contentbox'"
        ref="contentbox"-->
      <div
        :class="
          Animate2
            ? 'contentbox1 contentbox4 myanimation1'
            : 'contentbox1 contentbox4'
        "
        ref="tepsBox"
      >
        <!--<div class="teps">
           <div class="tepsBox" @click="showContent(1)">
            <div class="tepsImgs">
              <img src="../img/index_icon_part2_icon1.png" />
            </div>
            <div class="tepsName">拓展平台</div>
          </div>
          <div class="line"></div> -->
        <!-- <div class="tepsBox" @click="showContent(1)">
            <div class="choiceImg">
              <img src="@/img/index_icon_part2_icon2.png" />
            </div>
            <div class="tepsName">监控平台</div>
          </div>
          <div class="lineS"></div>
          <div class="tepsBox" @click="showContent(2)">
            <div class="tepsImgs">
              <img src="@/img/index_icon_part2_icon3.png" />
            </div>
            <div class="tepsName">展示平台</div>
          </div>
          <div class="lineS"></div>
          <div class="tepsBox" @click="showContent(3)">
            <div class="tepsImgs">
              <img src="@/img/index_icon_part2_icon4.png" />
            </div>
            <div class="tepsName">安管平台</div>
          </div>
          <div class="lineS"></div>
          <div class="tepsBox" @click="showContent(4)">
            <div class="tepsImgs">
              <img src="@/img/index_icon_part2_icon5.png" />
            </div>
            <div class="tepsName">资产平台</div>
          </div>
          <div class="lineS"></div>
          <div class="tepsBox" @click="showContent(5)">
            <div class="tepsImgs">
              <img src="@/img/index_icon_part2_icon6.png" />
            </div>
            <div class="tepsName">工单平台</div>
          </div> 
        </div> <div class="tepsFootBox"></div> -->
        <div
          class=""
          :class="Animate3 ? 'cloudBox myanimation1' : 'cloudBox'"
          ref="platContBox"
        >
          <div class="cloudTitle">
            <div
              @click="showContent(index)"
              class="title"
              v-for="(item, index) in navArr"
              :key="index"
              :class="{ choice: index == nowIndex }"
            >
              {{ item.content }}
            </div>
          </div>
          <div class="cloudLeft">
            <img src="@/img/index_part2_left_img1.png" />
          </div>
          <div v-if="teps == 1" class="cloudRight">
            监控平台主要为应用监控和设备监控，对企业的关键业务的应用和设备进行监测，实时获取应用和设备的健康信息、性能信息，提高企业应用和设备的可用性，保证关键核心业务的正常运转。
          </div>
          <div v-if="teps == 2" class="cloudRight">
            数据可视化展示平台，以表格、图表等多种方式对企业的数据进行统计和分析，并直观的提现，帮助企业挖掘数据中的价值，保证企业数据管理有效运转，协助企业经营者辅助决策。
          </div>
          <div v-if="teps == 3" class="cloudRight">
            对设备进行标准化以及规范化的管理，24小时实时监测设备状态，设备异常智能告警，根据设备和应用进行在线巡检、漏洞扫描、补丁修复等安全措施。
          </div>
          <div v-if="teps == 4" class="cloudRight">
            帮助医院实现各类资产的统一管理，并对医疗设备从科室入出库、维修保养到报废处置进行全生命周期的数字化管理，全面满足医院规范管理和质量控制的更多要求，建立资产和耗材备件台账，规范资产借用、归还、转科、维修、报废等处置流程。
          </div>
          <div v-if="teps == 5" class="cloudRight">
            基于智能化、数字化的工单平台，支持全程服务可视、可管、可回溯，实现服务商、云端专家快速响应。支持基于通话自动创建工单，支持一键创建工单；支持工单服务内容互动、服务内容确认，快速完成服务内容；做到服务全程留痕、信息充分共享、进度实时掌握。
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="contentbox1 contentbox2 partnerBox">
        <div
          class=""
          :class="
            Animate4 ? 'contentTitlebox  Boxmyanimation1' : 'contentTitlebox '
          "
          ref="partnerBox"
        >
          <div class="contentTitle">他们都在使用全栈云服</div>
          <div class="contentTitleS contentTitleS2">OUR PARTNER</div>
        </div>

        <div
          class=""
          :class="Animate5 ? 'pro-advantage myanimation6' : 'pro-advantage'"
          ref="partcontBox"
        >
          <div class="img-area">
            <div class="img-box" v-for="(item, index) in imgArr" :key="index">
              <img :src="item.image" />
            </div>
          </div>
        </div>
      </div>
      <!-- 最新新闻 -->
      <div class="contentbox1 contentbox3">
        <div
          class=""
          :class="Animate6 ? 'contentTitlebox myanimation6' : 'contentTitlebox'"
          ref="newsBox"
        >
          <div class="contentTitle">最新新闻</div>
          <div class="contentTitleS contentTitleS2">news</div>
        </div>
        <div
          class=""
          :class="Animate5 ? 'pro-advantage myanimation6' : 'pro-advantage'"
          ref="partcontBox"
        >
          <div class="newsRight">
            <div
              class="newsRightBox"
              v-for="(item, index) in documentList"
              :key="index"
            >
              <!-- <div class="leftBox" @click="go(item.id)">
                <div class="day">{{ item.day }}</div>
                <div class="times">{{ item.date }}</div>
              </div> -->
              <div class="rightBox" @click="go(item.id)">
                <div class="rightBoxhead">{{ item.title }}</div>
                <div class="day">{{ item.date }}</div>
                <div class="rightBoxfoot">
                  查看详情
                  <!-- <img src="@/img/all_icon_arrow_grey.png" /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="news-btn">
            <span class="btn" @click="toNews">更多新闻</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" @click="concatUs">
      <img src="@/img/index/icon_service@2x.png" />
      在线咨询
    </div>
  </div>
</template>
  <script>
import API from "@/api/api_surpport";
import image1 from "@/img/index/logo1.png";
import image2 from "@/img/index/logo2.png";
import image3 from "@/img/index/logo3.png";
import image4 from "@/img/index/logo4.png";
import image5 from "@/img/index/logo5.png";
import image6 from "@/img/index/logo6.png";
import image7 from "@/img/index/logo7.png";
import image8 from "@/img/index/logo8.png";
import image9 from "@/img/index/logo9.png";
import image10 from "@/img/index/logo10.png";
import image11 from "@/img/index/logo11.png";
import image12 from "@/img/index/logo12.png";
// import myTab from "@/pages/mobile/components/myTab.vue";
export default {
  name: "App",
  components: {
    // myTab
  },
  data() {
    return {
      value1: 0,
      value2: "a",
      option1: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      option2: [
        { text: "默认排序", value: "a" },
        { text: "好评排序", value: "b" },
        { text: "销量排序", value: "c" },
      ],
      navArr: [
        {
          path: "/home",
          content: "监控平台",
        },
        {
          path: "/YuService",
          content: "展示平台",
        },
        {
          path: "/Services",
          content: "安管平台",
        },
        {
          path: "/Programme",
          content: "资产平台",
        },
        {
          path: "/Support",
          content: "工单平台",
        },
      ],
      nowIndex: 0,
      teps: 1,
      imgArr: [
        {
          image: image1,
        },
        {
          image: image2,
        },
        {
          image: image3,
        },
        {
          image: image4,
        },
        {
          image: image5,
        },
        {
          image: image6,
        },
        {
          image: image7,
        },
        {
          image: image8,
        },
        {
          image: image9,
        },
        {
          image: image10,
        },
        {
          image: image11,
        },
        {
          image: image12,
        },
      ],
      documentList: [],
      docPage: {
        pageNum: 1,
        pageSize: 3,
        total: 0,
      },
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      Animate5: false,
      Animate6: false,
    };
  },
  created() {
    var that = this;
    that.getResourcesList();
  },
  mounted() {},
  methods: {
    concatUs() {
      window.open("https://v.egeel.vip/cs/c/46v4xpx6", "_blank");
    },
    getResourcesList() {
      var params = {
        con: {
          content: "",
          id: 0,
          title: "",
        },
        customerCode: "",
        customerSite: "",
        pageNum: this.docPage.pageNum,
        pageSize: 3,
      };
      API.newsList(params).then((res) => {
        if (res.code == "0000") {
          var templist = res.data.rows;
          templist.forEach((item) => {
            item.date = item.updatedDate.slice(0, 10);
            // item.updatedDate = item.updatedDate.replace(/-/g, "/").slice(0, 10);
            // item.date = item.updatedDate.slice(0, 7);
            // item.day = item.updatedDate.slice(8, 10);
            // pushDate
            item.pushDate = item.pushDate.replace(/-/g, "/").slice(0, 10);
            item.date = item.pushDate.slice(0, 7);
            item.day = item.pushDate.slice(8, 10);
          });
          this.documentList = templist;
          this.docPage.total = res.data.total;
        }
      });
    },
    // 详情
    go(row_id) {
      var that = this;
      // API.newsDetail({ id: row_id }).then((res) => {
      //   if (res.code == "0000") {
      //     this.newsDetail = res.data;
      //   }
      // });
      this.$router.push({
        path: "/NewsDetailsM",
        query: {
          id: row_id,
        },
      });
      // that.newsVisible = true;
    },
    // 跳转解决方案
    toProgramme() {
      this.$router.push({ path: "/ProgrammeBM" });
    },
    // 跳转新闻资讯
    toNews() {
      this.$router.push({ path: "/NewsM" });
    },
    // 平台右边内容切换
    showContent(index) {
      this.teps = index + 1;
      this.nowIndex = index;
    },
  },
};
</script>
  
  <style lang="scss" scoped="scoped">
// 在线客服
.agent {
  position: fixed;
  right: 2rem;
  bottom: 10%;
  z-index: 999;
  bottom: 78px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 46px;
  height: 120px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  line-height: 1.1;
  text-align: center;
  cursor: pointer;

  img {
    width: 31px;
    height: 31px;
  }
  span {
    width: 12px;
    line-height: 1.1;
  }
}
.box {
  width: 100%;
  // padding-bottom: 11.7rem;
  padding-top: 10rem;
  // border: 1px solid blue;
}
// banner区
.banner-box {
  background: #f7f9fd;
}
.banner {
  padding-top: 9.7rem;
  width: 75rem;
  // height: 99rem;
  display: flex;
  flex-direction: column;
  // padding-top: 70px;
  // background: url(../img/index/index_banner@2x.png) no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
}

.bannerBox {
  width: 80.5%;
  margin: 0 auto;
  text-align: center;
}
.bannerTitle {
  font-size: 4.4rem;
  font-family: Source Han Sans SC;
  font-weight: 800;
  color: #101635;
  padding-bottom: 3.8rem;
}
.keyTxt {
  color: #3e68ff;
}
.bannerFront {
  font-size: 2.8rem;
  font-family: PingFang HK;
  font-weight: 300;
  color: #101635;
  line-height: 1.7;
  text-align: justify;
  text-align-last: center;
  padding-bottom: 4rem;
}
.banner-img {
  position: relative;
  /* right: -2rem; */
  width: 100%;
  height: 56.6rem;
  background: url(@/img/mobile/index_banner@2x.png) no-repeat;
  // background-position: -79rem 0rem;
  // background-size: 221% 100%;
  background-position: -85rem 0;
  background-size: 235% 100%;
  padding-bottom: 3.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3.9rem;
}
.banner img {
  width: 100%;
  height: 56.6rem;
}
.banner-btn {
  z-index: 999;
  position: absolute;
  text-align: center;
}
.btn {
  box-sizing: border-box;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 36px;
  padding: 2.4rem 5.5rem 2.5rem 2rem;
}

.font {
  font-size: 2.8rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.dian {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  float: left;
}
.txt {
  margin-left: 2.9rem;
}
// banner区
// 内容区
.contentTitle,
.contentTitle p {
  font-size: 4.2rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
}

.contentTitleS {
  font-family: JI-Sludge !important;
  font-size: 1.8rem;
  font-weight: 400;
  color: #8f94b2;
}
// 服务
.contentTitleS1 {
  margin: 3.9rem 0 4.1rem;
}
// 合作伙伴
.contentTitleS2 {
  margin: 2rem 0 7.9rem;
}
// .contentBox {
//   margin-top: 91px;
//   display: flex;
//   flex-wrap: wrap;
// }

.contentBoxS {
  height: 170px;
  width: 210px;
  margin: 0 50px 0 50px;
}

.contentBoxSs {
  margin-left: 20%;
  display: flex;
}
// 服务
.contentbox1 {
  text-align: center;
  background: #f0f5ff;
  padding: 7.7rem 0 8.7rem;
  // border: 1px solid red;
}

.contenttitle {
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 44px;
}

.contentDetails {
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 30px;
  text-align: left;
}
/* 可提供的服务 */
.pro-advantage {
  width: 91.47%;
  margin: 0 auto;
}
.monitor {
  display: flex;
  flex-direction: column;
}
// TODO 展示
.monitor-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // TODO
  padding: 4.2rem 4rem 6.5rem 3.7rem;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 2px solid #ffffff;
  box-shadow: -4px -7px 20px 0px rgba(255, 255, 255, 0.5),
    4px 7px 20px 0px rgba(55, 99, 170, 0.1);
  border-radius: 1rem;
  margin-bottom: 4rem;
}
.monitor-content:last-child {
  margin-bottom: 0;
}
.contentTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 13px;
}
.contentTop .title {
  font-size: 3rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
}
.contentTop img {
  width: 7.9rem;
  height: 7.5rem;
}
.monitor-time {
  line-height: 1.8;
  font-size: 2.4rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  text-align: justify;
}
/* teps步骤区域 */
.teps {
  padding: 0 378px;
  height: 0;
  margin: 80px auto;
  margin-top: 48px;
  display: flex;
  justify-content: space-around;
  padding-bottom: 30px;
}

.tepsBox {
  height: 104px;
  width: 75px;
  text-align: center;
  cursor: pointer;
}

.tepsImgs {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}

.tepsImgs img {
  position: relative;
  top: 14px;
}

.tepsName {
  width: 73px;
  height: 17px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
}
.choiceImg {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: #fafbff;
  box-shadow: 0px 10px 20px 0px rgba(184, 188, 204, 0.3);
  border-radius: 50%;
}

.choiceImg img {
  position: relative;
  top: 14px;
}

.line {
  background-color: #3e68ff;
  height: 2px;
  width: 100px;
  margin: 35px 23px 0px 23px;
}

.lineS {
  background-color: #e6ebff;
  height: 2px;
  width: 100px;
  margin: 35px 23px 0px 23px;
}
.tepsFootBox {
  height: 24px;
  width: 54.5%;
  border: 1px solid #f5f7ff;
  border-top: 0;
  margin: 15px auto;
  border-radius: 1px;
  /* border-left: 1px solid #f5f7ff;
  border-bottom: 1px solid #f5f7ff;
  border-right: 1px solid #f5f7ff; */
  /* border: 1px solid red; */
}
// 平台
.contentbox4 {
  padding: 8rem 0 6.8rem;
  background: #ffffff;
}
.cloudBox {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cloudTitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .title {
    font-size: 2.8rem;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    padding: 2.7rem 4.9rem;
    background: #f5f7fa;
    border-radius: 0.6rem;
    margin-bottom: 2.4rem;
    cursor: pointer;
  }
  .choice {
    color: #3e68ff;
    background: #e0e7ff;
  }
  .title:last-child:nth-child(3n + 2) {
    margin-right: calc((100% - 30%) / 2);
  }
}
.cloudLeft {
  width: 41rem;
  height: 41rem;
}

.cloudLeft img {
  width: 100%;
  height: 100%;
}

.cloudRight {
  width: 87.5%;
  font-size: 2.6rem;
  line-height: 1.8;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  margin-top: 2rem;
  text-align: justify;
  text-align-last: center;
}

.cooperationBanner {
  display: flex;
  width: 100%;
  height: 500px;
  background-image: url(@/img/index_part3_logos_png.png);
}

.cooperationBox {
  width: 524px;
  height: 284px;
  background: #f9faff;
  /* border: 1px solid red; */
  text-align: center;
  margin: 108px auto;
  /* 不透明度 */
  opacity: 0.8;
}

.cooperationTitle {
  margin-top: 94px;
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  /* line-height: 44px; */
}

.cooperationTitleEnglish {
  margin-top: 13px;
  font-size: 14px;
  font-family: JI-Sludge !important;
  font-weight: 600;
  color: #8f94b3;
  /* line-height: 44px; */
}

.cooperationFoot {
  margin-top: 29px;
  font-size: 12px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  /* line-height: 44px; */
}
// 新闻
.contentbox3 {
  padding: 9.9rem 0 9.7rem;
  background: #ffffff;
}
.newsBox {
  border: 1px solid blue;
  display: flex;
  width: 63%;
  margin: 100px auto;
}

.newsLeft {
  /* border: 1px solid; */
  width: 380px;
  height: 390px;
  background-color: #f5f7fa;
}

.newsLeftFoot {
  text-align: center;
  /* color: #3D4566; */
}

.newsTitle {
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
}

.newsTitleEnglish {
  font-size: 18px;
  font-family: JI-Sludge !important;
  font-weight: 400;
  color: #8f94b2;
  line-height: 44px;
}

// .btn {
//   width: 144px;
//   height: 48px;
//   background: linear-gradient(0deg, #3e68ff, #5c7fff);
//   box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
//     0px 1px 3px 0px rgba(91, 127, 255, 0.4);
//   border-radius: 24px;
//   margin: 24px auto;
//   cursor: pointer;
// }
// TODO123 More按钮hover效果
// .btn:hover {
//   // transition: 0.8s linear;

//   // box-shadow: none;
//   // background: #fff !important;
//   background: linear-gradient(
//     90deg,
//     rgb(243, 246, 255) 0%,
//     rgb(255, 255, 255) 100%
//   ) !important;
//   // background-image: -ms-linear-gradient(
//   //   90deg,
//   //   rgb(243, 246, 255) 0%,
//   //   rgb(255, 255, 255) 100% !important
//   // );
//   box-shadow: 0px 3px 4px 0px rgba(224, 224, 224, 0.3) !important;

//   .font {
//     color: #3e68ff !important;
//   }
//   .dian {
//     background: #3e68ff !important;
//   }
// }
// .dian {
//   width: 6px;
//   height: 6px;
//   background: #ffffff;
//   border-radius: 50%;
//   float: left;
//   margin: 20px 0 0 20px;
// }

// .font {
//   font-size: 14px;
//   font-family: PingFang HK;
//   font-weight: 400;
//   color: #ffffff;
//   line-height: 48px;
//   margin-right: 17px;
// }

.newsRight {
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
}
// TODO 展示
.newsRightBox {
  box-sizing: border-box;
  width: 100%;
  padding: 4rem 3.3rem 3.8rem;
  background: rgba(96, 131, 255, 0.05);
  background: #ffffff;
  // box-shadow: 0px 1rem 7rem 0px rgba(184, 190, 204, 0.3);
  box-shadow: 0px 10px 70px 0px rgba(184, 190, 204, 0.3);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 3rem;
  background: linear-gradient(180deg, #f7f9ff, #fbfcff, #feffff);
  cursor: pointer;
}
// TODO123
.newsRightBox:hover {
  // box-shadow: 4px 7px 20px 0px rgba(55, 99, 170, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(184, 190, 204, 0.16);
  animation: load 0.4s linear;
  transform: translateY(0px);
  opacity: 1;
}
.news-btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 2.8rem;
    font-family: PingFang HK;
    font-weight: 400;
    color: #ffffff;
    padding: 2.6rem 4.5rem;
  }
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.leftBox {
  width: 80px;
  height: 96px;
  background: #f9faff;
  text-align: center;
  margin-top: 30px;
  margin-left: 31px;
}

// .day {
//   font-size: 24px;
//   font-family: Mont;
//   font-weight: normal;
//   color: #3d4566;
//   margin-top: 30px;
// }

.times {
  font-size: 14px;
  font-family: Mont;
  font-weight: normal;
  color: #8f94b2;
}

.rightBox {
  cursor: pointer;
}

.rightBoxhead {
  font-size: 3rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
}
.day,
.rightBoxfoot {
  font-size: 2.4rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
}
.day {
  margin: 2.3rem 0 4rem;
}

.rightBoxfoot img {
  margin-top: 10px;
  margin-left: 20px;
}
/* 合作伙伴 TODO背景图*/
// .partnerBox {
//   background-image: url("@/img/mobile/BG@2x.png");
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   background-position: center center;
// }
.contentbox2 {
  padding: 10rem 0 8.7rem;
  background: #f9faff;
  background-image: url("@/img/mobile/BG@2x.png");
  background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
  background-size: 100% 50%;
  background-position: top center;
}
.img-area {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .img-box {
    box-sizing: border-box;
    width: 33rem;
    height: 14rem;
    background: #ffffff;
    border: 2px solid #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(184, 190, 204, 0.06);
    border-radius: 0.4rem;
    margin-bottom: 2.8rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
  