<template>
  <div class="row row-offcanvas row-offcanvas-left clearfix">
    <main class="main-content col" id="content" role="main">
      <section class="section">
        <a id="main-content" tabindex="-1"></a>
        <div
          id="block-corem-bs5-page-title"
          class="block block-core block-page-title-block"
        >
          <div class="content">
            <h1 class="title">
              <span
                property="schema:name"
                class="field field--name-title field--type-string field--label-hidden"
                >隐私政策说明</span
              >
            </h1>
          </div>
        </div>
        <div
          id="block-corem-bs5-content"
          class="block block-system block-system-main-block"
        >
          <div class="content">
            <article
              data-history-node-id="661"
              role="article"
              about="/eweihits_LegalPrivacy"
              typeof="schema:WebPage"
              class="node node--type-page node--view-mode-full clearfix"
            >
              <header>
                <span
                  property="schema:name"
                  content="隐私政策说明"
                  class="rdf-meta hidden"
                ></span>
              </header>
              <div class="node__content clearfix">
                <div
                  property="schema:text"
                  class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
                >
                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        ><span style="font-family: 宋体"
                          >全栈云服(重庆)科技有限公司提供的app服务'全栈云服'（以下称本应用）非常重视用户隐私政策并严格遵守相关的法律规定。请您仔细阅读《隐私政策》后再继续使用。如果您继续使用我们的服务，表示您已经充分阅读和理解我们协议的全部内容。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        ><span style="font-family: 宋体">本应用</span
                        ><span style="font-family: 宋体"
                          >尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更优质的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。</span
                        >&nbsp;<span style="font-family: 宋体"
                          >您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <strong
                      ><span style=""
                        ><span style="font-family: Calibri, sans-serif"
                          >1.&nbsp;<span style="font-family: 宋体"
                            >适用范围</span
                          ></span
                        ></span
                      ></strong
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(a)&nbsp;<span style="font-family: 宋体">本应用</span
                        ><span style="font-family: 宋体"
                          >不对外提供注册账号信息，所有账号都由单位统一分配指定人员；</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(b)&nbsp;<span style="font-family: 宋体"
                          >在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的操作信息，包括但不限于您的</span
                        >IP<span style="font-family: 宋体"
                          >地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(c)<span style="font-family: 宋体"
                          >本应用严禁用户发布不良信息，如裸露、色情和亵渎内容，发布的内容我们会进行审核，一经发现不良信息，会禁用该用户的所有权限，予以封号处理。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <strong
                      ><span style=""
                        ><span style="font-family: Calibri, sans-serif"
                          >2.&nbsp;<span style="font-family: 宋体"
                            >信息使用</span
                          ></span
                        ></span
                      ></strong
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(a)<span style="font-family: 宋体"
                          >本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人登录信息。如果我们存储发生维修或升级，我们会事先发出推送消息来通知您，请您提前允许本应用消息通知。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(b)&nbsp;<span style="font-family: 宋体"
                          >本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >&nbsp;<span style="font-family: 宋体"
                          >我们会为您提供的各项具体功能场景包括：
                        </span></span
                      ></span
                    >
                  </p>
                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >&nbsp;<span style="font-family: 宋体"
                          >一、向您提供在线办公服务
                        </span></span
                      ></span
                    >
                  </p>
                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >&nbsp;<span style="font-family: 宋体"
                          >1、基础服务
                        </span></span
                      ></span
                    >
                  </p>
                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >&nbsp;<span style="font-family: 宋体"
                          >为向您提供基础的办公服务，保证应用稳定健康使用，应用的稳定性能分析，我们会在您使用应用时，收集设备信息（包括设备标识符（IMEI、IDFA、Android
                          ID、MAC、OAID、IMSI、ICCID个人信息及其他设备相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息），设备网络环境信息（IP地址，WiFi信息，基站信息及其他网络相关信息））、服务日志信息（浏览记录、点击查看记录、访问日期和时间）
                        </span></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >&nbsp;<span style="font-family: 宋体"
                          >2、服务团队通讯
                        </span></span
                      ></span
                    >
                  </p>
                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >&nbsp;<span style="font-family: 宋体"
                          >您可以在服务团队中查询您的同事信息，并直接拨打电话，则需要您授权我们直接拨打电话号码的权限。
                        </span></span
                      ></span
                    >
                  </p>
                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >&nbsp;<span style="font-family: 宋体"
                          >3、工单处理
                        </span></span
                      ></span
                    >
                  </p>
                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >&nbsp;<span style="font-family: 宋体"
                          >您可以在创建工单或者处理工单时，上传处理图片或者进行实时拍照，则需要您授权我们读取存储的权限：
                          读取您的 SD 卡中的内容、 修改或删除 SD 卡中的内容。
                        </span></span
                      ></span
                    >
                  </p>
                  <p>
                    <span style="font-size: 12pt"
                      ><span style="font-family: Calibri, sans-serif"
                        >&nbsp;<span style="font-family: 宋体"
                          >4、位置信息展示
                        </span></span
                      ></span
                    >
                  </p>
                  <p>
                    <span style="font-size: 12pt"
                      ><span style="font-family: Calibri, sans-serif"
                        >&nbsp;<span style="font-family: 宋体"
                          >您可以在发布需求和处理需求时，查看对方的位置信息：集成高德地图(定位、地图)SDK，用于实现(如定位及地图展示)的功能。该SDK主体为：高德软件有限公司；收集的个人信息包含：位置信息（经纬度、精确位置、粗略位置）。
                        </span></span
                      ></span
                    >
                  </p>
                  <p>
                    <strong
                      ><span style=""
                        ><span style="font-family: Calibri, sans-serif"
                          >3.&nbsp;<span style="font-family: 宋体"
                            >信息披露</span
                          ></span
                        ></span
                      ></strong
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        ><span style="font-family: 宋体"
                          >在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(a)&nbsp;<span style="font-family: 宋体"
                          >未经您事先同意，我们不会向第三方披露；</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(b)<span style="font-family: 宋体"
                          >为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(c)&nbsp;<span style="font-family: 宋体"
                          >根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(d)&nbsp;<span style="font-family: 宋体"
                          >如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(e)&nbsp;<span style="font-family: 宋体"
                          >如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <strong
                      ><span style=""
                        ><span style="font-family: Calibri, sans-serif"
                          >4.&nbsp;<span style="font-family: 宋体"
                            >信息存储和交换</span
                          ></span
                        ></span
                      ></strong
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        ><span style="font-family: 宋体"
                          >本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        ><b>5. Cookie</b
                        ><span style="font-family: 宋体">的使用</span></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(a)&nbsp;<span style="font-family: 宋体"
                          >在您未拒绝接受</span
                        >cookies<span style="font-family: 宋体"
                          >的情况下，本应用会在您的计算机上设定或取用</span
                        >cookies&nbsp;<span style="font-family: 宋体"
                          >，以便您能登录或使用依赖于</span
                        >cookies<span style="font-family: 宋体"
                          >的本应用平台服务或功能。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(b)&nbsp;<span style="font-family: 宋体"
                          >您有权选择接受或拒绝接受</span
                        >cookies<span style="font-family: 宋体"
                          >。您可以通过修改浏览器设置的方式拒绝接受</span
                        >cookies<span style="font-family: 宋体"
                          >。但如果您选择拒绝接受</span
                        >cookies<span style="font-family: 宋体"
                          >，则您可能无法登录或使用依赖于</span
                        >cookies<span style="font-family: 宋体"
                          >的本应用网络服务或功能。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(c)&nbsp;<span style="font-family: 宋体"
                          >通过本应用所设</span
                        >cookies<span style="font-family: 宋体"
                          >所取得的有关信息，将适用本政策。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <strong
                      ><span style=""
                        ><span style="font-family: Calibri, sans-serif"
                          >6.<span style="font-family: 宋体"
                            >本隐私政策的更改</span
                          ></span
                        ></span
                      ></strong
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(a)<span style="font-family: 宋体"
                          >如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        >(b)<span style="font-family: 宋体"
                          >本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style="font-size: 2.8rem"
                      ><span style="font-family: Calibri, sans-serif"
                        ><span style="font-family: 宋体"
                          >为防止向第三方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。</span
                        ></span
                      ></span
                    >
                  </p>

                  <p>
                    <span style=""
                      ><span style="font-family: Calibri, sans-serif"
                        ><span style="font-family: 宋体"
                          >感谢您花时间了解我们的隐私政策！我们将尽全力保护您的个人信息和合法权益，再次感谢您的信任！</span
                        ></span
                      ></span
                    >
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  created() {
    var that = this;
    // that.test();
  },
  methods: {},
};
</script>

<style scoped="scoped">
.row {
  /* margin: 20px auto; */
  /* max-width: 1320px; */
  margin: 0 auto;
  /* max-width: 1320px; */
  width: 91.25%;
  padding: 11rem 0;
}
.title {
  /* font-size: 42px; */
  font-size: 4.2rem;
  font-weight: 400;
  text-align: center;
  margin: 5rem 0 3rem;
}
p {
  font-size: 2.8rem;
  line-height: 1.8;
}
</style>