<template>
  <div class="box">
    <div class="banner">
      <img class="bannerImg" src="../../img/solution_bg_topbanner.png" />
      <div class="bannerBox">
        <img src="../../img/solution_top_title.png" />
        <div class="bannerFront">
          一行对这个模块功能的综合阐述小字 slogan什么什么的
        </div>
      </div>
    </div>
    <div class="body">
      <div class="left">
        <div class="title">制造业 综合网管 解决方案</div>
        <div class="titleEnglish">SoLUTION</div>
      </div>
      <div class="right">
        <div class="fajs">
          <div class="fajsBox">
            <div class="fajsTitle">方案介绍</div>
            <div class="fajsContent">
              数字医院应用是中国电信为充分满足各级卫生部门、合作医疗管理中心、中小医院、社区卫生服务中心/站的业务管理、信息共享的需求以及居民的健康管理需求，依托“商务领航”品牌、产品、服务、网络、渠道和客户资源优势，将IT软硬件产品与电信网络资源、增值服务和整合开发能力相结合，所提供的集医院信息管理、新型农村合作医疗管理、社区卫生服务管理、医疗卫生信息共享等应用于一体的应用服务整体解决方案，着力提升卫生行政部门和医疗机构的信息化水平，促进医疗卫生资源共享，构建数字化医院和数字化卫生。
            </div>
          </div>
        </div>
        <div class="fatd">
          <div class="fatdTitle">方案特点</div>
          <div class="fatdTitleEnglish">FEATURES</div>
          <div class="fatdFoot">
            <div v-for="(item, index) in arr" :key="index" class="fatdFootBox">
              <div class="footImg">
                <img src="../../img/index_icon_part2_icon2.png" />
              </div>
              <div class="footTitle">高可靠的医院网络</div>
              <div class="footContent">
                端到端打造高可靠的网络系统,保障医疗机构提供不间断医疗服务
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      arr: [1, 2, 3],
    };
  },
};
</script>

<style scoped="scoped">
.box {
  background-color: #fbfcff;
  padding-bottom: 116px;
}
.banner {
  width: 1903px;
  height: 280px;
  padding-top: 70px;
}

.bannerBox {
  height: 100%;
  padding: 0px 0 0 359px;
  margin-top: -200px;
}
.bannerImg {
  width: 100%;
  height: 100%;
}
.bannerFront {
  width: 410px;
  height: 18px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  line-height: 44px;
  opacity: 0.6;
  margin-top: 23px;
}
.body {
  width: 63%;
  margin: 0px auto;
  padding-top: 80px;
  display: flex;
  min-width: 820px;
  /* border: 1px solid red; */
}
.left {
  width: 200px;
  height: 420px;
  background: #3e68ff;
}
.title {
  width: 114px;
  height: 106px;
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #ffffff;
  line-height: 44px;
  margin-top: 60px;
  margin-left: 19px;
}
.titleEnglish {
  width: 137px;
  height: 11px;
  font-size: 18px;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #ffffff;
  line-height: 44px;
  margin: 183px 0 0 32px;
}
.right {
  margin-left: 100px;
}
.fajs {
  width: 900px;
  height: 320px;
  background: #ffffff;
  border: 1px solid #e6ebfa;
}
.fajsBox {
  /* border: 1px solid red; */
  margin: 48px 0 0 40px;
}
.fajsTitle {
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 32px;
}
.fajsContent {
  width: 820px;
  height: 144px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 28px;
}
.fatd {
  margin-top: 99px;
  padding-bottom: 50px;
  /* border: 1px solid red; */
}
.fatdTitle {
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
}
.fatdTitleEnglish {
  font-size: 18px;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #8f94b2;
  line-height: 44px;
}
.fatdFoot {
  /* border: 1px solid black; */
  display: flex;
  margin-top: 60px;
}
.fatdFootBox {
  width: 280px;
  height: 320px;
  background: #ffffff;
  border: 1px solid #e6ebfa;
  margin-right: 30px;
}
.footImg {
  width: 33px;
  height: 34px;
  margin: 49px 0 0 30px;
}
.footImg img {
  width: 100%;
  height: 100%;
}
.footTitle {
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
  margin-left: 31px;
  margin-top: 56px;
}
.footContent {
  width: 219px;
  height: 80px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 32px;
  margin-left: 31px;
}
@media screen and (max-width: 479px) {
  .box {
    width: 230%;
  }
  .banner {
    width: 100%;
    height: 160px;
  }
  .bannerBox {
    height: 100%;
    padding: 0px 0 0 20px;
    margin-top: -120px;
  }
  .bannerImg {
    width: 100%;
    height: 100%;
  }
  .fajs {
    width: 570px;
    height: 320px;
  }
  .fajsBox {
    width: 540px;
    margin: 28px 0 0 10px;
  }
  .fajsContent {
    width: 100%;
    height: 100%;
    font-size: 14px;
  }
  .fatdFootBox {
    margin-right: 3px;
    margin-top: 10px;
  }
  .fatdFoot {
    flex-wrap: wrap;
  }
}
</style>
