<template>
  <div class="box">
    <div class="banner">
      <!-- TODO替换banner -->
      <img src="../img/images/top_banner@2x.png" />
      <div class="bannerBox">
        <div class="bannerTitle">
          <span class="keyTxt">一站式智慧站点</span>
        </div>
        <div class="bannerFront">
          全栈云服智慧服务管理平台为服务加盟站点提供服务标准化全生命周期管理，基于工单的服务全流程管理，服务过程可视、可管、可追溯，有效提升客户服务满意度；智能客户管理，增加客户粘性，增加获客概率。平台提供智慧站点管理服务，可以有效对服务团队基于过程的全流程管理，提升管理效率。云服云端专业服务团队，提供远程协助，提供一系列自动化工具，降低一线工程师工作复杂度。通过全栈云服智慧服务管理平台，服务客户满意度提升20%，单工程师服务客户数量由1X提升至3X。
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 四项化 -->
      <div class="contentbox contentbox1 contentbox2">
        <div class="contentTitlebox">
          <div class="pro-advantage pro-qual">
            <div
              class="img-box"
              v-for="(item, index) in manageArr"
              :key="index"
            >
              <div class="img">
                <img src="../img/images/solution_icon1.png" />
              </div>
              <div class="title">{{ item.title }}</div>
              <p>{{ item.content }}</p>
            </div>
          </div>
          <div
            class=""
            :class="
              Animate1
                ? 'pro-advantage pro-qual pro-platform myanimation'
                : 'pro-advantage pro-qual pro-platform'
            "
            ref="oneBox"
          >
            <div class="service-platform">
              <div><img src="../img/solution/CHART1@2x.png" /></div>
              <div class="content-box">
                <div
                  v-for="(i, index) in serviceArr"
                  :key="index"
                  class="content"
                >
                  <div class="img">
                    <img src="../img/solution/icon@2x.png" />
                  </div>
                  <div>{{ i }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pro-advantage pro-qual pro-platform">
            <div
              class=""
              :class="
                Animate4 ? 'service-platform myanimation' : 'service-platform'
              "
              ref="fourBox"
            >
              <div><img src="../img/solutionb/CHART2@2x.png" /></div>
              <div class="content-box">
                <div
                  v-for="(i, index) in servicebArr"
                  :key="index"
                  class="content contentb"
                >
                  <div class="img">
                    <img src="../img/solutionb/icon_green@2x.png" />
                  </div>
                  <div>{{ i }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 闭环流程图 -->
      <div class="contentbox contentbox3">
        <div class="contentTitlebox">
          <div
            class=""
            :class="
              Animate2
                ? 'pro-advantage pro-intro myanimation'
                : 'pro-advantage pro-intro'
            "
            ref="twoBox"
          >
            <img src="../img/solution/bihuan@2x.png" />
          </div>
        </div>
      </div>

      <div class="contentbox contentbox4">
        <!-- 五个切换 -->
        <div class="pro-advantage">
          <div
            class=""
            :class="Animate3 ? 'manage-box myanimation' : 'manage-box'"
            ref="threeBox"
          >
            <div class="left">
              <div
                @click="changeClass(index)"
                v-for="(item, index) in arr"
                :class="{ choice: index == nowIndex }"
                :key="index"
                class="choiceBox"
              >
                <div class="name">{{ item.name }}</div>
              </div>
              <!-- @click="showAgent" -->

              <a
                href="http://itss.fullstackcs.com/register"
                target="_blank"
                style="text-decoration: none !important"
                ><div class="apply-btn"><span>申请入驻</span></div></a
              >
            </div>

            <div v-if="nowIndex == 0" class="right-box">
              <div
                class="right right1"
                v-for="(i, index) in contentArr[0]"
                :key="index"
              >
                <div class="cont">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name content">{{ item }}</div>
                    <!-- <p class="content">
                      {{ item.content }}
                    </p> -->
                  </div>
                </div>

                <div class="img img0">
                  <img src="../img/solutionb/S2_F1@2x.png" />
                </div>
                <div class="inner_box">
                  <img class="inner" src="../img/solution/tuoyuan1@2x.png" />
                </div>
              </div>
              <div class="outer_box">
                <img class="outer" src="../img/solution/tuoyuan2@2x.png" />
              </div>
            </div>
            <div v-if="nowIndex == 1" class="right-box">
              <div
                class="right right1 right2"
                v-for="(i, index) in contentArr[1]"
                :key="index"
              >
                <div class="cont cont1">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name content">{{ item }}</div>
                    <!-- <p class="content">
                      {{ item.content }}
                    </p> -->
                  </div>
                </div>

                <div class="img img1">
                  <img src="../img/solutionb/S2_F2@2x.png" />
                </div>
                <div class="inner_box">
                  <img class="inner" src="../img/solution/tuoyuan1@2x.png" />
                </div>
              </div>
              <div class="outer_box">
                <img class="outer" src="../img/solution/tuoyuan2@2x.png" />
              </div>
            </div>
            <div v-if="nowIndex == 2" class="right-box">
              <div
                class="right right1 right4"
                v-for="(i, index) in contentArr[2]"
                :key="index"
              >
                <div class="cont cont4">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name content">{{ item }}</div>
                    <!-- <p class="content">
                      {{ item.content }}
                    </p> -->
                  </div>
                </div>

                <div class="img img2">
                  <img src="../img/solutionb/S2_F3@2x.png" />
                </div>
                <div class="inner_box">
                  <img class="inner" src="../img/solution/tuoyuan1@2x.png" />
                </div>
              </div>
              <div class="outer_box">
                <img class="outer" src="../img/solution/tuoyuan2@2x.png" />
              </div>
            </div>
            <div v-if="nowIndex == 3" class="right-box">
              <div
                class="right right1 right3"
                v-for="(i, index) in contentArr[3]"
                :key="index"
              >
                <div class="cont cont3">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name content">{{ item }}</div>
                    <!-- <p class="content">
                      {{ item.content }}
                    </p> -->
                  </div>
                </div>

                <div class="img img3">
                  <img src="../img/solutionb/S2_F4@2x.png" />
                </div>
                <div class="inner_box">
                  <img class="inner" src="../img/solution/tuoyuan1@2x.png" />
                </div>
              </div>
              <div class="outer_box">
                <img class="outer" src="../img/solution/tuoyuan2@2x.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" onClick="__egeelTalk()">
      <img src="../img/index/icon_service@2x.png" />
      <span>在线咨询</span>
    </div>
    <ContactDialog :visible="contactVisible" @cancel="contactVisible = false" />
  </div>
</template>

<script>
import { isElementNotInViewport } from "../common/utils.js";
import ContactDialog from "./children/ContactDialog.vue";
export default {
  name: "App",
  components: {
    ContactDialog,
  },
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      contactVisible: false,
      manageArr: [
        {
          title: "服务标准化",
          content:
            "参照ITSS等标准，及服务专家经验，将ICT服务过程进行标准化拆解，形成标准服务目录等，实现服务过程标准化管理",
        },
        {
          title: "流程可视化",
          content:
            "服务流程管理，依与系统强大的工单系统，实现服务在客户、服务机构的链接。全过程可视、可管可控、可追溯，保障服务质量。",
        },
        {
          title: "服务专业化",
          content:
            "智慧服务管理，实现客户、本地第三方服务机构及云端专业服务团队的能力链接，实现专业服务快速可达，享受专业、便捷的ICT服务。",
        },
        {
          title: "管理智能化",
          content:
            "通过对服务过程、服务商服务周期综合能力智能评估，提供服务商服务报表；支持智慧项目、需求及排班管理，实现服务全流程智慧管理。",
        },
      ],
      serviceArr: [
        "服务标准化全生命周期管理",
        "基于工单的服务全流程管理，服务过程可视、可管、可追溯",
        "云端专业服务团队托底，远程协助，服务快速到达",
        "一键远程协助，可监管，可追溯",
        "应急事件透明管理，一键发起应急响应、应急演练",
        "资产全生命周期管理，支持设备、告警纳管",
        "智能巡检、漏洞扫描、补丁管理",
        "智能项目及需求管理",
        "智能排班管理",
        "智能服务团队服务综合评价",
        "小程序、APP支持，移动办公无忧",
      ],
      servicebArr: [
        "服务站点智能经营管理座舱",
        "智能值班管理",
        "服务工程师面向客户的服务全周期标准化管理",
        "基于工单的服务可视化流程",
        "智能客户管理",
        "云端远程技术专家支持",
        "专业服务工具",
        "全栈云服知识图库",
        "全栈云服一栈式培训服务",
        "全栈云服认证服务",
      ],
      arr: [
        {
          name: "服务机构智能经营管理座舱",
        },
        {
          name: "智能值班管理",
        },
        {
          name: "标准服务过程管理",
        },
        {
          name: "智能客户管理",
        },
      ],
      nowIndex: 0,
      contentArr: [
        [
          {
            name: "服务机构智能经营管理座舱",
            tip: [
              "支持服务机构经营管理，包括收益、团队人员、工单及服务评价等信息。",
              "支持智能数据统计及分析，支持报表输出。",
              "服务工程师团队人员满意度、出勤率及绩效等智能管理。",
            ],
          },
        ],
        [
          {
            name: "智能值班管理",
            tip: [
              "可视化值班管理，智能便捷排班，支持按天、批量排班。",
              "支持排班统计，排班数据分析。",
              "支持值班日志录入，记录值班事项。",
              "支持通知公告事件。",
            ],
          },
        ],
        [
          {
            name: "标准服务过程管理",
            tip: [
              "基于工单系统，支持服务过程事前可计划，事中可视，事后可审计、可追踪；",
              "支持对服务过程，进行全面评估服务能力",
            ],
          },
        ],
        [
          {
            name: "智能客户管理",
            tip: [
              "支持客户管理，包括客户信息、客户合同、结算信息、客户服务目录及计划等。",
              "支持客户服务评价管理，服务报表等。",
            ],
          },
        ],
      ],
    };
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.oneBox);
      this.Animate4 = !isElementNotInViewport(this.$refs.fourBox);
      this.Animate2 = !isElementNotInViewport(this.$refs.twoBox);
      this.Animate3 = !isElementNotInViewport(this.$refs.threeBox);
    },
    changeClass(index) {
      this.nowIndex = index;
    },
    // 在线客服
    // showAgent() {
    //   var that = this;
    //   // it服务注册界面
    //   window.open = "http://itss.fullstackcs.com/register";
    //   // that.contactVisible = true;
    // },
    // 最新新闻-查看详情
    viewDetail() {},
  },
};
</script>

<style lang="scss" scoped="scoped">
//产品优势 title
.myanimation {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 20px;
  bottom: 10%;
  z-index: 999;
  top: 465px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  height: 140px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  cursor: pointer;

  img {
    width: 41px;
    height: 41px;
  }
  span {
    width: 16px;
    line-height: 1.1;
  }
}
.box {
  // padding-bottom: 100px;
  width: 100%;
  /* border: 1px solid red; */
}

.banner {
  width: 1903px;
  height: 400px;
  padding-top: 70px;
  background: #eceff8;

  // padding-top: 70px;
  // background: url(../img/index/index_banner@2x.png) no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  /* height: 100%; */
  // width: 750px;
  // padding: 0px 0 0 360px;
  width: 63%;
  margin: 0 auto;
  /* border: 1px solid red; */
  margin-top: -310px;
}
.bannerTitle {
  font-size: 48px;
  font-family: Source Han Sans SC;
  font-weight: 800;
  color: #101635;
}
.keyTxt {
  color: #3e68ff;
}
.bannerFront {
  /* width: 410px;
  height: 18px; */
  width: 63%;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #101635;
  padding: 26px 15px 58px 0;
  line-height: 1.7;
}

.title1 {
  font-size: 32px;
  font-family: DOUYU;
  font-weight: normal;
  color: #ffffff;
  margin: 168px 0 0 360px;
}

.title2 {
  margin: 22px 0 0 359px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #ffffff;
}

.contentbox {
  width: 100%;
  height: 600px;
}

.btn {
  width: 144px;
  height: 48px;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 24px;
  margin: 59px 0 0 360px;
}

.dian {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  float: left;
  margin: 20px 0 0 20px;
}

.font {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  margin-right: 60px;
}

.More {
  margin-left: 51px;
}

.contentTitle {
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
  padding-top: 98px;
  /* margin-top: 98px; */
}

.contentTitleS {
  font-size: 18px;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #8f94b2;
  margin-top: 12px;
}

.contentBox {
  // margin-top: 91px;
  display: flex;
  flex-wrap: wrap;
}

.contentBoxS {
  height: 170px;
  width: 210px;
  margin: 0 50px 0 50px;
}

.contentBoxSs {
  margin-left: 20%;
  display: flex;
}
.contentbox1 {
  text-align: center;
  height: 700px;
  background: #eef6ff;
  /* border-bottom: 1px solid #e1e7ff; */
}
// 四项化
.contentbox2 {
  height: 1783px;
  background: #f9faff;
}
.contenttitle {
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 44px;
}

.contentDetails {
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 30px;
  text-align: left;
}
.pro-platform {
  // padding: 0 360px 0 !important;
  padding: 0 !important;
  // width: 63%;
  // margin: 0 auto;
}
.service-platform {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 572px;
  background: #ffffff;
  border: 1px solid #e6ebfa;
  padding: 48px 31px 51px 37px;
  margin-bottom: 56px;
  img {
    width: 748px;
    height: 473px;
  }
  .content-box {
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #3d4566;
    padding: 20px 0 30px 40px;
    .content {
      padding-bottom: 13px;
      display: flex;
      text-align: left;
      line-height: 1.3;
      align-items: center;
      // flex-direction: column;
      // justify-content: space-between;
      .img {
        display: inline-block;
        margin-right: 7px;
        margin-top: 5px;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    .contentb {
      margin-bottom: 9px;
    }
  }
}

// 闭环流程图
.contentbox3 {
  box-sizing: border-box;
  height: 752px;
  background: #ffffff;
  padding-top: 49px;
}
.pro-intro {
  padding-top: 35px;

  text-align: center;
  img {
    width: 1111px;
    height: 612px;
  }
}
.contentbox4 {
  height: 745px;
  background: #f7fbfe;
  padding-top: 104px;
}
/* 五个切换 */
.pro-advantage {
  // padding: 0 360px;
  width: 63%;
  margin: 0 auto;
}
.manage-box {
  display: flex;
  justify-content: space-between;
}

.choiceBox {
  width: 280px;
  height: 64px;
  line-height: 64px;
  background: #ebf2f9;
  border-radius: 6px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  margin-bottom: 20px;
  cursor: pointer;
}
.name {
  padding-left: 25px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
}

.choice {
  color: #ffffff;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
}
.apply-btn {
  width: 180px;
  height: 56px;
  line-height: 56px;
  background: #0cdec0;
  border: 1px solid #ffffff;
  box-shadow: 7px 7px 30px 0px rgba(12, 222, 192, 0.25);
  border-radius: 28px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #ffffff;
  margin-top: 56px;
  text-align: center;
  cursor: pointer;
}
.right {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 900px;
  height: 520px;
  background: #ebf2f9;
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin-left: 20px;
  padding: 60px 20px 0 40px;
  .title {
    font-size: 18px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
  }
  .inner_box {
    position: absolute;
    right: 0;
    top: 0;
    .inner {
      width: 260px;
      height: 220px;
    }
  }
}
.right-box {
  position: relative;
}
.outer_box {
  position: absolute;
  right: -44px;
  top: -54px;
  z-index: 0;
  .outer {
    width: 180px;
    height: 180px;
  }
}
.right1 {
  display: flex;
  .cont {
    width: 50%;
    margin-right: 40px;
  }
  .cont1 {
    width: 45%;
    // margin-right: 60px;
  }
  .cont3 {
    margin-right: -18px;
  }

  .img {
    width: 452px;
    height: 335px;
    position: relative;
    z-index: 999;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .img0 {
    top: -50px;
  }
  .img1 {
    width: 381px;
    height: 261px;
  }
  .img2 {
    width: 569px;
    height: 417px;
  }
  .img3 {
    width: 417px;
    height: 257px;
  }
  .tip {
    margin-top: 38px;
    .tip-name {
      font-size: 18px;
      font-family: PingFang HK;
      font-weight: 500;
      color: #3d4566;
    }
    .tip-name::before {
      display: inline-block;
      content: "";
      width: 8px;
      height: 8px;
      border: 2px solid #5f79ff;
      border-radius: 50%;
      margin-right: 5px;
    }
    .content {
      font-size: 14px;
      font-family: PingFang HK;
      font-weight: 400;
      color: #8f94b2;
      margin-top: 15px;
      line-height: 1.5;
    }
  }
}
.right4 {
  .cont4 {
    width: 43%;
  }
  .img {
    width: 569px;
    position: absolute;
    right: -117px;
  }
}
// .right2 {
//   .cont {
//     margin-right: 60px;
//   }
// }
// .right3 {
//   .cont {
//     margin-right: -120px;
//   }
// }
.right5 img {
  width: 800px;
  height: 275px;
  margin-top: 49px;
}
//  企业资质
.pro-qual {
  display: flex;
  justify-content: space-between;
  // padding: 59px 360px 89px;
  // padding-top: 59px;
  padding-top: 95px;
  padding-bottom: 88px;
  width: 63%;
  margin: 0 auto;
}
.img-box {
  box-sizing: border-box;
  padding: 0 30px;
  // width: 285px;
  width: 280px;
  height: 360px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    4px 7px 20px 0px rgba(55, 99, 170, 0.1);
  border-radius: 10px;
  text-align: center;
  img {
    width: 160px;
    height: 160px;
  }
  .title {
    font-size: 20px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    margin: 18px 0 20px 0;
  }
  p {
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    line-height: 1.7;
    text-align: left;
  }
}

.cloudBox {
  width: 55%;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  margin: 80px auto;
  justify-content: space-between;
}

.cloudLeft {
  width: 250px;
  height: 200px;
  /* margin-left: 10%; */
}

.cloudLeft img {
  width: 100%;
  height: 100%;
}

.cloudRight {
  /* border: 1px solid; */
  width: 543px;
  height: 176px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 32px;
  /* margin-left: 179px; */
}

.cooperationBanner {
  display: flex;
  width: 100%;
  height: 500px;
  background-image: url(../img/index_part3_logos_png.png);
}

.cooperationBox {
  width: 524px;
  height: 284px;
  background: #f9faff;
  /* border: 1px solid red; */
  text-align: center;
  margin: 108px auto;
  /* 不透明度 */
  opacity: 0.8;
}

.cooperationTitle {
  margin-top: 94px;
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  /* line-height: 44px; */
}

.cooperationTitleEnglish {
  margin-top: 13px;
  font-size: 14px;
  font-family: JI-Sludge;
  font-weight: 600;
  color: #8f94b3;
  /* line-height: 44px; */
}

.cooperationFoot {
  margin-top: 29px;
  font-size: 12px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  /* line-height: 44px; */
}

@media screen and (max-width: 479px) {
  .banner {
    width: 160%;
    height: 200px;
    position: relative;
  }
  .banner {
    width: 230%;
    height: 160px;
  }
  .bannerBox {
    height: 100%;
    padding: 0px 0 0 20px;
    margin-top: -120px;
  }
  .banner img {
    width: 100%;
    height: 100%;
    position: absolute;
    /* clip: rect( 0 500px 0 500px); */
  }
  .contentTitle {
    font-size: 15px;
    text-align: center;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    line-height: 44px;
    margin-top: 24px;
  }
  .contentBox {
    width: 100%;
    height: 390px;
    margin-top: 24px;
  }
  .contentBoxS {
    height: 85px;
    width: 100%;
    margin: 0 25px 0 25px;
  }
  .contentBoxSs {
    margin: 0 5% 0;
  }
  .contentbox1 {
    width: 100%;
    /* text-align: center; */
  }
  .contenttitle {
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 600;
    color: #3d4566;
    line-height: 44px;
  }
  .contentDetails {
    font-size: 12px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    line-height: 28px;
    text-align: center;
  }
  .teps {
    width: 100%;
    height: 0;
    margin: 59px auto;
    display: flex;
  }
  .tepsBox {
    height: 52px;
    width: 52px;
    /* border: 1px solid red; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tepsBox:first-child {
    margin-left: 10px;
  }
  .tepsImgs {
    height: 52px;
    width: 52px;
    border-radius: 50%;
  }

  .tepsImgs img {
    position: relative;
    top: 5px;
  }
  .choiceImg {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #fafbff;
    box-shadow: 0px 10px 20px 0px rgba(184, 188, 204, 0.3);
    border-radius: 50%;
  }

  .choiceImg img {
    position: relative;
    top: 5px;
  }

  .tepsName {
    width: 73px;
    height: 44px;
    font-size: 15px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    line-height: 44px;
  }
  .tepsFootBox {
    width: 160%;
    margin: 15px 0 0 30%;
  }

  /* 云 拓扑平台区域 */
  .cloudBox {
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    margin: 24px auto;
    /* 分配弹性元素方式 均匀排列每个元素，首：起点 尾：终点 */
    justify-content: space-between;
  }
  .cloudLeft {
    width: 150px;
    height: 100px;
    /* margin-left: 10%; */
  }

  .cloudRight {
    /* border: 1px solid; */
    width: 543px;
    height: 200px;
    font-size: 12px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    line-height: 32px;
    margin-bottom: 20px;
  }
  /* 底部合作banner区域 */
  .cooperationBanner {
    width: 100%;
    height: 50%;
    /* transform: scale(0.5); */
  }
  /* 最新新闻区域 */
  .newsBox {
    width: 100%;
  }
  .contentbox .contentboxHeight {
  }
  .newsRightBox {
    width: 110px;
    height: 180px;
  }
  .rightBoxfoot {
    margin-top: 5px;
  }
}
</style>
