<template>
  <div :class="navChangeFlag ? 'headChange' : 'head'">
    <!-- meta属性最大的作用：SEO，查找信息和分类 -->
    <!-- 自适应布局 适应手机端网页的浏览 
    宽度，缩放比例，最大缩放比例，不允许用户缩放-->
    <meta
      name="viewport"
      content="
      width=device-width,
      initial-scale=1,
      minimum-scale=1.0,
      maximum-scale=1.0,
      user-scalable=no,
      viewport-fit=cover"
    />
    <div class="img" @click="toHome()">
      <div class="imgBox">
        <img src="../img/LOGO@2x.png" />
      </div>
    </div>

    <div class="Bigbox">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color=""
        text-color="#3D4566"
        active-text-color="#3e68ff"
        router
      >
        <!-- <template>
        <el-menu-item
          v-for="(item, index) in navArr"
          :key="index"
          :index="item.path"
          >
          {{ item.content }}
          </el-menu-item
        >
        <el-submenu index="">
          <template slot="title">解决方案</template>
          <el-menu-item index="/Programme">智慧服务</el-menu-item>
          <el-menu-item index="/ProgrammeB">一站式智慧站点</el-menu-item>
        </el-submenu>
      </template> -->
        <el-menu-item index="/Home">首页</el-menu-item>
        <el-menu-item index="/YuService">走进云服</el-menu-item>
        <el-menu-item index="/Services">产品与服务</el-menu-item>
        <!-- :popper-append-to-body="false" -->
        <el-submenu popper-class="submenu" index="/Programme">
          <template slot="title">解决方案</template>
          <!-- <span slot="title"></span>解决方案 -->
          <!-- <div class="submenu-box"></div> -->
          <el-menu-item index="/Programme">智慧服务</el-menu-item>
          <el-menu-item index="/ProgrammeB">一站式智慧站点</el-menu-item>

          <!-- 原 -->
          <!-- <template slot="title">解决方案</template>
          <el-menu-item index="/Programme">智慧服务</el-menu-item>
          <el-menu-item index="/ProgrammeB">一站式智慧站点</el-menu-item> -->
        </el-submenu>

        <!-- <template slot="title">解决方案</template> -->

        <el-menu-item index="/Support">资源下载</el-menu-item>
        <el-menu-item index="/News">新闻资讯</el-menu-item>
        <el-menu-item index="/About">关于我们</el-menu-item>
      </el-menu>
      <a
        href="http://yun.fullstackcs.com/login"
        target="_blank"
        style="text-decoration: none !important"
      >
        <div class="login-btn">登录</div></a
      >
      <!-- 顶部状态 -->
      <!-- {{ navChangeFlag }} -->
    </div>
  </div>
</template>

<script>
export default {
  name: "WebHead",
  props: {
    navChangeFlag: {
      type: Boolean,
    },
  },
  data() {
    return {
      navFlag: this.navChangeFlag,
      navArr: [
        {
          path: "/Home",
          content: "首页",
          index: 0,
        },
        {
          path: "/YuService",
          content: "走进云服",
          index: 1,
        },
        {
          path: "/Services",
          content: "产品与服务",
          index: 2,
        },
        {
          path: "/Programme",
          content: "解决方案",
          index: 3,
          children: [
            {
              path: "/Programme",
              content: "智慧服务",
              index: 7,
            },
            {
              path: "/ProgrammeB",
              content: "一站式智慧站点",
              index: 8,
            },
          ],
        },
        {
          path: "/Support",
          content: "资源下载",
          index: 4,
        },
        {
          path: "/News",
          content: "新闻资讯",
          index: 5,
        },
        {
          path: "/About",
          content: "关于我们",
          index: 6,
        },
      ],
      nowIndex: 0,
      childIndex: 3,
      showMenu: false,
      defaultActive: "1",
      // activeIndex: 0,
      activeIndex: this.$route.path,
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to.path == "/YuService") {
          this.navChangeFlag = true;
        }
        this.activeIndex = to.path;
      },
      deep: true,
      immediate: true,
    },
    // $route(to, from) {
    //   if (to.path == "/YuService") {
    //     this.navChangeFlag = true;
    //   }
    //   this.activeIndex = to.path;
    // },
  },

  created() {},

  methods: {
    // setCurrentRoute(newV, oldV) {},
    // handleSelect(key, keyPath) {
    // },

    changeClass(index) {
      this.childIndex = 3;
      this.nowIndex = index;
      if (index == 3) {
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }
    },
    // 跳转首页
    toHome() {
      this.$router.push({ path: "/Home" });
    },
    // changeChild(index, path) {
    //   this.childIndex = index;

    //   this.showMenu = false;
    //   this.$router.push({ path: `${path}` });

    //   // this.$router.push({ path: "/Expert" });

    // },
  },
};
</script>
<style lang="scss" >
// .submenu .is-active {
//   background: #3e68ff !important;
//   color: #fff !important;
// }
.is-active {
  background: #3e68ff !important;
  color: #fff !important;
  .el-submenu__title,
  .el-icon-arrow-down {
    color: rgb(255, 255, 255) !important;
  }
}
.submenu .el-menu {
  box-sizing: border-box;
  margin-top: 0;
  min-width: 150px;
  max-width: 150px;
  height: 120px;
  background: #f4f8ff;

  box-shadow: 0px 10px 30px 0px rgba(184, 190, 204, 0.2);
  border-radius: 0;
  padding: 0 !important;
  .el-menu-item {
    height: 60px !important;
    line-height: 60px !important;
    background: #fff !important;
    // background: #f4f8ff !important;
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #3d4566 !important;
    text-align: center;
    // padding: 0 51px !important;
  }
  .el-menu-item:hover {
    background: #f4f8ff !important;
  }
  .is-active {
    // background: #3e68ff !important;
    // color: #fff !important;
  }
}
.el-submenu:hover {
  .el-submenu__title,
  .el-icon-arrow-down {
    color: rgb(62, 104, 255) !important;
  }
}
</style>
<style lang="scss" scoped="scoped">
.router-link-active {
  text-decoration: none;
  color: #fff;
}

.router-link {
  text-decoration: none;
  color: #fff;
}
/* 二级 */
.childMenu {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3d4566;
  border-bottom: 1px solid #f5f6f9;
  a {
    display: block;
    width: 100%;
    height: 100%;
    color: #3d4566;
  }
  .router-link-active {
    text-decoration: none;
    // color: #3d4566;
  }

  .router-link {
    text-decoration: none;
    // color: #fff;
  }
}
// /deep/.el-menu--horizontal::before {
//   content: "";
//   background: red;
//   height: 70px;
// }
.childMenu:hover {
  background: #fff;

  router-link {
    color: #3e68ff !important;
  }
}
// 置顶有个动态效果
.head {
  width: 100%;
  height: 70px;
  display: flex;
  background: transparent;
  background: hsla(0, 0%, 100%, 0.2);
  backdrop-filter: blur(20px);
  box-shadow: 0px 2px 6px 0px rgba(178, 188, 199, 0.1);
  z-index: 10000000;
  position: fixed;
}
.headChange {
  width: 100%;
  height: 70px;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(178, 188, 199, 0.1);
  z-index: 10000000;
  position: fixed;
}
.img {
  width: 36%;
  cursor: pointer;
  /* border: 1px solid red; */
  /* border: 1px solid black; */
}

.imgBox {
  width: 202px;
  height: 38px;
  margin: 21px 0px 21px 20%;
}

.imgBox img {
  width: 100%;
  height: 100%;
}

.Bigbox {
  display: flex;
  .login-btn {
    position: absolute;
    top: 15px;
    right: 40px;
    text-align: center;
    width: 88px;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(-79deg, #00d6b3, #11db75);
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
  }
  .login-btn:hover {
    opacity: 0.8;
  }
}

.choice {
  background: #3e68ff;
  color: #fff !important;
}

.box {
  width: 150px;
  height: 80px;
  /* border: 1px solid red; */
  flex-direction: row-reverse;
  text-align: center;
  line-height: 80px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3d4566;
  cursor: pointer;
  display: inline !important;
}
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
// 菜单
/deep/ .el-menu {
  background: transparent;
  .el-menu-item,
  .el-submenu__title {
    width: 150px;
    height: 70px !important;
    line-height: 70px !important;
    font-size: 16px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #3d4566;
    text-align: center;
  }
  .el-menu--horizontal {
    border-bottom: none !important;
  }
  .el-menu-item.is-active {
    color: #fff !important;
    background: #3e68ff !important;
  }
}
// 取消下划线
.el-menu /deep/ .el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
  color: #fff;
  border-bottom-color: none;
}
/*菜单下拉框样式*/
.el-menu /deep/.el-menu--horizontal .el-menu--popup-bottom-start {
  margin-top: 0 !important;
}
// .el-menu-item {
//   position: relative;
// }
// .el-menu-item:after {
//   content: "";
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   color: #3e68ff !important;
//   // background-color: #fff !important;
//   opacity: 0;
//   transition: 0.5s;
// }
// .el-menu-item:hover:after {
//   opacity: 1;
// }
.el-menu-item:hover,
.el-submenu:hover,
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: #3e68ff !important;
  background-color: #fff !important;
  transition: 0.8s;
}

/deep/.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}
//二次菜单悬浮样式
// /deep/.el-menu--popup-bottom-start .el-menu-item:hover {
//   background: rgba(255, 137, 36, 1) !important;
//   color: #fff !important;
// }
@media screen and (max-width: 1660px) {
  .Bigbox {
    width: 100%;
    display: block;
  }
  /deep/.el-menu .el-menu-item {
    width: 133px;
  }
  /deep/.submenu .el-menu {
    min-width: 133px;
    max-width: 133px;
  }
}
@media screen and (max-width: 479px) {
  .head {
    width: 100%;
    height: 50px;
    display: flex;
  }

  .img {
    width: 20%;
    /* border: 1px solid #fff; */
  }

  .imgBox {
    width: 100%;
    height: 30px;
    margin: 8px 5% 5px 5%;
  }
  .imgBox img {
    width: 100%;
    height: 100%;
  }
  .box {
    width: 50px;
    height: 50px;
    /* border: 1px solid red; */
    color: white;
    flex-direction: row-reverse;
    text-align: center;
    line-height: 50px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>
