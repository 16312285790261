<template>
  <el-dialog
    :visible.sync="visible"
    width="700px"
    max-height="500px"
    :before-close="cancel"
    :show-close="false"
  >
    <div class="down-body">
      <div class="down-box">
        <div class="title">
          <div>立即咨询</div>
          <span
            >微信扫码，立即联系销售经理进行咨询，或电话、短信致电均可。</span
          >
        </div>
        <div class="info">
          <div>
            <div class="img">
              <img src="~@/img/index/logo4.png" alt="" />
            </div>
            <div class="phone">
              <div class="phone-img">
                <img src="~@/img/index/phone@2x.png" alt="" />
              </div>
              <div>周经理 1388888888</div>
            </div>
          </div>
          <div>
            <div class="img">
              <img src="~@/img/index/logo3.png" alt="" />
            </div>
            <div class="phone">
              <div class="phone-img">
                <img src="~@/img/index/phone@2x.png" alt="" />
              </div>
              <div>周经理 1388888888</div>
            </div>
          </div>
        </div>
      </div>
      <div class="close_box">
        <!-- 右上角定位  -->
        <img
          class="close_img"
          src="~@/img/images/icon_close@2x.png"
          alt=""
          @click="cancel"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      contactVisible: false,
      name: "",
      province: "",
      data: "",
    };
  },
  props: {
    visible: {
      type: Boolean,
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.box {
  width: 63%;
  margin: 0px auto;
  /* padding-top: 80px; */
  /* display: flex; */
  min-width: 820px;
  padding: 0 140px;
  /* border: 1px solid red; */
}
.down-body {
  min-height: 260px;
}
/deep/ .el-dialog {
  border-radius: 30px;
  .el-dialog__body {
    padding: 0 64px 48px;
  }
  .dialog-footer {
    text-align: center;
  }
}
.down-box {
  margin-bottom: 88px;

  .title {
    margin-bottom: 35px;
    text-align: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    div {
      font-size: 28px;
      font-family: PingFang HK;
      font-weight: 600;
      color: #3d4566;
      padding: 25px 0 18px;
    }
    span {
      font-size: 16px;
      font-family: PingFang HK;
      font-weight: 400;
      color: #3d4566;
    }
  }
  p {
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #3d4566;
    line-height: 1.7;
  }
}
.close_img {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
.info {
  display: flex;
  justify-content: space-between;
  padding: 0 66px;

  .img {
    width: 200px;
    height: 200px;
    background: #d8dae0;
    border-radius: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .phone {
    box-sizing: border-box;
    display: flex;
    width: 200px;
    height: 48px;
    background: #f8fafb;
    border-radius: 24px;
    margin-top: 20px;
    padding-left: 19px;
    align-items: center;
    .phone-img {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.box {
  background-color: #fbfcff;
  padding-bottom: 81px;
}

@media screen and (max-width: 479px) {
  /* .Bigbox {
    width: 230%;
  } */
  .box {
    width: 230%;
  }
}
</style>
