<template>
  <el-dialog
    :visible.sync="visible"
    width="1100px"
    :show-close="false"
    :before-close="cancel"
    class="news-dialog"
  >
    <div class="box">
      <div class="contentOne">
        <div class="contentOneHead">
          <!-- <div class="fang"></div> -->
          <div class="contentOneName">
            {{ newsData.title }}
          </div>
        </div>
        <div class="contentOneBody">
          <!-- <div class="box1">
            <div class="fbsj">新闻来源：&nbsp;:&nbsp;</div>
            <div class="time">{{ newsData.updatedBy }}</div>
          </div> -->
          <div class="box1">
            <div class="fbsj">发布时间：&nbsp;&nbsp;</div>
            <div class="time">{{ newsData.pushDate }}</div>
          </div>
        </div>
      </div>
      <div class="contentTwo">
        <!-- <div class="contentTwoTitle ldjj">漏洞简介</div> -->
        <div class="contentTwoContent" v-imgtoken="newsData.content">
          <!-- <quill-editor
            style="height: 300px"
            v-model="newsData.content"
            :readOnly="true"
          >
          </quill-editor> -->
        </div>
        <!-- <div class="contentTwoTitle ldgg">漏洞公告</div>
        <div class="contentTwoContent">
          目前厂商已发布升级补丁以修复漏洞，补丁获取链接：
          <div @click="go()" class="URL">
            https://search.abb.com/library/Download.aspx?DocumentID=8DBD000062&LanguageCode=en&DocumentPartId=&Action=Launch
          </div>
        </div>
        <div class="contentTwoTitle bb">漏洞简介</div>
        <div class="contentTwoContent">adobe photoshop CC2015以下</div> -->
      </div>
      <div class="deco"></div>
      <div class="close_box">
        <!-- 右上角定位  -->
        <img
          class="close_img"
          src="~@/img/images/icon_close@2x.png"
          alt=""
          @click="cancel"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script>
// 引入富文本编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor, Quill } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      newsVisible: false,
      name: "",
      province: "",
      data: "",
    };
  },
  props: {
    visible: {
      type: Boolean,
    },
    newsData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    // this.get();
  },
  methods: {
    // get() {

    //   this.name = this.$route.query.name;
    //   this.province = this.$route.query.province;
    //   this.data = this.$route.query.data;
    // },
    cancel() {
      this.$emit("cancel");
    },
    // go() {
    //   window.location.href =
    //     "https://search.abb.com/library/Download.aspx?DocumentID=8DBD000062&LanguageCode=en&DocumentPartId=&Action=Launch";
    // },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
/* .Bigbox {
  background-color: #f5f6fa;
  padding-bottom: 243px;
} */
/deep/ .news-dialog {
  min-height: 400px;
}
/deep/.el-dialog__header {
  padding: 35px 20px 10px;
}

/deep/.el-dialog__body {
  padding: 0px 20px;
}
.deco {
  position: absolute;
  left: 0;
  top: 78px;
  width: 20px;
  height: 28px;
  background: #3e68ff;
}
.close_img {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
.box {
  width: 63%;
  margin: 0px auto;
  /* padding-top: 80px; */
  /* display: flex; */
  min-width: 820px;
  padding: 0 140px;
  /* border: 1px solid red; */
}

.breadcrumb >>> .el-breadcrumb__inner {
  color: #3e68ff;
}

.contentOne {
  margin-top: 34px;
  // height: 108px;
  background: #ffffff;
  border-bottom: 1px solid #ebebeb;
}

.contentOneHead {
  /* padding-top: 46px; */
  display: flex;
  /* border: 0.0625rem solid; */
}

.fang {
  width: 20px;
  height: 28px;
  background: #3e68ff;
}

.contentOneName {
  margin-left: 20px;
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 28px;
}

.contentOneBody {
  margin-top: 30px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #8f94b2;
  line-height: 36px;
  /* border: 1px solid blue; */
}

.box1 {
  display: flex;
  margin-left: 40px;
  margin-bottom: 40px;
}

.contentTwo {
  margin-top: 10px;
  // max-height: 400px;
  background: #ffffff;
  padding-bottom: 40px;
}

.contentTwoTitle {
  padding-left: 39px;
  font-size: 24px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 28px;
}

.ldjj {
  padding-top: 48px;
}

.ldgg {
  margin-top: 81px;
}
.bb {
  margin-top: 78px;
}

.contentTwoContent {
  margin-top: 45px;
  // margin-left: 40px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3d4566;
  line-height: 32px;

  /deep/ p {
    // text-indent: 2em;//首行缩进
    margin-bottom: 14px;
    img {
      width: 75% !important;
      height: 75% !important;
    }
  }
}
.URL {
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3e68ff;
  line-height: 32px;
  cursor: pointer;
}
@media screen and (max-width: 479px) {
  /* .Bigbox {
    width: 230%;
  } */
  .box {
    width: 230%;
  }
}
</style>
