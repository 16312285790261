<template>
  <div class="box">
    <div class="banner">
      <div class="bannerBox">
        <div class="bannerTitle">
          <span class="keyTxt">一站式智慧站点</span>
        </div>
        <div class="bannerFront">
          全栈云服智慧服务管理平台为服务加盟站点提供服务标准化全生命周期管理，基于工单的服务全流程管理，服务过程可视、可管、可追溯，有效提升客户服务满意度；智能客户管理，增加客户粘性，增加获客概率。平台提供智慧站点管理服务，可以有效对服务团队基于过程的全流程管理，提升管理效率。云服云端专业服务团队，提供远程协助，提供一系列自动化工具，降低一线工程师工作复杂度。通过全栈云服智慧服务管理平台，服务客户满意度提升20%，单工程师服务客户数量由1X提升至3X。
        </div>
      </div>
      <!-- <img src="@/img/images/solution_banner.png" /> -->
    </div>
    <div class="content">
      <!-- 四项化 -->
      <div class="contentbox">
        <div class="pro-advantage pro-stand">
          <div class="img-box" v-for="(item, index) in manageArr" :key="index">
            <div class="top">
              <div class="title">{{ item.title }}</div>
              <div class="img">
                <img src="@/img/images/solution_icon1.png" />
              </div>
            </div>

            <p>{{ item.content }}</p>
          </div>
        </div>
        <div
          class=""
          :class="Animate1 ? ' pro-platform myanimation' : '  pro-platform'"
          ref="oneBox"
        >
          <div class="service-platform">
            <img src="@/img/solution/CHART1@2x.png" />
            <div class="content-box">
              <div
                v-for="(i, index) in serviceArr"
                :key="index"
                class="content"
              >
                <img src="@/img/solution/icon@2x.png" />
                <div>{{ i }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class=""
          :class="
            Animate1
              ? ' pro-platform pro-platform2 myanimation'
              : '  pro-platform pro-platform2'
          "
          ref="oneBox"
        >
          <div class="service-platform">
            <img src="@/img/solutionb/CHART2@2x.png" />
            <div class="content-box">
              <div
                v-for="(i, index) in servicebArr"
                :key="index"
                class="content"
              >
                <img src="@/img/mobile/1VG@3x.png" />
                <div>{{ i }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 闭环流程图 -->
      <div class="contentbox contentbox3">
        <div class="contentTitlebox">
          <div
            class=""
            :class="Animate2 ? ' pro-intro myanimation' : ' pro-intro'"
            ref="twoBox"
          >
            <p>基于工单的流程</p>
            <img src="@/img/solution/bihuan@2x.png" />
          </div>
        </div>
      </div>

      <div class="contentbox contentbox4">
        <!-- 五个切换 -->
        <div class="pro-advantage">
          <div
            class=""
            :class="Animate3 ? 'manage-box myanimation' : 'manage-box'"
            ref="threeBox"
          >
            <div class="left">
              <div
                @click="changeClass(index)"
                v-for="(item, index) in arr"
                :class="{ choice: index == nowIndex }"
                :key="index"
                class="choiceBox"
              >
                <div class="name">{{ item.name }}</div>
              </div>
              <!-- @click="showAgent" -->
            </div>
            <div v-if="nowIndex == 0" class="right-box">
              <div
                class="right right1"
                v-for="(i, index) in contentArr[0]"
                :key="index"
              >
                <div class="img">
                  <img src="@/img/solutionb/S2_F1@2x.png" />
                </div>

                <div class="cont">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name content">{{ item }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="nowIndex == 1" class="right-box">
              <div
                class="right right1"
                v-for="(i, index) in contentArr[1]"
                :key="index"
              >
                <div class="img">
                  <img src="@/img/solutionb/S2_F2@2x.png" />
                </div>
                <div class="cont">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name content">{{ item }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="nowIndex == 2" class="right-box">
              <div
                class="right right1"
                v-for="(i, index) in contentArr[2]"
                :key="index"
              >
                <div class="img">
                  <img src="@/img/solutionb/S2_F3@2x.png" />
                </div>
                <div class="cont cont4">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name content">{{ item }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="nowIndex == 3" class="right-box">
              <div
                class="right right1"
                v-for="(i, index) in contentArr[3]"
                :key="index"
              >
                <div class="img">
                  <img src="@/img/solutionb/S2_F4@2x.png" />
                </div>
                <div class="cont">
                  <div class="title">
                    {{ i.name }}
                  </div>
                  <div class="tip" v-for="(item, index) in i.tip" :key="index">
                    <div class="tip-name content">{{ item }}</div>
                    <!-- <p class="content">
                      {{ item.content }}
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
            <a
              href="http://itss.fullstackcs.com/register"
              target="_blank"
              style="text-decoration: none !important"
              ><div class="apply-btn"><span>申请入驻</span></div></a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" @click="concatUs">
      <img src="@/img/index/icon_service@2x.png" />
      在线咨询
    </div>
    <!-- <ContactDialog :visible="contactVisible" @cancel="contactVisible = false" /> -->
  </div>
</template>

<script>
import { isElementNotInViewport } from "./common/utils.js";
import ContactDialog from "./children/ContactDialog.vue";
export default {
  name: "App",
  components: {
    ContactDialog,
  },
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      contactVisible: false,
      manageArr: [
        {
          title: "服务标准化",
          content:
            "参照ITSS等标准，及服务专家经验，将ICT服务过程进行标准化拆解，形成标准服务目录等，实现服务过程标准化管理",
        },
        {
          title: "流程可视化",
          content:
            "服务流程管理，依与系统强大的工单系统，实现服务在客户、服务机构的链接。全过程可视、可管可控、可追溯，保障服务质量。",
        },
        {
          title: "服务专业化",
          content:
            "智慧服务管理，实现客户、本地第三方服务机构及云端专业服务团队的能力链接，实现专业服务快速可达，享受专业、便捷的ICT服务。",
        },
        {
          title: "管理智能化",
          content:
            "通过对服务过程、服务商服务周期综合能力智能评估，提供服务商服务报表；支持智慧项目、需求及排班管理，实现服务全流程智慧管理。",
        },
      ],
      serviceArr: [
        "服务标准化全生命周期管理",
        "基于工单的服务全流程管理，服务过程可视、可管、可追溯",
        "云端专业服务团队托底，远程协助，服务快速到达",
        "一键远程协助，可监管，可追溯",
        "应急事件透明管理，一键发起应急响应、应急演练",
        "资产全生命周期管理，支持设备、告警纳管",
        "智能巡检、漏洞扫描、补丁管理",
        "智能项目及需求管理",
        "智能排班管理",
        "智能服务团队服务综合评价",
        "小程序、APP支持，移动办公无忧",
      ],
      servicebArr: [
        "服务站点智能经营管理座舱",
        "智能值班管理",
        "服务工程师面向客户的服务全周期标准化管理",
        "基于工单的服务可视化流程",
        "智能客户管理",
        "云端远程技术专家支持",
        "专业服务工具",
        "全栈云服知识图库",
        "全栈云服一栈式培训服务",
        "全栈云服认证服务",
      ],
      arr: [
        {
          name: "服务机构经营管理座舱",
        },
        {
          name: "智能值班管理",
        },
        {
          name: "标准服务过程管理",
        },
        {
          name: "智能客户管理",
        },
      ],
      nowIndex: 0,
      rightArr: [
        {
          name: "全栈云服基于ISS等标准,总结团队10+年专业CT服务经验,分级ICT服务过程,梳理标准化服务流程",
          tip: [
            {
              title: "服务目录流程",
              content:
                "将ICT服务过程标准化拆解为服务流程节点;服务流程中的服务动作、内容及标准形成服务目录,实现服务的量化。",
            },
            {
              title: "服务合同",
              content:
                "提供标准服务合同模板,将ICT服务过程目录在合同中体现,实现服务内容、责任的明确边界界定。",
            },
            {
              title: "服务任务计划",
              content:
                "将服务流程节点,拆解为任务细项,预定义责任人、完成时间智能化管理。",
            },
          ],
        },
      ],
      contentArr: [
        [
          {
            name: "服务机构经营管理座舱",
            tip: [
              "支持服务机构经营管理，包括收益、团队人员、工单及服务评价等信息。",
              "支持智能数据统计及分析，支持报表输出。",
              "服务工程师团队人员满意度、出勤率及绩效等智能管理。",
            ],
          },
        ],
        [
          {
            name: "智能值班管理",
            tip: [
              "可视化值班管理，智能便捷排班，支持按天、批量排班。",
              "支持排班统计，排班数据分析。",
              "支持值班日志录入，记录值班事项。",
              "支持通知公告事件。",
            ],
          },
        ],
        [
          {
            name: "标准服务过程管理",
            tip: [
              "基于工单系统，支持服务过程事前可计划，事中可视，事后可审计、可追踪；",
              "支持对服务过程，进行全面评估服务能力",
            ],
          },
        ],
        [
          {
            name: "智能客户管理",
            tip: [
              "支持客户管理，包括客户信息、客户合同、结算信息、客户服务目录及计划等。",
              "支持客户服务评价管理，服务报表等。",
            ],
          },
        ],
      ],
    };
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    concatUs() {
      window.open("https://v.egeel.vip/cs/c/46v4xpx6", "_blank");
      // setTimeout(() => {
      //   $('img[type="talk"]').parent().parent().css("z-index", "-1000");
      // }, 1000);
    },
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.oneBox);
      this.Animate2 = !isElementNotInViewport(this.$refs.twoBox);
      this.Animate3 = !isElementNotInViewport(this.$refs.threeBox);
    },
    changeClass(index) {
      this.nowIndex = index;
    },
    // 在线客服
    // showAgent() {
    //   var that = this;
    //   that.contactVisible = true;
    // },
    // 最新新闻-查看详情
    viewDetail() {},
  },
};
</script>

<style lang="scss" scoped="scoped">
//产品优势 title
.myanimation {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 2rem;
  bottom: 10%;
  z-index: 999;
  bottom: 78px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 46px;
  height: 120px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  line-height: 1.1;
  text-align: center;
  cursor: pointer;

  img {
    width: 31px;
    height: 31px;
  }
  span {
    width: 12px;
    line-height: 1.1;
  }
}
.box {
  // padding-bottom: 100px;
  width: 100%;
  padding-top: 10rem;
  /* border: 1px solid red; */
}

.banner {
  // padding-top: 8.7rem;
  background: #eceff8;
  background: url(@/img/mobile/solutionb_bg_topbanner@3x.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  padding-top: 8.7rem;
  // padding-bottom: 63.3rem;
  padding-bottom: 76rem;
  margin: 0 auto;
  width: 87.2%;
  text-align: center;
}
.bannerTitle {
  font-size: 4.2rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  padding-bottom: 5.4rem;
}
.keyTxt {
  // color: #3e68ff;
  //   font-size: 42px;
  // font-family: PingFang HK;
  // font-weight: 500;
  color: #3d4566;
}
.bannerFront {
  font-size: 2.8rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  padding: 0;
  line-height: 1.8;
  text-align: justify;
  text-align-last: center;
}

.title1 {
  font-size: 32px;
  font-family: DOUYU;
  font-weight: normal;
  color: #ffffff;
  margin: 168px 0 0 360px;
}

.title2 {
  margin: 22px 0 0 359px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #ffffff;
}

.btn {
  width: 144px;
  height: 48px;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 24px;
  margin: 59px 0 0 360px;
}

.dian {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  float: left;
  margin: 20px 0 0 20px;
}

.font {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  margin-right: 60px;
}

.More {
  margin-left: 51px;
}

.contentTitle {
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
  padding-top: 98px;
  /* margin-top: 98px; */
}

.contentTitleS {
  font-size: 18px;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #8f94b2;
  margin-top: 12px;
}

.contentBox {
  // margin-top: 91px;
  display: flex;
  flex-wrap: wrap;
}

.contentBoxS {
  height: 170px;
  width: 210px;
  margin: 0 50px 0 50px;
}

.contentBoxSs {
  margin-left: 20%;
  display: flex;
}
.contentbox1 {
  text-align: center;
  height: 700px;
  background: #eef6ff;
}
// 四项化
.contentbox {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  background: #f5f7fa;
  // padding: 1rem 0 0;
  // transform: translateY(-27.6rem);
  // border: 1px solid red;
}
.pro-advantage {
  width: 91.47%;
  margin: 0 auto;
}
.pro-stand {
  transform: translateY(-27.6rem);
}
.img-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 1.3rem 4rem 6.5rem;
  width: 100%;
  background: #ffffff;
  border-radius: 0.6rem;
  text-align: center;
  margin-bottom: 2rem;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.6rem;
  }
  img {
    width: 16rem;
    height: 16rem;
  }
  .title {
    font-size: 3.6rem;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
  }
  p {
    font-size: 2.8rem;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    line-height: 1.8;
    text-align: justify;
  }
}
.img-box:last-child {
  margin-bottom: 0;
}
.contenttitle {
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 44px;
}

.contentDetails {
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 30px;
  text-align: left;
}
// 服务可视化大屏

.pro-platform {
  padding: 4.7rem 4.2rem 5.3rem;
  background: #fff;
  margin: 0 auto;
  // margin-top: 8.1rem;
  margin-top: -19.5rem;
}
.pro-platform2 {
  margin-top: 0 !important;
  padding-top: 5.9rem !important;
}
.service-platform {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  img {
    width: 100%;
    height: 100%;
  }
  .content-box {
    font-size: 2.8rem;
    font-family: PingFang HK;
    font-weight: 400;
    color: #3d4566;
    padding: 5rem 1.8rem 0 0;
    .content {
      padding-bottom: 1.8rem;
      display: flex;
      text-align: left;
      line-height: 1.4;
      align-items: center;
      // flex-direction: column;
      // justify-content: space-between;
      img {
        width: 4rem;
        height: 4rem;
        margin-right: 4rem;
      }
    }
  }
}

// 闭环流程图
.contentbox3 {
  box-sizing: border-box;
  background: #f0f5ff;
  border-radius: 0.6rem;
  padding: 12.8rem 7rem 11.9rem;
}
.pro-intro {
  text-align: center;
  font-size: 4.2rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  p {
    margin-bottom: 9.9rem;
  }

  img {
    width: 100%;
  }
}

/* 五个切换 */
.contentbox4 {
  background: #fff;
  // padding: 5.6rem 0 0.8rem;
  padding: 5.6rem 0 5.6rem;
}
.manage-box {
  display: flex;
  flex-direction: column;
  // padding-bottom: 10.4rem;
  // padding-bottom: 9.4rem;
  // justify-content: space-between;
}
.left {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.choiceBox {
  box-sizing: border-box;
  width: 33.4rem;
  height: 9.6rem;
  line-height: 9.6rem;
  text-align: center;
  // background: #ebf2f9;
  background: #ffffff;
  border-radius: 0.6rem;
  margin-bottom: 2.4rem;
  cursor: pointer;
}
.name {
  font-size: 2.8rem;
  font-family: PingFang HK;
  font-weight: 400;
}

.choice {
  color: #426cff !important;
  background: #f0f5ff !important;
  border-radius: 0.6rem;
}
.apply-btn {
  box-sizing: border-box;
  width: 29.3%;
  padding: 2.6rem 0;
  font-size: 2.8rem;
  color: #ffffff;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 8px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 4rem;
  margin: 0 auto;

  text-align: center;
  cursor: pointer;
}
.right {
  box-sizing: border-box;
  background: #ebf2f9;
  border-radius: 0.6rem;
  border: 1px solid #ffffff;
  padding: 5.3rem 5.7rem 6.1rem;
  .title {
    font-size: 3.6rem;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    margin: 6.4rem 0 3.9rem;
  }
  .tip {
    font-size: 2.8rem;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
  }
}
.right-box {
  // margin-bottom: 6.4rem;
  margin-bottom: 4.6rem;
}
.right1 {
  display: flex;
  flex-direction: column;
  .cont {
    // width: 50%;
    // margin-right: 40px;
  }
  .img {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .img1 {
    // width: 502px;
    // height: 437px;
  }
  .img2 {
    // width: 680px;
    // height: 437px;
    // position: relative;
    // left: 10%;
    // height: 426px;
    // width: 1204px;
  }
  .img3 {
    // width: 517px;
    // height: 451px;
  }

  .tip {
    // text-align: left;
    text-align: justify;
    // margin-top: 38px;
    .tip-name {
      font-size: 2.8rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #8f94b2;
    }
    .tip-name::before {
      display: inline-block;
      content: "";
      margin-right: 1.5rem;
      width: 1.6rem;
      height: 1.6rem;
      background: #ebf2f9;
      border: 0.4rem solid #426cff;
      border-radius: 50%;
    }
    .content {
      text-indent: -4rem;
      font-size: 2.8rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #8f94b2;
      margin-top: 1.5rem;
      line-height: 1.8;
      padding-left: 4rem;
    }
  }
}

//  企业资质
// .pro-qual {
//   display: flex;
//   flex-direction: column;
//   // justify-content: space-between;
//   padding-top: 95px;
//   padding-bottom: 88px;
//   width: 63%;
//   margin: 0 auto;
// }

.cloudBox {
  width: 55%;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  margin: 80px auto;
  justify-content: space-between;
}

.cloudLeft {
  width: 250px;
  height: 200px;
  /* margin-left: 10%; */
}

.cloudLeft img {
  width: 100%;
  height: 100%;
}

.cloudRight {
  /* border: 1px solid; */
  width: 543px;
  height: 176px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 32px;
  /* margin-left: 179px; */
}

.cooperationBanner {
  display: flex;
  width: 100%;
  height: 500px;
  background-image: url(@/img/index_part3_logos_png.png);
}

.cooperationBox {
  width: 524px;
  height: 284px;
  background: #f9faff;
  /* border: 1px solid red; */
  text-align: center;
  margin: 108px auto;
  /* 不透明度 */
  opacity: 0.8;
}

.cooperationTitle {
  margin-top: 94px;
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  /* line-height: 44px; */
}

.cooperationTitleEnglish {
  margin-top: 13px;
  font-size: 14px;
  font-family: JI-Sludge;
  font-weight: 600;
  color: #8f94b3;
  /* line-height: 44px; */
}

.cooperationFoot {
  margin-top: 29px;
  font-size: 12px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  /* line-height: 44px; */
}
</style>
