<template>
  <div class="box">
    <div class="banner">
      <div class="bannerBox">
        <div class="bannerTitle">
          <span class="keyTxt">新闻资讯</span>
        </div>
        <div class="bannerFront">服务成就卓越，记录精彩，创享未来！</div>
      </div>
    </div>
    <!-- :class="Animate1 ? 'body myanimation' : 'body'"
      ref="animateBox" -->
    <div class="body">
      <div class="pro-advantage">
        <div v-for="(item, index) in documentList" :key="index" class="bodyBox">
          <div class="left">
            <div class="leftBox">
              <div class="day">{{ item.day }}</div>
              <div class="time">{{ item.date }}</div>
            </div>
          </div>

          <div class="right" @click="go(item.id)">
            <div class="rightHead">{{ item.title }}</div>
            <div class="rightBody" v-html="item.content"></div>
            <!-- @click="go(item.name, item.province, item.date) -->
            <div class="rightFoot">
              <div class="ckxq">阅读详情</div>

              <!-- <img class="rightImg" src="@/img/all_icon_arrow_grey.png" /> -->
              <!-- <div class="rightImg"></div> -->
            </div>
          </div>
        </div>
        <div v-if="docPage.total > 0" class="pagination-box">
          <el-pagination
            layout="total,prev, pager, next"
            @current-change="changeDocPage"
            :page-size="docPage.pageSize"
            background
            :total="docPage.total"
            :current-page="docPage.pageNum"
            class="pagination"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 在线客服 暂时隐藏-->
      <div class="agent" @click="concatUs">
        <img src="@/img/index/icon_service@2x.png" />
        在线咨询
      </div>
      <!-- <DetailDialog
        :visible="newsVisible"
        :newsData="newsDetail"
        @cancel="newsVisible = false"
      /> -->
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "@/common/utils.js";
import API from "@/api/api_surpport";
import DetailDialog from "./children/DetailsDialog.vue";
// 引入富文本编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor, Quill } from "vue-quill-editor";
export default {
  components: {
    DetailDialog,
    quillEditor,
  },
  data() {
    return {
      Animate1: false,
      newsVisible: false,
      data: [],
      titleArr: [
        {
          name: "微软 SQL Server 2008/R2 将停止支持",
        },
        {
          name: "运维资讯：SD-WAN和运维的那点事",
        },
        {
          name: "阿特斯太阳能与高盛签订长期运维服务协议",
        },
        {
          name: "阿特斯太阳能与高盛签订长期运维服务协议",
        },
      ],
      // 文档
      fetchParams: {
        con: {
          attachment: "",
          description: "",
          id: 0,
          name: "",
          type: "",
        },
        customerCode: "",
        customerSite: "",
        // pageNum: 1,
        // pageSize: 10,
      },
      docPage: {
        pageNum: 1,
        pageSize: 3,
        total: 0,
      },
      documentList: [],
      newsDetail: {},
    };
  },
  created() {
    var that = this;
    that.getResourcesList();
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    concatUs() {
      window.open("https://v.egeel.vip/cs/c/46v4xpx6", "_blank");
      // setTimeout(() => {
      //   $('img[type="talk"]').parent().parent().css("z-index", "-1000");
      // }, 1000);
    },
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.animateBox);
    },

    getResourcesList() {
      var params = {
        con: {
          content: "",
          id: 0,
          title: "",
        },
        customerCode: "",
        customerSite: "",
        pageNum: this.docPage.pageNum,
        pageSize: 3,
      };
      API.newsList(params).then((res) => {
        if (res.code == "0000") {
          var templist = res.data.rows;
          templist.forEach((item) => {
            // item.updatedDate = item.updatedDate.replace(/-/g, "/").slice(0, 10);
            // item.date = item.updatedDate.slice(0, 7);
            // item.day = item.updatedDate.slice(8, 10);
            // pushDate
            item.pushDate = item.pushDate.replace(/-/g, "/").slice(0, 10);
            item.date = item.pushDate.slice(0, 7);
            item.day = item.pushDate.slice(8, 10);
          });
          // var templist = res.data.rows || [];
          this.documentList = templist;
          this.docPage.total = res.data.total;
        }
      });
    },

    // 分页
    changeDocPage(e) {
      var that = this;
      that.docPage.pageNum = e;
      that.getResourcesList();
    },
    // 详情
    go(row_id) {
      var that = this;
      // API.newsDetail({ id: row_id }).then((res) => {
      //   if (res.code == "0000") {
      //     this.newsDetail = res.data;
      //   }
      // });
      this.$router.push({
        path: "/NewsDetailsM",
        query: {
          id: row_id,
        },
      });
      // that.newsVisible = true;
    },

    // 新闻资讯详情 弹窗
    // go(name, province, data) {
    //   this.data = data;
    //   this.newsVisible = true;
    //   // var name = name;
    //   // var province = province;
    //   // var data = data;
    // },
  },
};
</script>

<style lang="scss" scoped="scoped">
// 翻页
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #3e68ff;
}
/deep/ .el-pagination.is-background .el-pager li:hover {
  color: #3e68ff;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  // background-color: #409EFF;
  color: #fff;
}
// 新闻body
.myanimation {
  animation: load 0.6s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 2rem;
  bottom: 10%;
  z-index: 999;
  bottom: 78px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 46px;
  height: 120px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  line-height: 1.1;
  text-align: center;
  cursor: pointer;

  img {
    width: 31px;
    height: 31px;
  }
  span {
    width: 12px;
    line-height: 1.1;
  }
}
// .box {
//   background-color: #fbfcff;
//   padding-bottom: 148px;
// }

.box {
  // padding-bottom: 100px;
  width: 100%;
  padding-top: 10rem;
  /* border: 1px solid red; */
}

.banner {
  // padding-top: 8.7rem;
  background: #eceff8;
  background: url(@/img/mobile/news@2x.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  padding-top: 8.7rem;
  padding-bottom: 63.3rem;
  margin: 0 auto;
  width: 87.2%;
  text-align: center;
}
.bannerTitle {
  font-size: 4.2rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  padding-bottom: 3.2rem;
}
.keyTxt {
  color: #3e68ff;
}
.bannerFront {
  font-size: 2.8rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  padding: 0;
  line-height: 1.8;
  text-align: justify;
  text-align-last: center;
}
.body {
  box-sizing: border-box;
  width: 100%;
  margin: 0px auto;
  padding: 6rem 0 8.9rem;
  // padding: 6rem 0 20.9rem;

  // min-width: 820px;
  // /* border: 1px solid red; */
}
.pro-advantage {
  box-sizing: border-box;
  width: 91.47%;
  margin: 0 auto;
}
.bodyBox {
  // padding: 4rem 4.9rem 4rem 3rem;
  padding: 4rem 3.6rem 4rem 3rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  background: #f4f8fb;
  border-radius: 0.6rem;
  margin-bottom: 3rem;
}
.left {
  margin-right: 3rem;
  // border: 1px solid red;
}

.leftBox {
  // width: 152px;
  // height: 152px;
  padding: 4.7rem 6.6rem 4.8rem 3rem;
  background: #ffffff;
  text-align: center;
  // margin-top: 40px;
}
.day {
  font-size: 6.4rem;
  font-family: Bebas;
  font-weight: 400;
  color: #3d4566;
  margin-bottom: 6rem;
}
.time {
  font-size: 2.4rem;
  font-family: Bebas;
  font-weight: 400;
  color: #8f94b2;
}
.right {
  overflow: hidden;

  // height: 13.55rem;
  // display: flex;
  // flex-wrap: wrap;
  // align-content: space-between;
  // padding: 40px 0;
  // border: 1px solid red;
  .rightBody {
    /deep/ p {
      img {
        width: 20% !important;
        height: 20% !important;
      }
    }
  }
}
.right:hover {
  cursor: pointer;
  .rightHead,
  .ckxq {
    color: #3e68ff;
  }
}
.rightHead {
  font-size: 3rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  width: 41rem;
  // height: 4rem;
}
.rightBody {
  font-size: 2.4rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  margin: 4.3rem 0 4.6rem;
  max-height: 3.5rem;
}
.rightFoot {
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ckxq {
  font-size: 2.4rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #426cff;
  background: #fff;
  padding: 1.8rem 2.4rem;
}
.rightImg {
  width: 14px;
  height: 8px;
  // margin-top: 8px;
  // margin-left: 20px;
  text-align: center;
  img {
  }
}
// 分页
.pagination-box {
  width: 100%;
  margin-top: 3rem;
  .pagination {
    text-align: right;
    // padding-right: 22px;
    padding-top: 2rem;
  }
}
</style>
