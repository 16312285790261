<template>
  <div class="box">
    <div class="banner">
      <img src="../img/images/support_bg_topbanner@2x.png" />
      <div class="bannerBox">
        <div class="bannerTitle">
          <span class="keyTxt">资源下载</span>
        </div>
        <div class="bannerFront">
          最新的文档、工具及软件的下载。为业务执行与实施提供强有力的帮助，如有遗漏，请联系在线客户。每日更新请保持关注。
        </div>
      </div>
    </div>
    <div class="body">
      <div class="left">
        <div
          v-for="(item, index) in typeList"
          :class="{ choice: index == nowIndex }"
          :key="index"
          class="choiceBox"
          @click="changeClass(index, item.key, item.name)"
        >
          <div class="dian" :class="{ choicedian: index == nowIndex }"></div>
          <div class="name">{{ item.name }}下载</div>
        </div>
      </div>
      <!-- 文档列表 -->
      <div class="right">
        <template v-if="nowName != 'App'">
          <div
            v-for="(item, index) in documentList"
            :key="index"
            class="rightBox"
          >
            <div class="Rleft">
              <div class="imgBox">
                <img src="../img/images/support_icon_1@2x.png" />
              </div>
            </div>
            <div class="Rright" @click="go(item.id)">
              <div class="title">{{ item.name }}</div>
              <div class="xq">
                查看详情
                <img src="../img/all_icon_arrow_grey.png" />
              </div>
            </div>
          </div>
          <div v-if="docPage.total > 0" class="pagination-box">
            <el-pagination
              layout="total,prev, pager, next"
              @current-change="changeDocPage"
              :page-size="docPage.pageSize"
              background
              :total="docPage.total"
              :current-page="docPage.pageNum"
              class="pagination"
            >
            </el-pagination>
          </div>
        </template>
        <template v-else>
          <div class="codeItem">
            <div class="codeBox">
              <!-- <img src="../img/images/codeImg.png" class="codeIMG" /> -->
              <div class="codeImg">
                <vue-qr
                  class="codeIMG"
                  :size="100"
                  :margin="0"
                  :auto-color="true"
                  :dot-scale="1"
                  :text="qrHref"
                  :logoSrc="qrIcon"
                />
              </div>
              <div class="title txt">全栈云服-智慧服务</div>
            </div>
            <!-- it-app下载 -->
            <div class="codeBox">
              <!-- <img src="../img/images/codeImg.png" class="codeIMG" /> -->
              <div class="codeImg">
                <vue-qr
                  class="codeIMG"
                  :size="100"
                  :margin="0"
                  :auto-color="true"
                  :dot-scale="1"
                  :text="itQrHref"
                  :logoSrc="itQrIcon"
                />
              </div>
              <div class="title txt">全栈云服-站点</div>
            </div>
          </div>
        </template>
      </div>
      <!-- 工具列表 -->
      <!-- <div v-if="nowIndex == 1" class="right">
       
        <div
          v-for="(item, index) in toolList"
          :key="index + 'w'"
          class="rightBox"
        >
          <div class="Rleft">
            <div class="imgBox">
              <img src="../img/images/support_icon_2@2x.png" />
            </div>
          </div>
          <div class="Rright">
            <div class="title">{{ item.name }}</div>
            <div class="xq" @click="go(item.id)">
              查看详情
              <img src="../img/all_icon_arrow_grey.png" />
            </div>
          </div>
        </div>
        <div v-if="toolPage.total > 0" class="pagination-box">
          <el-pagination
            layout="total,prev, pager, next"
            @current-change="changeToolPage"
            :page-size="toolPage.pageSize"
            background
            :total="toolPage.total"
            :current-page="toolPage.pageNum"
            class="pagination"
          >
          </el-pagination>
        </div>
      </div>  -->
      <!-- 软件列表 -->
      <!-- <div v-if="nowIndex == 2" class="right">
       
        <div
          v-for="(item, index) in softList"
          :key="index + 'q'"
          class="rightBox rightBoxH"
        >
          <div class="Rleft">
            <div class="imgBox">
              <img src="../img/images/support_icon_3@2x.png" />
            </div>
          </div>
          <div class="Rright">
            <div class="title">{{ item.name }}</div>
            <div class="mid">版本1.0 &nbsp; &nbsp;大小986kb</div>
            <div class="xq xqM" @click="go(item.id)">
              查看详情
              <img src="../img/all_icon_arrow_grey.png" />
            </div>
          </div>
        </div>
        <div v-if="softPage.total > 0" class="pagination-box">
          <el-pagination
            layout="total,prev, pager, next"
            @current-change="changeSoftPage"
            :page-size="softPage.pageSize"
            background
            :total="softPage.total"
            :current-page="softPage.pageNum"
            class="pagination"
          >
          </el-pagination>
        </div>
      </div> -->
      <!-- 资讯情报 -->
      <!-- <div v-if="nowIndex == 3" class="right">
        <div class="zxqbHead">
          <div class="zxqbChoiceBox">
            <div
              v-for="(item, index) in ku"
              :key="index"
              @click="table(index)"
              :class="{ zxqbChoice: index == nowIndex1 }"
              class="zxqb1"
            >
              {{ item.name }}
            </div>
            <div class="zxqb1">
							版本库
						</div>
          </div>
          <div class="inputBox">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
            <el-button slot="append" type="primary" icon="el-icon-search"
              >搜索</el-button
            >
          </div>
        </div>
        <div v-if="nowIndex1 == 0" class="table">
          <table cellspacing="0" cellpadding="0" border="1">
            <tr>
              <th>漏洞名称</th>
              <th>漏洞编号</th>
              <th>发布时间</th>
            </tr>
            <tr v-for="(item, index) in tableData" :key="index">
              <td @click="go(item.name, item.province, item.date)" class="td1">
                {{ item.name }}
              </td>
              <td class="td2">{{ item.province }}</td>
              <td class="td3">{{ item.date }}</td>
            </tr>
          </table>
        </div>
        <div v-else class="table">
          <table cellspacing="0" cellpadding="0" border="0">
            <tr>
              <th>版本名称</th>
              <th>版本编号</th>
              <th>发布时间</th>
            </tr>
            <tr v-for="(item, index) in tableData" :key="index">
              <td class="td1">{{ item.name }}</td>
              <td class="td2">{{ item.province }}</td>
              <td class="td3">{{ item.date }}</td>
            </tr>
          </table>
        </div>
      </div> -->
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" onClick="__egeelTalk()">
      <img src="../img/index/icon_service@2x.png" />
      <span>在线咨询</span>
    </div>
    <!--查看界面-->
    <el-dialog
      :visible.sync="downVisible"
      :show-close="false"
      :close-on-click-modal="false"
      width="40%"
    >
      <!-- v-for="(item, i) in scope.row.toolAttach"
                  :key="i"
                  @click="download(scope.row, i)" -->
      <div class="down-body">
        <div class="down-box">
          <div class="title">
            <!-- <img src="../img/images/support_icon_1@2x.png" /> -->
            <span>{{ docDetail.name }}</span>
          </div>
          <p>
            {{ docDetail.description }}
          </p>
        </div>
        <div class="close_box">
          <!-- 右上角定位  -->
          <img
            class="close_img"
            src="~@/img/images/icon_close@2x.png"
            alt=""
            @click="downVisible = false"
          />
        </div>
        <div class="fileBox">
          <div
            v-for="(i, index) in docDetail.attachment"
            :key="index"
            @click="downTemp(i)"
          >
            <img
              class="down_img"
              src="~@/img/images/support_icon_1@2x.png"
              alt=""
            />
            <span class="downLoad">{{ i.name }}</span>
          </div>
        </div>
        <!-- @click.native="downTemp(docDetail.attachment)" -->
        <!-- <div slot="footer" class="dialog-footer">
          <el-button class="down-btn">点击文件立即下载</el-button>
        </div> -->
      </div>
    </el-dialog>
    <!-- <el-dialog :visible.sync="showImage" width="900px" :before-close="closede">
      <img style="width: 100%; height: 100%" :src="imageUrl" alt="" />
    </el-dialog> -->
  </div>
</template>

<script>
import API from "@/api/api_surpport";
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      qrIcon: require("../img/images/logo.jpg"),
      qrHref:
        window.location.host == "192.168.82.5:8060" ||
        window.location.host == "http://test.fullstackcs.com"
          ? "http://testit.fullstackcs.com" + "/clientDown?type=saas"
          : "http://itss.fullstackcs.com" + "/clientDown?type=saas",
      downVisible: false,
      arr: [
        {
          name: "文档下载",
        },
        {
          name: "工具下载",
        },
        {
          name: "软件下载",
        },
        // {
        // 	name: '资讯情报',
        // },
      ],
      ku: [
        {
          name: "软件漏洞库",
        },
        {
          name: "版本库",
        },
      ],
      nowIndex: 0,
      nowIndex1: 0,
      brr: [1, 2, 3, 4],
      crr: [1, 2],
      activeName: "first",
      input: "",
      tableData: [
        {
          date: "2021-12-06",
          name: "Taocms SQL注入漏洞   ",
          province: "CNNVD-202112-101",
        },
        {
          date: "2021-12-05",
          name: "Renesas Electronics Renesas RX65和RX65N 安全漏洞",
          province: "CNNVD-202112-085",
        },
        {
          date: "2021-12-05",
          name: "WordPress 跨站脚本漏洞      ",
          province: "CNNVD-202112-101",
        },
        {
          date: "2021-12-04",
          name: "Taocms SQL注入漏洞   ",
          province: "CNNVD-202112-101",
        },
        {
          date: "2021-12-04",
          name: "WordPress 跨站脚本漏洞     ",
          province: "CNNVD-202112-085",
        },
        {
          date: "2021-12-03",
          name: "Taocms SQL注入漏洞   ",
          province: "CNNVD-202112-101",
        },
      ],
      names: "",
      // 文档
      fetchParams: {
        con: {
          attachment: "",
          description: "",
          id: 0,
          name: "",
          type: "",
        },
        customerCode: "",
        customerSite: "",
        // pageNum: 1,
        // pageSize: 10,
      },
      docPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      documentList: [],
      docDetail: {},
      typeList: [], //类型
      keyType: "1",
      typeName: "",
      showImage: false,
      nowName: "",
      itQrHref: "http://itss.fullstackcs.com" + "/appdown",
      // window.location.protocol + "//" + window.location.host + "/appdown",
      itQrIcon: require("../img/images/index_site_icon.png"),
    };
  },
  created() {
    var that = this;
    that.getType();
    that.getResourcesList();
    // that.getToolList();
    // that.getSoftList();
  },
  methods: {
    getType() {
      API.findDictionaryList({
        con: {
          key: "resourceType",
        },
      }).then((res) => {
        this.typeList = JSON.parse(res.data);
        var appType = {
          name: "App",
          key: "4",
        };
        this.typeList.push(appType);
      });
    },
    // 文档列表
    getResourcesList() {
      var params = {
        con: {
          attachment: "",
          description: "",
          id: 0,
          name: "",
          type: this.keyType,
        },
        customerCode: "",
        customerSite: "",
        pageNum: this.docPage.pageNum,
        pageSize: 10,
      };

      API.resourcesList(params).then((res) => {
        if (res.code == "0000") {
          var templist = res.data.rows;
          // var templist = res.data.rows || [];
          this.documentList = templist;
          this.docPage.total = res.data.total;
        }
      });
    },

    // 分页
    changeDocPage(e) {
      var that = this;
      that.docPage.pageNum = e;
      that.getResourcesList();
    },

    changeClass(index, key, name) {
      var that = this;
      that.nowIndex = index;
      that.nowName = name;
      // return;
      if (name != "App") {
        this.keyType = key;
        this.typeName = name;
        that.docPage.pageNum = 1;
        that.getResourcesList();
      }
    },
    handleClick(tab, event) {},
    table(index) {
      this.nowIndex1 = index;
    },
    // 详情
    go(row_id) {
      var that = this;
      var params = row_id;
      // {
      //   id: ,
      // };
      API.resourceDetail(params).then((res) => {
        if (res.code == "0000") {
          var templist = res.data;
          templist.attachment = JSON.parse(res.data.attachment);
          this.docDetail = templist;
        }
      });
      that.downVisible = true;
    },
    downTemp(e) {
      // const params = new URLSearchParams();
      // var e = JSON.parse(demoFile)[0];
      API.downFile({
        fileName: e.name,
        fileUrl: e.url,
      }).then((res) => {
        if (res.resultCode) {
          return;
        }
        var allStr = res;
        var export_blob = new Blob([allStr]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([allStr]), e.name);
        } else {
          var save_link = document.createElement("a");
          save_link.href = window.URL.createObjectURL(export_blob);
          save_link.download = e.name;
          save_link.click();
          // this.$message.success("下载成功！");
          setTimeout(() => {
            this.$message.success("文件下载成功！");
          }, 500);
        }
      });
    },
    // 新闻咨询详情页
    // go(name, province, data) {
    //   var name = name;
    //   var province = province;
    //   var data = data;
    //   this.$router.push({
    //     // path: '/SupportDetails',
    //     name: `SupportDetails`,
    //     query: {
    //       name: name,
    //       province: province,
    //       data: data,
    //     },
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped="scoped">
// 翻页
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #3e68ff;
}
/deep/ .el-pagination.is-background .el-pager li:hover {
  color: #3e68ff;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  // background-color: #409EFF;
  color: #fff;
}
// 在线客服
.agent {
  position: fixed;
  right: 20px;
  bottom: 10%;
  z-index: 999;
  top: 465px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  height: 140px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  cursor: pointer;

  img {
    width: 41px;
    height: 41px;
  }
  span {
    width: 16px;
    line-height: 1.1;
  }
}
// 资源下载
.down-btn {
  width: 200px;
  height: 56px;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 28px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  // margin-bottom: 20px;
}

.fileBox {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  img {
    width: 22px;
    height: 24px;
    margin-right: 10px;
  }
  .downLoad {
    cursor: pointer;
    color: #3e68ff;
    display: inline-block;
    margin-bottom: 15px;
    padding-top: 2px;
  }
}
.down-body {
  min-height: 260px;
}
/deep/ .el-dialog {
  border-radius: 30px;
  .el-dialog__body {
    padding: 0 64px 48px;
  }
  .dialog-footer {
    text-align: center;
  }
}
.down-box {
  margin-bottom: 88px;
  .title {
    margin-bottom: 35px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    span {
      font-size: 28px;
      font-family: PingFang HK;
      font-weight: 600;
      color: #3d4566;
    }
  }
  p {
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #3d4566;
    line-height: 1.7;
    // padding: 0 10px;
  }
}
.close_img {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
.box {
  background-color: #fbfcff;
  // padding-bottom: 81px;
  padding-bottom: 160px;
}

.banner {
  width: 1903px;
  height: 400px;
  padding-top: 70px;
  background: #eceff8;
  // padding-top: 70px;
  // background: url(../img/index/index_banner@2x.png) no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  width: 63%;
  margin: 0 auto;
  /* height: 100%; */
  // width: 750px;
  // padding: 0px 0 0 360px;
  /* border: 1px solid red; */
  margin-top: -310px;
}
.bannerTitle {
  font-size: 48px;
  font-family: Source Han Sans SC;
  font-weight: 800;
  color: #101635;
}
.keyTxt {
  color: #3e68ff;
}
.bannerFront {
  /* width: 410px;
  height: 18px; */
  width: 61%;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #101635;
  padding: 26px 15px 58px 0;
  line-height: 1.7;
}

.body {
  width: 63%;
  // padding: 120px 360px 0;
  padding-top: 120px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  min-width: 820px;
  min-height: 400px;
}

.left {
  width: 232px;
  height: 256px;
}

.choiceBox {
  margin-top: 1px;
  color: black;
  display: flex;
  align-items: center;
  width: 232px;
  height: 64px;
  background: rgba(62, 104, 255, 0.06);
  cursor: pointer;
}

.dian {
  width: 8px;
  height: 8px;
  background: linear-gradient(90deg, #ccd7ff, #f0f3ff);
  border-radius: 50%;
  margin: 28px 0 28px 40px;
}

.choicedian {
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  margin: 28px 0 28px 40px;
}

.name {
  margin-left: 18px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
}

.choice {
  color: #fefefe;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
}

.right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // border: 1px solid yellow;
  margin-left: 28px;
  // margin-left: 68px;
  width: 100%;
  .codeItem {
    display: flex !important;
    // flex-direction: column !important;
    //   align-items: center !important;
    //   background: green;
    .codeBox {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 180px;
      height: 180px;
      background: linear-gradient(
        20deg,
        #d6cafc,
        #e7e3fc,
        #eae6f1,
        #ebedff,
        #f1f6fa,
        #f3f2fc
      );
      border: 1px solid #ffffff;
      border-radius: 6px;
      padding: 20px;
      margin-right: 28px;

      .codeIMG {
        width: 100px;
        height: 100px;
        // background: #171717  ;
      }
      .txt {
        margin: 14px 0 0;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .ttx {
      font-size: 14px;
      font-family: PingFang HK;
      font-weight: 400;
      color: #ffffff;
    }
  }
}

.rightBox {
  // width: 419px;
  width: 426px;

  height: 138px;
  background: #ffffff;
  border: 1px solid #e6ebfa;
  display: flex;
  margin-left: 40px;
  margin-bottom: 40px;
  cursor: pointer;
}
.rightBox:hover {
  // box-shadow: 4px 7px 20px 0px rgba(55, 99, 170, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(184, 190, 204, 0.16);
  animation: load 0.4s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.rightBoxH {
  height: 210px;
}

.imgBox {
  width: 30px;
  height: 30px;
  margin: 41px 0 0 31px;
}

.imgBox img {
  width: 100%;
  height: 100%;
}

.Rright {
  /* border: 1px solid #0000FF; */
  margin-left: 20px;
  width: 100%;
}

.title {
  margin: 36px 0 0 0px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 32px;
}

.xq {
  /* width: 65px;
		height: 16px; */
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 32px;
  cursor: pointer;
}

.xqM {
  margin-top: 10px;
}

.xq img {
  margin-top: 10px;
  margin-left: 3px;
}

.mid {
  width: 200%;
  border-bottom: 1px solid #e6ebfa;
  height: 46px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  /* line-height: 32px; */
  margin-top: 19px;
}

.zxqbHead {
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  height: 40px;
}

.zxqbChoiceBox {
  /* border: 1px solid red; */
  display: flex;
}

.zxqb1 {
  text-align: center;
  width: 100px;
  line-height: 30px;
  margin-right: 20px;
  height: 30px;
  color: #8f94b2;
  cursor: pointer;
  border-bottom: 1px solid #8f94b2;
}

.inputBox {
  margin-left: 250px;
  width: 400px;
  display: flex;
}

.table {
  width: 100%;
  /* border: 1px solid blue; */
  margin-top: 40px;
}

.zxqbChoice {
  color: #3e68ff;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 600;
  border-bottom: 2px solid #3e68ff;
  /* line-height: 44px; */
  cursor: pointer;
}

td {
  height: 64px;
  background: #ffffff;
  border: 1px solid #e6ebfa;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 28px;
  padding-left: 20px;
}

th {
  text-align: left;
  padding-left: 20px;
  height: 40px;
  background: #f0f3ff;
  border: 1px solid #e6ebfa;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 44px;
}

.td1 {
  width: 440px;
  cursor: pointer;
}

.td2 {
  width: 280px;
  cursor: pointer;
}

.td3 {
  width: 182px;
  cursor: pointer;
}
// 分页
.pagination-box {
  width: 100%;
  .pagination {
    text-align: right;
    padding-right: 22px;
  }
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #4970ff;
}
@media screen and (max-width: 1669px) {
  .rightBox {
    width: 48%;
  }
}
@media screen and (max-width: 479px) {
  .box {
    width: 230%;
  }

  .banner {
    width: 100%;
    height: 160px;
  }

  .bannerBox {
    height: 100%;
    padding: 0px 0 0 20px;
    margin-top: -120px;
  }

  .bannerImg {
    width: 100%;
    height: 100%;
  }

  .left {
    margin-left: 60px;
  }

  .td1 {
    width: 200px;
  }

  .td2 {
    width: 180px;
  }

  .td3 {
    width: 82px;
  }

  .inputBox {
    margin-left: 10px;
    width: 220px;
    display: flex;
  }
}
</style>
