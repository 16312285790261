import Vue from 'vue'
import App from './App.vue'
// import store from './store'
// import store from '@/plugins/store'
import rem from './common/rem'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/iconfont.css";
import echarts from 'echarts' // 这一步是引入echarts的插件
Vue.prototype.$echarts = echarts // 这一步是为了能在全局直接使用
import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper/dist/ssr'
import "../node_modules/swiper/dist/css/swiper.min.css";
import "../node_modules/swiper/dist/js/swiper.min";

function timeStamp() {
    var _time = AES.encrypt(Date.now() + "&client-service", "123456{saltNS}..");
    return `?client=${encodeURIComponent(_time)}`;
}
import AES from "@/common/AES";
Vue.prototype.$timeStamp = timeStamp;
/**
 * 替换图片链接
 * @param {*} a
 * @returns
 */
function replaceImg(a, isCloud) {
    // a 为富文本的字符串内容，为了测试，只写了img标签
    var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
    var s = a.match(b); // 取到所有img标签 放到数组 s里面
    if (s) {
        for (var i = 0; i < s.length; i++) {
            var srcImg = s[i].replace(b, "$1"); //取src面的内容
            // if (isCloud) {
            a = a.replace(srcImg, srcImg + timeStamp());
            // } else {
            //   a = a.replace(srcImg, srcImg + "?access_token=" + store.state.token);
            // }
        }
    }
    return a;
}
Vue.prototype.$replaceImg = replaceImg;
/**
 * 替换v-html的图片路径
 */
Vue.directive("imgtoken", {
    bind(el, binding) {
        // var _str =
        //   typeof binding.value == "object" ? binding.value.content : binding.value;
        // var isCloud = binding.value.isCloud || false;
        var _str = binding.value;
        el.innerHTML = _str ? replaceImg(_str) : "";
    },
    update(el, binding) {
        // var _str =
        //   typeof binding.value == "object" ? binding.value.content : binding.value;
        // var isCloud = binding.value.isCloud || false;
        var _str = binding.value;
        if (_str && _str != el.innerHTML.replace(/\?client=(.*?)\"/g, '"')) {
            el.innerHTML = replaceImg(_str);
        }
    }
});



// 引入路由
import router from "@/route/routerPC" // import router 的router 一定要小写， 不要写成Router, 否则报 can't match的报错

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key /
    ak: 'QrKw6BpO274b6mXQ5T1uSrMt2kZGLE5E'
})
import axios from 'axios'
Vue.prototype.$axios = axios;

// 配置请求的根路径 '/api/'正式环境 打包记得改
axios.defaults.baseURL = '/api/'
    // axios.defaults.baseURL = 'http://192.168.7.152:8082/'
    // axios.defaults.baseURL = 'http://192.168.7.55:8090/'
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};
Vue.use(ElementUI);
import { quillEditor } from 'vue-quill-editor'

// main.js中引入样式文件
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(quillEditor)
new Vue({
    el: '#app',

    router, // 注入到根实例中
    render: h => h(App)
})