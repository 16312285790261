<template>
  <div class="box">
    <div class="banner">
      <img src="../img/index/index_banner@2x.png" />
      <div class="bannerBox">
        <div class="bannerTitle">
          智慧
          <span class="keyTxt">一站式</span>
          站点管理解决方案
        </div>
        <div class="bannerFront">
          全栈云服致力于ICT服务标准化、可视化、专业化，实现客户ICT服务智能管理；链接客户、服务机构及专业云端专业服务团队，打通ICT服务的最后一公里
        </div>
        <div class="btn banner-btn" @click="toProgramme">
          <div class="font">
            <div class="dian"></div>
            More
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="contentbox contentbox1">
        <div class="contentTitlebox">
          <div class="contentTitle">
            全栈云服为客户提供全方位的服务和优质的产品
          </div>
          <div class="contentTitleS">OUR SERVICE</div>
        </div>
        <!-- 可提供的服务 -->
        <!-- 服务体系/团队介绍 -->
        <!-- class="pro-advantage" -->
        <!-- :class="Animate1 ? 'pro-advantage myanimation' : 'pro-advantage'"
          ref="contentbox"-->
        <div class="pro-advantage">
          <div class="monitor">
            <div class="monitor-content" style="margin-bottom: 20px">
              <div class="contentTop">
                <div class="title">易监控</div>
                <img src="../img/index_icon_part1_icon1.png" />
              </div>

              <div>
                <div class="monitor-time">
                  将ICT专业服务从签订合同到服务合同期满的全过程进行标准化拆解，参考合同服务目录，形成标准化的服务流程、计划，进行智能可视化管理；支持基于时间、事件触发、用户自定义制定服务计划等。
                </div>
              </div>
            </div>
            <div class="monitor-content" style="margin-bottom: 20px">
              <div class="contentTop">
                <div class="title">智能服务团队管理服务</div>
                <img src="../img/index_icon_part1_icon2.png" />
              </div>

              <div>
                <div class="monitor-time">
                  支持服务机构管理，按照不同服务机构进行组织数字化管理。支持服务工程师团队管理，并支持工程师一键发起工单、支持服务机构与工程师后服务评价体系；用户可自定义事件周期，自动输出服务机构周期内服务评估报告。
                </div>
              </div>
            </div>
            <!-- class="monitor-content" -->
            <div
              :class="
                Animate1 ? 'monitor-content myanimation' : 'monitor-content'
              "
              ref="animateBox"
              style="margin-bottom: 20px"
            >
              <div class="contentTop">
                <div class="title">数字化工单服务</div>
                <img src="../img/index_icon_part1_icon3.png" />
              </div>

              <div>
                <div class="monitor-time">
                  基于智能化、数字化的工单系统，支持全服务流程可视、可管、可回溯，实现服务商、云端专家快速响应。支持基于通话自动创建工单，支持一键创建工单；支持工单服务内容互动、服务内容确认，快速完成服务内容。
                </div>
              </div>
            </div>
            <!-- class="monitor-content"  -->
            <div
              :class="
                Animate1 ? 'monitor-content myanimation' : 'monitor-content'
              "
              ref="animateBox"
              style="margin-bottom: 20px"
            >
              <div class="contentTop">
                <div class="title">远程协助服务</div>
                <img src="../img/index_icon_part1_icon4.png" />
              </div>

              <div>
                <div class="monitor-time">
                  支持一键式发起远程协助，调度云端专家提供快速、专业的远程支持服务；提供服务过程留存，通过录音屏实现远程服务可追溯；本地站点服务团队可快速现场服务，云端专家团队24小时在线服务协助，400电话24*365实时在线。
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 原四列 -->
        <!-- <div class="contentBox">
          <div class="contentBoxS contentBoxSs">
            <div class="contentTop">
              <div class="contentImg">
                <img src="../img/index_icon_part1_icon1.png" />
              </div>
              <div class="contenttitle">运维服务</div>
            </div>

            <div class="contentDetails">
              提供线上、线下的运维服务。保障客户业务安全
            </div>
          </div>
          <div class="contentBoxS">
            <div class="contentImg">
              <img src="../img/index_icon_part1_icon2.png" />
            </div>
            <div class="contenttitle">集成交付</div>
            <div class="contentDetails">
              产品采购与交付服务解耦，降低成本及风险
            </div>
          </div>
          <div class="contentBoxS">
            <div class="contentImg">
              <img src="../img/index_icon_part1_icon3.png" />
            </div>
            <div class="contenttitle">咨询规划</div>
            <div class="contentDetails">
              凝聚大批行业资深专家及合作伙伴。帮助客户规划未来。
            </div>
          </div>
          <div class="contentBoxS">
            <div class="contentImg">
              <img src="../img/index_icon_part1_icon4.png" />
            </div>
            <div class="contenttitle">定制培训</div>
            <div class="contentDetails">
              帮助全栈ICT赋能，帮助客户提升综合能力。
            </div>
          </div>
        </div> -->
      </div>
      <!-- class="contentbox" :class="Animate1 ? 'contentbox myanimation' : 'contentbox'"
        ref="contentbox"-->
      <div
        :class="Animate2 ? 'contentbox myanimation1' : 'contentbox'"
        ref="tepsBox"
      >
        <div class="teps">
          <!-- <div class="tepsBox" @click="showContent(1)">
            <div class="tepsImgs">
              <img src="../img/index_icon_part2_icon1.png" />
            </div>
            <div class="tepsName">拓展平台</div>
          </div>
          <div class="line"></div> -->
          <div class="tepsBox" @click="showContent(1)">
            <div class="choiceImg">
              <img src="../img/index_icon_part2_icon2.png" />
            </div>
            <div class="tepsName">监控平台</div>
          </div>
          <div class="lineS"></div>
          <div class="tepsBox" @click="showContent(2)">
            <div class="tepsImgs">
              <img src="../img/index_icon_part2_icon3.png" />
            </div>
            <div class="tepsName">展示平台</div>
          </div>
          <div class="lineS"></div>
          <div class="tepsBox" @click="showContent(3)">
            <div class="tepsImgs">
              <img src="../img/index_icon_part2_icon4.png" />
            </div>
            <div class="tepsName">安管平台</div>
          </div>
          <div class="lineS"></div>
          <div class="tepsBox" @click="showContent(4)">
            <div class="tepsImgs">
              <img src="../img/index_icon_part2_icon5.png" />
            </div>
            <div class="tepsName">资产平台</div>
          </div>
          <div class="lineS"></div>
          <div class="tepsBox" @click="showContent(5)">
            <div class="tepsImgs">
              <img src="../img/index_icon_part2_icon6.png" />
            </div>
            <div class="tepsName">工单平台</div>
          </div>
        </div>
        <div class="tepsFootBox"></div>
        <div
          class=""
          :class="Animate3 ? 'cloudBox myanimation1' : 'cloudBox'"
          ref="platContBox"
        >
          <div class="cloudLeft">
            <img src="../img/index_part2_left_img1.png" />
          </div>
          <!-- <div v-if="teps == 1" class="cloudRight">
            拓扑平台（cloudcomputing）是分布式计算的一种，指的是通过网络“云”将巨大的数据计算处理程序分解成无数个小程序，然后，通过多部服务器组成的系统进行处理和分析这些小程序得到结果并返回给用户。云计算早期，简单地说，就是简单的分布式计算，解决任务分发，并进行计算结果的合并。因而，云计算又称为网格计算。通过这项技术，可以在很短的时间内（几秒钟）完成对数以万计的数据的处理，从而达到强大的网络服务。
          </div> -->
          <div v-if="teps == 1" class="cloudRight">
            监控平台主要为应用监控和设备监控，对企业的关键业务的应用和设备进行监测，实时获取应用和设备的健康信息、性能信息，提高企业应用和设备的可用性，保证关键核心业务的正常运转。
          </div>
          <div v-if="teps == 2" class="cloudRight">
            数据可视化展示平台，以表格、图表等多种方式对企业的数据进行统计和分析，并直观的提现，帮助企业挖掘数据中的价值，保证企业数据管理有效运转，协助企业经营者辅助决策。
          </div>
          <div v-if="teps == 3" class="cloudRight">
            对设备进行标准化以及规范化的管理，24小时实时监测设备状态，设备异常智能告警，根据设备和应用进行在线巡检、漏洞扫描、补丁修复等安全措施。
          </div>
          <div v-if="teps == 4" class="cloudRight">
            帮助医院实现各类资产的统一管理，并对医疗设备从科室入出库、维修保养到报废处置进行全生命周期的数字化管理，全面满足医院规范管理和质量控制的更多要求，建立资产和耗材备件台账，规范资产借用、归还、转科、维修、报废等处置流程。
          </div>
          <div v-if="teps == 5" class="cloudRight">
            基于智能化、数字化的工单平台，支持全程服务可视、可管、可回溯，实现服务商、云端专家快速响应。支持基于通话自动创建工单，支持一键创建工单；支持工单服务内容互动、服务内容确认，快速完成服务内容；做到服务全程留痕、信息充分共享、进度实时掌握。
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="contentbox contentbox1 partnerBox">
        <div
          class=""
          :class="Animate4 ? 'contentTitlebox myanimation1' : 'contentTitlebox'"
          ref="partnerBox"
        >
          <div class="contentTitle">他们都在使用全栈云服</div>
          <div class="contentTitleS">OUR PARTNER</div>
        </div>

        <div
          class=""
          :class="Animate5 ? 'pro-advantage myanimation6' : 'pro-advantage'"
          ref="partcontBox"
        >
          <div class="img-area">
            <div class="img-box" v-for="(item, index) in imgArr" :key="index">
              <img :src="item.image" />
            </div>
          </div>
        </div>
      </div>
      <!-- 最新新闻 -->
      <div class="contentbox contentboxHeight">
        <div
          class=""
          :class="Animate6 ? 'newsBox myanimation6' : 'newsBox'"
          ref="newsBox"
        >
          <div class="newsLeft">
            <div class="newsLeftHead">
              <img src="../img/index_part4_news_deco.png" />
            </div>
            <div class="newsLeftFoot">
              <div class="newsTitle">最新新闻</div>
              <div class="newsTitleEnglish">news</div>
              <div class="btn" @click="toNews">
                <div class="font">
                  <div class="dian"></div>
                  More
                </div>
              </div>
            </div>
          </div>
          <div class="newsRight">
            <div
              class="newsRightBox"
              v-for="(item, index) in documentList"
              :key="index"
            >
              <div class="leftBox" @click="go(item.id)">
                <div class="day">{{ item.day }}</div>
                <div class="times">{{ item.date }}</div>
              </div>
              <div class="rightBox" @click="go(item.id)">
                <div class="rightBoxhead">{{ item.title }}</div>
                <div class="rightBoxfoot">
                  查看详情
                  <img src="../img/all_icon_arrow_grey.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 在线客服 暂时隐藏-->
    <div class="agent" onClick="__egeelTalk()">
      <img src="../img/index/icon_service@2x.png" />
      <span>在线咨询</span>
    </div>
    <ContactDialog :visible="contactVisible" @cancel="contactVisible = false" />
    <DetailDialog
      :visible="newsVisible"
      :newsData="newsDetail"
      @cancel="newsVisible = false"
    />
  </div>
</template>
<script>
import { isElementNotInViewport } from "../common/utils.js";
import API from "@/api/api_surpport";
import ContactDialog from "./children/ContactDialog.vue";
import DetailDialog from "./children/DetailsDialog.vue";
import image1 from "@/img/index/logo1.png";
import image2 from "@/img/index/logo2.png";
import image3 from "@/img/index/logo3.png";
import image4 from "@/img/index/logo4.png";
import image5 from "@/img/index/logo5.png";
import image6 from "@/img/index/logo6.png";
import image7 from "@/img/index/logo7.png";
import image8 from "@/img/index/logo8.png";
import image9 from "@/img/index/logo9.png";
import image10 from "@/img/index/logo10.png";
import image11 from "@/img/index/logo11.png";
import image12 from "@/img/index/logo12.png";
export default {
  name: "App",
  components: {
    ContactDialog,
    DetailDialog,
  },
  data() {
    return {
      newsVisible: false,
      newsDetail: {},
      contactVisible: false,
      teps: 1,
      options: [
        {
          value: "1",
          label: "智慧服务",
        },
        {
          value: "2",
          label: "一站式智慧站点",
        },
      ],
      imgArr: [
        {
          image: image1,
        },
        {
          image: image2,
        },
        {
          image: image3,
        },
        {
          image: image4,
        },
        {
          image: image5,
        },
        {
          image: image6,
        },
        {
          image: image7,
        },
        {
          image: image8,
        },
        {
          image: image9,
        },
        {
          image: image10,
        },
        {
          image: image11,
        },
        {
          image: image12,
        },
      ],
      docPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      documentList: [],
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      Animate5: false,
      Animate6: false,
    };
  },
  created() {
    var that = this;
    that.getResourcesList();
    that.test();
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.animateBox);
      this.Animate2 = !isElementNotInViewport(this.$refs.tepsBox);
      this.Animate3 = !isElementNotInViewport(this.$refs.platContBox);
      this.Animate4 = !isElementNotInViewport(this.$refs.partnerBox);
      this.Animate5 = !isElementNotInViewport(this.$refs.partcontBox);
      this.Animate6 = !isElementNotInViewport(this.$refs.newsBox);
    },

    test() {
      document
        .getElementsByTagName("head")[0]
        .appendChild(document.createElement("script")).src =
        (location.protocol.toLocaleLowerCase() == "https:"
          ? "https:"
          : "http:") + "//v.fmkefu.com/cs/js?u=4482_4535&c=auto";
    },
    getResourcesList() {
      var params = {
        con: {
          content: "",
          id: 0,
          title: "",
        },
        customerCode: "",
        customerSite: "",
        pageNum: this.docPage.pageNum,
        pageSize: 4,
      };
      API.newsList(params).then((res) => {
        if (res.code == "0000") {
          var templist = res.data.rows;
          templist.forEach((item) => {
            // item.updatedDate = item.updatedDate.replace(/-/g, "/").slice(0, 10);
            // item.date = item.updatedDate.slice(0, 7);
            // item.day = item.updatedDate.slice(8, 10);
            // pushDate
            item.pushDate = item.pushDate.replace(/-/g, "/").slice(0, 10);
            item.date = item.pushDate.slice(0, 7);
            item.day = item.pushDate.slice(8, 10);
          });
          // var templist = res.data.rows || [];
          this.documentList = templist;
          this.docPage.total = res.data.total;
        }
      });
    },
    // 详情
    go(row_id) {
      var that = this;
      API.newsDetail({ id: row_id }).then((res) => {
        if (res.code == "0000") {
          this.newsDetail = res.data;
        }
      });
      that.newsVisible = true;
    },
    // 跳转解决方案
    toProgramme() {
      this.$router.push({ path: "/ProgrammeB" });
    },
    // 跳转新闻资讯
    toNews() {
      this.$router.push({ path: "/News" });
    },
    // 在线客服
    showAgent() {
      var that = this;
      that.contactVisible = true;
    },
    // 平台右边内容切换
    showContent(index) {
      this.teps = index;
    },
    // 最新新闻-查看详情
    viewDetail() {},
  },
};
</script>

<style lang="scss" scoped="scoped">
// 过渡动画
.myanimation {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.myanimation1 {
  animation: load1 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load1 {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 步骤
// 最新新闻
.myanimation6 {
  animation: load6 0.6s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load6 {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 20px;
  bottom: 10%;
  z-index: 999;
  top: 465px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  height: 140px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  cursor: pointer;

  img {
    width: 41px;
    height: 41px;
  }
  span {
    width: 16px;
    line-height: 1.1;
  }
}
.box {
  padding-bottom: 100px;
  width: 100%;
  /* border: 1px solid red; */
}
.banner {
  width: 1903px;
  height: 620px;
  // padding-top: 70px;
  // background: url(../img/index/index_banner@2x.png) no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  /* height: 100%; */
  // width: 660px;
  // padding: 0px 0 0 360px;
  width: 63%;
  margin: 0 auto;
  /* border: 1px solid red; */
  margin-top: -416px;
}
.bannerTitle {
  font-size: 48px;
  font-family: Source Han Sans SC;
  font-weight: 800;
  color: #101635;
}
.keyTxt {
  color: #3e68ff;
}
.bannerFront {
  /* width: 410px;
  height: 18px; */
  width: 54%;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #101635;
  padding: 26px 15px 58px 0;
  line-height: 1.7;
}

.title1 {
  font-size: 32px;
  font-family: DOUYU;
  font-weight: normal;
  color: #ffffff;
  margin: 168px 0 0 360px;
}

.title2 {
  margin: 22px 0 0 359px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #ffffff;
}

.contentbox {
  width: 100%;
  height: 476px;
}
.banner-btn {
  z-index: 999;
  position: absolute;
  text-align: center;
}
.btn {
  width: 144px;
  height: 48px;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);

  border-radius: 24px;
  margin: 59px 0 0 360px;
}

.dian {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  float: left;
  margin: 20px 0 0 20px;
}

.font {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  margin-right: 60px;
}

.More {
  margin-left: 51px;
}

.contentTitle {
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
  padding-top: 98px;
  /* margin-top: 98px; */
}

.contentTitleS {
  font-size: 18px;
  font-family: JI-Sludge !important;
  font-weight: 400;
  color: #8f94b2;
  margin-top: 12px;
}

.contentBox {
  margin-top: 91px;
  display: flex;
  flex-wrap: wrap;
}

.contentBoxS {
  height: 170px;
  width: 210px;
  margin: 0 50px 0 50px;
}

.contentBoxSs {
  margin-left: 20%;
  display: flex;
}
.contentbox1 {
  text-align: center;
  height: 780px;
  background: #f9faff;
  /* border-bottom: 1px solid #e1e7ff; */
}

.contenttitle {
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 44px;
}

.contentDetails {
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 30px;
  text-align: left;
}
/* 可提供的服务 */
.pro-advantage {
  width: 63%;
  // padding: 0 360px;
  margin: 0 auto;
}
.monitor {
  margin-top: 61px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
// TODO 展示
.monitor-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  padding-top: 25px;
  width: 49.1%;
  // width: 580px;
  height: 220px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 2px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    4px 7px 20px 0px rgba(55, 99, 170, 0.1);
  border-radius: 4px;
}

.contentTop {
  display: flex;
  justify-content: space-between;
  // padding: 0 5px 13px;
  padding-bottom: 13px;
  height: 55px;
  line-height: 55px;
}
.contentTop .title {
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
}
.contentTop img {
  width: 55px;
  height: 55px;
}
.monitor-time {
  /* height: 48px; */
  font-size: 14px;
  line-height: 1.8;
  color: #8f94b2;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}
/* teps步骤区域 */
.teps {
  padding: 0 378px;
  // width: 54%;
  height: 0;

  margin: 80px auto;
  margin-top: 48px;
  display: flex;
  justify-content: space-around;
  padding-bottom: 30px;
}

.tepsBox {
  height: 104px;
  width: 75px;
  /* border: 1px solid red; */
  text-align: center;
  cursor: pointer;
}

.tepsImgs {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}

.tepsImgs img {
  position: relative;
  top: 14px;
}

.tepsName {
  width: 73px;
  height: 17px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
}
.choiceImg {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: #fafbff;
  box-shadow: 0px 10px 20px 0px rgba(184, 188, 204, 0.3);
  border-radius: 50%;
}

.choiceImg img {
  position: relative;
  top: 14px;
}

.line {
  background-color: #3e68ff;
  height: 2px;
  width: 100px;
  margin: 35px 23px 0px 23px;
}

.lineS {
  background-color: #e6ebff;
  height: 2px;
  width: 100px;
  margin: 35px 23px 0px 23px;
}
.tepsFootBox {
  height: 24px;
  width: 54.5%;
  border: 1px solid #f5f7ff;
  border-top: 0;
  margin: 15px auto;
  border-radius: 1px;
  /* border-left: 1px solid #f5f7ff;
  border-bottom: 1px solid #f5f7ff;
  border-right: 1px solid #f5f7ff; */
  /* border: 1px solid red; */
}

.cloudBox {
  // TODO 居中
  padding: 0 430px;
  // width: 55%;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  // margin: 80px auto;
  justify-content: space-between;
}

.cloudLeft {
  width: 280px;
  height: 280px;
  /* margin-left: 10%; */
}

.cloudLeft img {
  width: 100%;
  height: 100%;
}

.cloudRight {
  /* border: 1px solid; */
  width: 543px;
  // height: 176px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 32px;
  /* margin-left: 179px; */
}

.cooperationBanner {
  display: flex;
  width: 100%;
  height: 500px;
  background-image: url(../img/index_part3_logos_png.png);
}

.cooperationBox {
  width: 524px;
  height: 284px;
  background: #f9faff;
  /* border: 1px solid red; */
  text-align: center;
  margin: 108px auto;
  /* 不透明度 */
  opacity: 0.8;
}

.cooperationTitle {
  margin-top: 94px;
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  /* line-height: 44px; */
}

.cooperationTitleEnglish {
  margin-top: 13px;
  font-size: 14px;
  font-family: JI-Sludge !important;
  font-weight: 600;
  color: #8f94b3;
  /* line-height: 44px; */
}

.cooperationFoot {
  margin-top: 29px;
  font-size: 12px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  /* line-height: 44px; */
}

.newsBox {
  // padding: 0 360px;
  /* border: 1px solid red; */
  display: flex;

  width: 63%;
  margin: 100px auto;
}

.newsLeft {
  /* border: 1px solid; */
  width: 380px;
  height: 390px;
  background-color: #f5f7fa;
}

.newsLeftFoot {
  text-align: center;
  /* color: #3D4566; */
}

.newsTitle {
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
}

.newsTitleEnglish {
  font-size: 18px;
  font-family: JI-Sludge !important;
  font-weight: 400;
  color: #8f94b2;
  line-height: 44px;
}

.btn {
  width: 144px;
  height: 48px;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 24px;
  margin: 24px auto;
  cursor: pointer;
}
// TODO123
.btn:hover {
  // transition: 0.8s linear;

  // box-shadow: none;
  // background: #fff !important;
  background: linear-gradient(
    90deg,
    rgb(243, 246, 255) 0%,
    rgb(255, 255, 255) 100%
  ) !important;
  // background-image: -ms-linear-gradient(
  //   90deg,
  //   rgb(243, 246, 255) 0%,
  //   rgb(255, 255, 255) 100% !important
  // );
  box-shadow: 0px 3px 4px 0px rgba(224, 224, 224, 0.3) !important;

  .font {
    color: #3e68ff !important;
  }
  .dian {
    background: #3e68ff !important;
  }
  // background: red;
}
.dian {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  float: left;
  margin: 20px 0 0 20px;
}

.font {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  margin-right: 17px;
}

.newsRight {
  width: 790px;
  height: 390px;
  /* border: 1px solid; */
  margin-left: 30px;
  margin-bottom: 27px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
// TODO 展示
.newsRightBox {
  // width: 380px;
  width: 48%;
  height: 180px;
  background: #ffffff;
  border: 1px solid #e6ebfa;
  display: flex;
  margin-bottom: 27px;
  cursor: pointer;
  // box-shadow: 4px 7px 20px 0px rgba(255, 199, 170, 0.9);
}
// TODO123
.newsRightBox:hover {
  // box-shadow: 4px 7px 20px 0px rgba(55, 99, 170, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(184, 190, 204, 0.16);
  animation: load 0.4s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.leftBox {
  width: 80px;
  height: 96px;
  background: #f9faff;
  text-align: center;
  margin-top: 30px;
  margin-left: 31px;
}

.day {
  font-size: 24px;
  font-family: Mont;
  font-weight: normal;
  color: #3d4566;
  margin-top: 30px;
}

.times {
  font-size: 14px;
  font-family: Mont;
  font-weight: normal;
  color: #8f94b2;
}

.rightBox {
  /* border: 1px solid red; */
  width: 218px;
  height: 108px;
  margin-left: 19px;
  margin-top: 40px;
  cursor: pointer;
}

.rightBoxhead {
  font-size: 16px;
  /* height: ; */
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 30px;
}

.rightBoxfoot {
  // margin-top: 40px;
  margin-top: 15px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #8f94b2;
  line-height: 30px;
  cursor: pointer;
}

.rightBoxfoot img {
  margin-top: 10px;
  margin-left: 20px;
}
.contentboxHeight {
  height: 460px;
}
/* 合作伙伴 */
.partnerBox {
  background-image: url("~@/img/index/index_deco_pt.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.pro-advantage {
  // padding: 0 360px;
  width: 63%;
  margin: 0 auto;
}
.img-area {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 47px;
  .img-box {
    box-sizing: border-box;
    width: 285px;
    height: 120px;
    background: #ffffff;
    border: 2px solid #ffffff;
    box-shadow: 4px 7px 20px 0px rgba(55, 99, 170, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 479px) {
  .banner {
    width: 160%;
    height: 200px;
    position: relative;
  }
  .banner {
    width: 230%;
    height: 160px;
  }
  // .banner img{
  //   width: 70%;
  // }
  .bannerBox {
    height: 100%;
    padding: 0px 0 0 20px;
    margin-top: -120px;
  }
  .banner img {
    width: 100%;
    height: 100%;
    position: absolute;
    /* clip: rect( 0 500px 0 500px); */
  }
  .contentTitle {
    font-size: 15px;
    text-align: center;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    line-height: 44px;
    margin-top: 24px;
  }
  .contentBox {
    width: 100%;
    height: 390px;
    margin-top: 24px;
  }
  .contentBoxS {
    height: 85px;
    width: 100%;
    margin: 0 25px 0 25px;
  }
  .contentBoxSs {
    margin: 0 5% 0;
  }
  .contentbox1 {
    width: 100%;
    /* text-align: center; */
  }
  .contenttitle {
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 600;
    color: #3d4566;
    line-height: 44px;
  }
  .contentDetails {
    font-size: 12px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    line-height: 28px;
    text-align: center;
  }
  .teps {
    width: 100%;
    height: 0;
    margin: 59px auto;
    display: flex;
  }
  .tepsBox {
    height: 52px;
    width: 52px;
    /* border: 1px solid red; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tepsBox:first-child {
    margin-left: 10px;
  }
  .tepsImgs {
    height: 52px;
    width: 52px;
    border-radius: 50%;
  }

  .tepsImgs img {
    position: relative;
    top: 5px;
  }
  .choiceImg {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #fafbff;
    box-shadow: 0px 10px 20px 0px rgba(184, 1, 204, 0.3);
    border-radius: 50%;
  }

  .choiceImg img {
    position: relative;
    top: 5px;
  }

  .tepsName {
    width: 73px;
    height: 44px;
    font-size: 15px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    line-height: 44px;
  }
  .tepsFootBox {
    width: 160%;
    margin: 15px 0 0 30%;
  }

  /* 云 拓扑平台区域 */
  .cloudBox {
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    margin: 24px auto;
    /* 分配弹性元素方式 均匀排列每个元素，首：起点 尾：终点 */
    justify-content: space-between;
  }
  .cloudLeft {
    width: 150px;
    height: 100px;
    /* margin-left: 10%; */
  }

  .cloudRight {
    /* border: 1px solid; */
    width: 543px;
    height: 200px;
    font-size: 12px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    line-height: 32px;
    margin-bottom: 20px;
  }
  /* 底部合作banner区域 */
  .cooperationBanner {
    width: 100%;
    height: 50%;
    /* transform: scale(0.5); */
  }
  /* 最新新闻区域 */
  .newsBox {
    width: 100%;
  }
  .contentbox .contentboxHeight {
  }
  .newsRightBox {
    width: 110px;
    height: 180px;
  }
  .rightBoxfoot {
    margin-top: 5px;
  }
}
</style>
