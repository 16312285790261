<template>
  <div class="box">
    <div class="banner">
      <div class="bannerBox">
        <div class="bannerHead">
          <div class="fwzd">服务站点</div>
          <div class="fwzdEnglish">Service network</div>
        </div>
        <div class="map">
          <!-- <div class="mapLeft"> -->
          <Map></Map>
          <!-- </div> -->
        </div>
      </div>

      <!-- <img src="../img/banner.png" > -->
    </div>
    <!-- 地区 -->
    <div class="pro-advantage pro-area">
      <p>全部(6)</p>
      <div class="swiper-container swiper-area">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="area1">
              <div class="item">
                <div class="img">
                  <img src="@/img/images/about_icon1@2x.png" />
                </div>

                <div class="contentRight">
                  <div class="title">重庆</div>
                  <div class="monitor-time">
                    渝北区黄山大道中段53号双鱼座A座11层1110号
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="img">
                  <img src="@/img/images/about_icon1@2x.png" />
                </div>

                <div class="contentRight">
                  <div class="title">四川</div>
                  <div class="monitor-time">
                    成都市武侯区磨子桥街7号10层18号
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="img">
                  <img src="@/img/images/about_icon1@2x.png" />
                </div>

                <div class="contentRight">
                  <div class="title">湖南</div>
                  <div class="monitor-time">
                    长沙芙蓉区朝阳街道车站中路21号鸿运.凯旋国际
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="area1">
              <div class="item">
                <div class="img">
                  <img src="@/img/images/about_icon1@2x.png" />
                </div>

                <div class="contentRight">
                  <div class="title">广东</div>
                  <div class="monitor-time">
                    福田区深南中路3003号北方大厦1018室
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="img">
                  <img src="@/img/images/about_icon1@2x.png" />
                </div>

                <div class="contentRight">
                  <div class="title">北京</div>
                  <div class="monitor-time">
                    北京市海淀区大钟寺13号院1号楼4层
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="img">
                  <img src="@/img/images/about_icon1@2x.png" />
                </div>

                <div class="contentRight">
                  <div class="title">上海</div>
                  <div class="monitor-time">上海市松江区新浜镇新绿路398号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <!-- <div class="areas">
        <div class="area1">
          <div class="item">
            <div class="img"><img src="@/img/images/about_icon1@2x.png" /></div>

            <div class="contentRight">
              <div class="title">重庆</div>
              <div class="monitor-time">
                渝北区黄山大道中段53号双鱼座A座11层1110号
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img"><img src="@/img/images/about_icon1@2x.png" /></div>

            <div class="contentRight">
              <div class="title">四川</div>
              <div class="monitor-time">成都市武侯区磨子桥街7号10层18号</div>
            </div>
          </div>
          <div class="item">
            <div class="img"><img src="@/img/images/about_icon1@2x.png" /></div>

            <div class="contentRight">
              <div class="title">湖南</div>
              <div class="monitor-time">
                长沙芙蓉区朝阳街道车站中路21号鸿运.凯旋国际
              </div>
            </div>
          </div>
        </div>
        <div class="area1">
          <div class="item">
            <div class="img"><img src="@/img/images/about_icon1@2x.png" /></div>

            <div class="contentRight">
              <div class="title">广东</div>
              <div class="monitor-time">福田区深南中路3003号北方大厦1018室</div>
            </div>
          </div>
          <div class="item">
            <div class="img"><img src="@/img/images/about_icon1@2x.png" /></div>

            <div class="contentRight">
              <div class="title">北京</div>
              <div class="monitor-time">北京市海淀区大钟寺13号院1号楼4层</div>
            </div>
          </div>
          <div class="item">
            <div class="img"><img src="@/img/images/about_icon1@2x.png" /></div>

            <div class="contentRight">
              <div class="title">上海</div>
              <div class="monitor-time">上海市松江区新浜镇新绿路398号</div>
            </div>
          </div>
        </div>
      </div>  -->
    </div>
    <!-- 服务体系/团队介绍 -->
    <div class="contentbox1">
      <div class="contentTitlebox">
        <div class="contentTitle">服务体系</div>
        <div class="contentTitleS contentTitleS1">SERVICE SYSTEM</div>
        <div class="link">
          服务宗旨：让优质的服务更贴近客户
          服务体系：线下7*10现场驻场服务，线上24*365专家全天支持，采用主动运维、应急服务等。
        </div>
      </div>

      <div class="pro-advantage">
        <div
          class=""
          :class="Animate1 ? 'modal myanimation' : 'modal'"
          ref="animateBox"
        >
          <div class="items">
            <div class="title">响应运维</div>
            <div class="content">
              <div style="margin-bottom: 10px">
                在线咨询<span style="margin-left: 15px">服务体系</span>
              </div>
              <div>现场服务<span style="margin-left: 15px">备件服务</span></div>
            </div>
            <img src="@/img/DECOL@2x.png" class="itmesImg" alt="" />
          </div>
          <div class="items none-border">
            <div class="title">主动运维</div>
            <div class="content">
              <div style="margin-bottom: 10px">
                健康检查<span style="margin-left: 15px">驻场服务</span>
              </div>
              <div>事件保障<span style="margin-left: 15px">版本维护</span></div>
            </div>
            <img src="@/img/DECO2LL.png" class="itmesImg" alt="" />
          </div>
          <div class="items none-border">
            <div class="title">应急响应</div>
            <div class="content">
              <div style="margin-bottom: 10px">
                应急响应<span style="margin-left: 15px">安全响应</span>
              </div>
              <div>溯源取证<span style="margin-left: 15px">灾难恢复</span></div>
            </div>
            <img src="@/img/DECO3L@2x.png" class="itmesImg" alt="" />
          </div>
          <div class="items none-border">
            <div class="title">硬件保固</div>
            <div class="content">
              <div style="margin-bottom: 10px">
                设备保养<span style="margin-left: 15px">硬件保养</span>
              </div>
              <div>设备维修</div>
            </div>
            <img src="@/img/DECO4L@2x.png" class="itmesImg" alt="" />
          </div>
        </div>
      </div>
      <div class="contentbox1 contentbox2">
        <div class="contentTitlebox">
          <div class="contentTitle">团队介绍</div>
          <div class="contentTitleS contentTitleS2">OUR TEAM</div>
        </div>
        <div
          class=""
          :class="Animate2 ? 'list1 myanimation' : 'list'"
          ref="teamBox"
        >
          <!-- 跑马灯效果 -->
          <!-- <el-carousel
            :interval="4000"
            type="card"
            height="300px"
            :autoplay="false"
          >
            <el-carousel-item>
              <div class="listItem">
                <div class="header">
                  <div class="pepole">CT网络安全</div>
                  <div class="num">40+</div>
                </div>
                <div class="personnel">
                  <div class="perList">
                    <div class="item">
                      <div class="title">CCIE/HCIE/H3CTE</div>
                      <div class="perNum">10+</div>
                    </div>
                    <div class="item">
                      <div class="title">CCNP/HCIP/H3CSE</div>
                      <div class="perNum">30+</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="listItem">
                <div class="header">
                  <div class="pepole">IT服务存储系统</div>
                  <div class="num">40+</div>
                </div>
                <div class="personnel">
                  <div class="perList">
                    <div class="item">
                      <div class="title">Linux/Unix RHCE及以上</div>
                      <div class="perNum">20+</div>
                    </div>
                    <div class="item">
                      <div class="title">Linux/Unix AIX认证工程师</div>
                      <div class="perNum">3</div>
                    </div>
                    <div class="item">
                      <div class="title">虚拟化云平台华为/vMware/Redhat</div>
                      <div class="perNum">10+</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="listItem">
                <div class="header">
                  <div class="pepole">DT数据技术</div>
                  <div class="num">10+</div>
                </div>
                <div class="personnel">
                  <div class="perList">
                    <div class="item">
                      <div class="title">Oracle OCP以上</div>
                      <div class="perNum">6</div>
                    </div>
                    <div class="item">
                      <div class="title">InterSystems</div>
                      <div class="perNum">2</div>
                    </div>
                    <div class="item">
                      <div class="title">SQL类</div>
                      <div class="perNum">10</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="listItem">
                <div class="header">
                  <div class="pepole">RD研发工程师</div>
                  <div class="num">26+</div>
                </div>
                <div class="personnel">
                  <div class="perList">
                    <div class="item">
                      <div class="title">后端开发</div>
                      <div class="perNum">20+</div>
                    </div>
                    <div class="item">
                      <div class="title">前端</div>
                      <div class="perNum">6+</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel> -->
        </div>
        <div
          class=""
          :class="Animate2 ? 'list myanimation' : 'list'"
          ref="teamBox"
        >
          <div class="swiper-container swiper-list">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="listItem">
                  <div class="header">
                    <div class="pepole">CT网络安全</div>
                    <div class="num">40+</div>
                  </div>
                  <div class="personnel">
                    <div class="perList">
                      <div class="item">
                        <div class="title">CCIE/HCIE/H3CTE</div>
                        <div class="perNum">10+</div>
                      </div>
                      <div class="item">
                        <div class="title">CCNP/HCIP/H3CSE</div>
                        <div class="perNum">30+</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="listItem">
                  <div class="header">
                    <div class="pepole">IT服务存储系统</div>
                    <div class="num">40+</div>
                  </div>
                  <div class="personnel">
                    <div class="perList">
                      <div class="item">
                        <div class="title">Linux/Unix RHCE及以上</div>
                        <div class="perNum">20+</div>
                      </div>
                      <div class="item">
                        <div class="title">Linux/Unix AIX认证工程师</div>
                        <div class="perNum">3</div>
                      </div>
                      <div class="item">
                        <div class="title">虚拟化云平台华为/vMware/Redhat</div>
                        <div class="perNum">10+</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="listItem">
                  <div class="header">
                    <div class="pepole">DT数据技术</div>
                    <div class="num">10+</div>
                  </div>
                  <div class="personnel">
                    <div class="perList">
                      <div class="item">
                        <div class="title">Oracle OCP以上</div>
                        <div class="perNum">6</div>
                      </div>
                      <div class="item">
                        <div class="title">InterSystems</div>
                        <div class="perNum">2</div>
                      </div>
                      <div class="item">
                        <div class="title">SQL类</div>
                        <div class="perNum">10</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="listItem">
                  <div class="header">
                    <div class="pepole">RD研发工程师</div>
                    <div class="num">26+</div>
                  </div>
                  <div class="personnel">
                    <div class="perList">
                      <div class="item">
                        <div class="title">后端开发</div>
                        <div class="perNum">20+</div>
                      </div>
                      <div class="item">
                        <div class="title">前端</div>
                        <div class="perNum">6+</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <webFoot></webFoot> -->
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" @click="concatUs">
      <img src="@/img/index/icon_service@2x.png" />
      在线咨询
    </div>
  </div>
</template>

<script>
import Swiper from "@/static/js/swiper.js";
import "@/assets/swiper.css";
import { isElementNotInViewport } from "./common/utils.js";
import Map from "./components/MapW.vue";
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import myTab from "@/pages/mobile/components/myTab.vue";
// import webFoot from "@/pages/mobile/components/WebFoot.vue";
export default {
  components: {
    Swiper,
    // swiper,
    // swiperSlide,
    Map,
    // myTab,
    // webFoot,
  },
  data() {
    return {
      arr: ["1", "2", "3"],
      Animate1: false,
      Animate2: false,
      Animate3: false,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 25,
        loop: false,
        autoplay: false,
        // {
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        // 显示分页
        pagination: ".swiper-pagination",
        // pagination:
        // {
        //   el: ".swiper-pagination",
        //   clickable: true, //点击切换轮播
        //   // bulletClass: "my-bullet", //自定义分页器样式
        //   // bulletActiveClass: "my-bullet-active", //自定义当前选中样式
        //   // clickable: false, //允许分页点击跳转
        //   // observer: true, //开启动态检查器，监测swiper和slide
        //   // observeParents: true, //监测Swiper 的祖/父元素
        // },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },

  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    var mySwiper = new Swiper(".swiper-list", {
      loop: true,
      centeredSlides: true,
      slidesPerView: "auto",
      initialSlide: 1,
      pagination: {
        el: ".swiper-pagination",
      },
    });
    var mySwiper2 = new Swiper(".swiper-area", {
      loop: true,
      // centeredSlides: true,
      slidesPerView: "auto",
      // initialSlide: 1,
      // slidesPerGroup : 2,
      // slidesOffsetBefore : 10,
      // slidesOffsetAfter : -20000,
      spaceBetween: -21.5,
      // initialSlide: 2,
      pagination: {
        el: ".swiper-pagination",
      },
    });
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    concatUs() {
      window.open("https://v.egeel.vip/cs/c/46v4xpx6", "_blank");
      setTimeout(() => {
        $('img[type="talk"]').parent().parent().css("z-index", "-1000");
      }, 1000);
    },
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.animateBox);
      this.Animate2 = !isElementNotInViewport(this.$refs.teamBox);
      this.Animate3 = !isElementNotInViewport(this.$refs.titleBox);
    },
  },
  // components: {
  //   Map,
  // },
};
</script>

<style lang="scss" scoped="scoped">
//modal
.myanimation {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.myanimation1 {
  animation: load1 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load1 {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 2rem;
  bottom: 10%;
  z-index: 999;
  bottom: 78px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 46px;
  height: 120px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  line-height: 1.1;
  text-align: center;
  cursor: pointer;

  img {
    width: 31px;
    height: 31px;
  }
  span {
    width: 12px;
    line-height: 1.1;
  }
}
.box {
  width: 100%;
  // padding-bottom: 124px;
  background: #fbfcff;
  padding-top: 10rem;
  /* border: 1px solid #101635; */
}
.banner {
  // width: 1903px;
  // height: 700px;
  // padding-top: 70px;
  background: linear-gradient(180deg, #4971ff, #335fff);
}
.bannerBox {
  text-align: center;
  margin: 0 auto;
  padding-top: 8.7rem;
}
.bannerHead {
  margin: 0 auto;
  /* border: 1px solid black; */
}
.fwzd {
  font-size: 4.2rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #fefefe;
}
.fwzdEnglish {
  font-size: 2.4rem;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #ffffff;
  line-height: 44px;
}

/* 服务体系/团队介绍 */
.contentbox1 {
  background: #f7f9fd;
  padding: 10rem 0 0;
  // padding: 10rem 0 9.1rem;
  // border: 1px solid red;
}

.pro-advantage {
  box-sizing: border-box;
  width: 91.47%;
  margin: 0 auto;

  // .pro-top {
  //   display: flex;
  //   justify-content: space-between;
  //   text-align: right;
  //   align-items: flex-end;
  //   padding-top: 120px;
  //   .link {
  //     font-size: 14px;
  //     font-family: PingFang HK;
  //     font-weight: 400;
  //     color: #8a8c99;
  //   }
  // }
  .pro-top2 {
    align-items: center;
  }
}
.contentTitlebox {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.contentTitle,
.contentTitle p {
  font-size: 4.4rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
}

.contentTitleS {
  font-family: JI-Sludge !important;
  font-size: 2.4rem;
  font-weight: 400;
  color: #8f94b2;
}
.link {
  width: 79.7%;
  text-align: justify;
  text-align-last: center;
  font-size: 2.4rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3d4566;
  line-height: 1.8;
}
// 地区
.pro-area {
  background: #ffffff;
  box-shadow: 0px 10px 30px 0px rgba(184, 190, 204, 0.2);
  border-radius: 0.4rem;
  padding: 4.8rem 3.2rem;
  // padding-left: 3rem;
  transform: translateY(-10.3rem);
  p {
    font-size: 3.6rem;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
    margin-bottom: 4rem;
  }
  .areas {
    margin-top: 3.7rem;
    // display: flex;
  }
  .area1 {
    display: flex;
    flex-direction: column;
    max-width: 56rem;
  }
  .item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border-radius: 1rem;
    padding: 4rem 4.2rem 3.7rem 2.2rem;
    margin-bottom: 2rem;
    height: 19rem;
    .img {
      width: 4.16rem;
      height: 4.5rem;
      margin-right: 2.4rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .contentRight {
      display: flex;
      flex-direction: column;
      // width: 82%;
      text-align: left;
      padding-top: 5px;
    }
    .title {
      font-size: 3rem;
      font-family: PingFang HK;
      font-weight: 500;
      color: #3d4566;
      margin-bottom: 1.1rem;
    }
    .monitor-time {
      font-size: 2.4rem;
      font-family: PingFang HK;
      font-weight: 400;
      color: #3d4566;
      line-height: 1.8;
    }
  }
  .is-active {
    background: transparent !important;
  }
  /deep/.el-carousel__indicator--horizontal {
    //   width: 4.8rem;
    //   height: 0.8rem;
    //   background: #3e68ff !important;
    //   padding: 0 !important;
    //   color: #335fff;
  }
  /deep/.el-carousel__item:nth-child(2n),
  .el-carousel__item:nth-child(2n + 1) {
    background-color: transparent !important;
  }
}

// 服务
.contentTitleS1 {
  margin: 2.1rem 0 6rem;
}
.pro-font {
  width: 112px;
  font-size: 28px;
  color: #3d4566;
}
.modal {
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  justify-content: space-between;
  position: relative;
  padding-top: 4.9rem;

  .items {
    box-sizing: border-box;
    // width: 342px;
    // height: 202px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 4.9rem 5.7rem 1.4rem 3.5rem;
    position: relative;
    margin-bottom: 3.3rem;
    .title {
      font-size: 3.6rem;
      font-family: PingFang HK;
      font-weight: 500;
      color: #3d4566;
      margin-bottom: 25px;
    }
    .itmesImg {
      width: 11.4rem;
      height: 10.6rem;
      margin: 9.9rem 0 0 2.5rem;
    }
    .content {
      div {
        font-size: 2.6rem;
        font-family: PingFang HK;
        font-weight: 400;
        color: #8f94b2;
      }
    }
  }
  .none-border {
    border-left: none !important;
  }
}
// 团队
.contentbox2 {
  background: linear-gradient(0deg, #ffffff, #dde7ff);
  padding: 10rem 0 14.3rem;
  // padding: 10rem 0 9.1rem;
}
.contentTitleS2 {
  margin: 2.4rem 0 8.1rem;
}

.list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; //测试
  // TODO
  .listItem {
    margin-bottom: 20px; //测试
    display: flex;
    flex-direction: column;
    min-width: 23rem;
    // width: 280px;
    // min-height: 56rem;
    background: #ffffff;
    border: 1px solid #ebeef5;
    // box-shadow: 0px 10px 30px 0px rgba(143, 162, 179, 0.2);
    .header {
      box-sizing: border-box;
      height: 82px;
      background: #f5f6fa;
      border-bottom: 1px solid #ebeef5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 18px;
      padding-right: 18px;
      .pepole {
        font-size: 3.2rem;
        font-family: PingFang HK;
        font-weight: 500;
        color: #3d4566;
      }
      .num {
        font-size: 3.2rem;
        font-family: Bebas;
        font-weight: 400;
        color: #3d4566;
      }
    }
    .personnel {
      // padding: 20px;
      padding: 17.5px;
      // min-height: 40rem;//云服TODO
      .perList {
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-bottom: 1rem;
          // width: 285px;
          height: 48px;
          background: #f5f6fa;
          .title {
            font-size: 2.8rem;
            font-family: PingFang HK;
            font-weight: 400;
            color: #3d4566;
          }
          .perNum {
            font-size: 2.8rem;
            font-family: PingFang HK;
            font-weight: 400;
            color: #3d4566;
          }
        }
      }
    }
  }
  .choiceItem {
    .header {
      background: url(@/img/mobile/top_bg@3x.png) no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      border-bottom: none;
      .pepole {
        font-size: 3.2rem;
        font-family: PingFangHK;
        font-weight: 500;
        color: #ffffff;
      }
      .num {
        font-size: 3.2rem;
        font-family: Bebas;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .personnel {
      background: linear-gradient(45deg, #1765ff, #5b93ff);
      box-shadow: 0px 1rem 3rem 0px rgba(23, 87, 255, 0.2);
      .perList .item {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #9cbdff;
        .title,
        .perNum {
          font-size: 2.8rem;
          font-family: PingFangHK;
          font-weight: 400;
          color: #f4f6ff;
        }
      }
    }
  }
}
// 区域轮播
.swiper-area {
  width: 100%;
  .swiper-slide {
    width: 87%;
    transform: scale(0.9);
    margin-right: -2rem;
    // transform: scale(0.84);
    // margin-right: -5rem;
  }
  /deep/.swiper-pagination-bullet {
    width: 2rem;
    height: 0.8rem;
    background: #cccccc;
    border-radius: 0 !important;
  }
  /deep/.swiper-pagination-bullet-active {
    width: 4.8rem;
    height: 0.8rem;
    background: #3e68ff !important;
  }
}
// 团队轮播
.swiper-container {
  width: 100%;
  padding-bottom: 3.8rem;
  // border: 1px solid red;
}
.swiper-slide {
  width: 68%;
  transform: scale(0.85);
}

/deep/.swiper-slide-active {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  .listItem {
    .header {
      background: url(@/img/mobile/top_bg@3x.png) no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      border-bottom: none;
      .pepole {
        font-size: 3.2rem;
        font-family: PingFangHK;
        font-weight: 500;
        color: #ffffff;
      }
      .num {
        font-size: 3.2rem;
        font-family: Bebas;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .personnel {
      background: linear-gradient(45deg, #1765ff, #5b93ff);
      box-shadow: 0px 1rem 3rem 0px rgba(23, 87, 255, 0.2);
      .perList .item {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #9cbdff;
        .title,
        .perNum {
          font-size: 2.8rem;
          font-family: PingFangHK;
          font-weight: 400;
          color: #f4f6ff;
        }
      }
    }
  }
}
/deep/.swiper-pagination-bullet {
  background: #d6ecff !important;
  opacity: 1;
}
/deep/.swiper-pagination-bullet-active {
  background: #4070f1 !important;
}

/deep/.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

/deep/.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

/deep/.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
// .list1 {
//   // display: flex;
//   // justify-content: space-between;
//   // align-items: center;
//   .listItem {
//     display: flex;
//     flex-direction: column;
//     min-width: 230px;
//     // width: 280px;
//     // height: 291px;
//     background: #ffffff;
//     // border: 1px solid #ebeef5;
//     // box-shadow: 0px 10px 30px 0px rgba(143, 162, 179, 0.2);
//     .header {
//       box-sizing: border-box;
//       height: 82px;
//       background: #f5f6fa;
//       border-bottom: 1px solid #ebeef5;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       padding-left: 18px;
//       padding-right: 18px;
//       .pepole {
//         font-size: 18px;
//         font-family: PingFang HK;
//         font-weight: 500;
//         color: #3d4566;
//       }
//       .num {
//         font-size: 20px;
//         font-family: Bebas;
//         font-weight: 400;
//         color: #3d4566;
//       }
//     }
//     .personnel {
//       // padding: 20px;
//       padding: 17.5px;
//       .perList {
//         display: flex;
//         flex-direction: column;
//         .item {
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           padding-left: 2rem;
//           padding-right: 2rem;
//           margin-bottom: 1rem;
//           // width: 285px;
//           height: 48px;
//           background: #f5f6fa;
//           .title {
//             font-size: 13px;
//             font-family: PingFang HK;
//             font-weight: 400;
//             color: #3d4566;
//           }
//           .perNum {
//             font-size: 14px;
//             font-family: PingFang HK;
//             font-weight: 400;
//             color: #3d4566;
//           }
//         }
//       }
//     }
//   }
// }
.map {
  /* border: 1px solid red; */
}
.mapLeft {
  // width: 500px;
  // height: 540px;
}
</style>
