import Vue from "vue";
import VueRouter from "vue-router";
// 手机端
import HomeM from '@/pages/mobile/Home.vue'
import YuServiceM from '@/pages/mobile/YuService.vue'
import ProgrammeM from '@/pages/mobile/SchemeList.vue'
import ProgrammeBM from '@/pages/mobile/SchemeBList.vue'
import SupportM from '@/pages/mobile/SupportList.vue'
import NewsM from '@/pages/mobile/News.vue'
import AboutM from '@/pages/mobile/AboutUs.vue'
import ServicesM from '@/pages/mobile/Services.vue'
import NewsDetailsM from '@/pages/mobile/children/NewsDetailsM.vue'
import PrivacyPolicyM from '@/pages/mobile/PrivacyPolicy' //智慧运维
import PrivacyPolicyMQ from '@/pages/mobile/PrivacyPolicyQ' //全栈云服

// pc端
import Home from '@/pages/Home.vue'
import YuService from '@/pages/YuService.vue'
import Expert from '@/pages/children/ExpertDetails.vue'
import Programme from '@/pages/SchemeList.vue'
import ProgrammeB from '@/pages/SchemeBList.vue'
import SchemeDetails from '@/pages/children/SchemeDetails.vue'
import Support from '@/pages/SupportList.vue'
import SupportDetails from '@/pages/children/SupportDetails.vue'
import News from '@/pages/News.vue'
import About from '@/pages/AboutUs.vue'
import Services from '@/pages/Services.vue'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import PrivacyPolicyQ from '@/pages/PrivacyPolicyQ'
const RouterView = resolve => require(["@/components/RouterView"], resolve);
Vue.use(VueRouter);
// const routerPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//     return routerPush.call(this, location).catch(error => error)
// }
// let router = new VueRouter({
//         mode: "history",
//         routes: [{
//                 path: "/home",
//                 name: "home",
//                 component: home
//             },
//             {
//                 path: '/YuService',
//                 component: YuService,
//                 name: 'YuService'
//             },
//         ]
//     })
// PC端
const routerPC = [{
            path: '/',
            redirect: "/Home",
            component: Home,
            name: 'Home'
        },
        {
            path: "/Home",
            component: Home,
            name: 'Home'
        },
        {
            path: '/YuService',
            component: YuService,
            name: 'YuService'
        },
        {
            path: '/Expert',
            component: Expert,
            name: 'Expert'
        },
        {
            path: "/routerview",
            name: "解决方案",
            component: RouterView,
            children: [{
                    path: '/Programme',
                    component: Programme,
                    name: 'Programme'
                },
                {
                    path: '/ProgrammeB',
                    component: ProgrammeB,
                    name: 'ProgrammeB'
                },
            ]
        },

        {
            path: "/SchemeDetails",
            component: SchemeDetails,
            name: 'SchemeDetails'
        },
        {
            path: '/Support',
            component: Support,
            name: 'Support'
        },
        {
            path: '/SupportDetails',
            component: SupportDetails,
            name: 'SupportDetails'
        },
        {
            path: '/News',
            component: News,
            name: 'News'
        },
        {
            path: '/About',
            component: About,
            name: 'About'
        },
        {
            path: '/Services',
            component: Services,
            name: 'Services'
        },
        {
            path: '/PrivacyPolicy',
            component: PrivacyPolicy,
            name: 'PrivacyPolicy'
        },
        {
            path: '/PrivacyPolicyQ',
            component: PrivacyPolicyQ,
            name: 'PrivacyPolicyQ'
        }
    ]
    // 手机端
const routerM = [{
        path: '/m',
        redirect: "/HomeM",
        component: HomeM,
        name: 'HomeM'
    },
    {
        path: "/HomeM",
        component: HomeM,
        name: 'HomeM'
    },
    {
        path: "/YuServiceM",
        component: YuServiceM,
        name: 'YuServiceM'
    },
    {
        path: '/ProgrammeBM',
        component: ProgrammeBM,
        name: 'ProgrammeBM'
    },
    {
        path: '/ProgrammeM',
        component: ProgrammeM,
        name: 'ProgrammeM'
    },
    {
        path: '/SupportM',
        component: SupportM,
        name: 'SupportM'
    },
    {
        path: '/NewsM',
        component: NewsM,
        name: 'NewsM'
    },
    {
        path: '/NewsDetailsM',
        component: NewsDetailsM,
        name: 'NewsDetailsM'
    },
    {
        path: '/AboutM',
        component: AboutM,
        name: 'AboutM'
    },
    {
        path: '/ServicesM',
        component: ServicesM,
        name: 'ServicesM'
    },
    {
        path: '/PrivacyPolicyM',
        component: PrivacyPolicyM,
        name: 'PrivacyPolicyM'
    },
    {
        path: '/PrivacyPolicyMQ',
        component: PrivacyPolicyMQ,
        name: 'PrivacyPolicyMQ'
    }
]

var routes = []
let Adaptive = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
if (navigator.userAgent.match(Adaptive)) {
    routes = routerM
        // a = true
        // localStorage.setItem('a',true)
} else {
    routes = routerPC
        // localStorage.setItem('a',false)
}

// if(localStorage.getItem('a')){
//     window.location.href = ' http://192.168.7.181:8080/HomeM'
// }
// alert(routes, 'routes!!!!')
const router = new VueRouter({
        mode: "hash",
        routes
    })
    // var router = new VueRouter({
    //     routes
    // })
export default router;