<template>
  <div class="whole-wrapper" v-if="showChat">
    <div class="center_whole_wrapper">
      <div class="center_wrapper">
        <!-- 上面的表头  @mousedown="drag" -->
        <div class="center_wrapper_top" id="center_wrapper_top">
          <div class="center_wrapper_top_name">
            {{ `编号为${myKFid}客服` }}
            <p>竭诚为您服务</p>
          </div>
          <span class="close-icon" @click="close">×</span>
        </div>
        <!-- 中间的聊天框，尝试模拟数据 -->
        <div
          class="center_wrapper_center"
          style="overflow-y: auto"
          ref="dialogue_box"
          v-loading="loadingArea"
          :element-loading-text="loadingTxt"
        >
          <!-- <div class="item loading">
            {{ loadText }}<span>{{ "第" + pageNum + "页" }}</span>
          </div> -->
          <div
            v-for="(item, index) in dialogueInfo.dialogueList"
            :key="index"
            :class="
              item.other
                ? center_wrapper_center_item
                : center_wrapper_center_item_reserve
            "
          >
            <div class="left-item-head"><img :src="item.head" /></div>
            <!-- 文字 -->
            <div class="center-item-info_wrapper" v-if="item.text">
              <div class="center-item-time">{{ item.date }}</div>
              <div class="center-item-tip">
                <div class="center-item-info">{{ item.text }}</div>
                <div class="error-tip" v-if="item.send">
                  <!-- <img src="../../../public/img/bg/error.png" /> -->
                  <i class="el-icon-warning"></i>
                </div>
              </div>
            </div>
            <!-- 图片 -->
            <div class="center-item-info_wrapper" v-if="item.img">
              <div class="center-item-time">{{ item.date }}</div>
              <div class="center-item-tip">
                <div class="center-item-info_img">
                  <el-image
                    :src="item.img"
                    :preview-src-list="item.srcList"
                    fit="contain"
                    v-loading="item.loading"
                  >
                  </el-image>
                </div>
                <div class="error-tip" v-if="item.send">
                  <i class="el-icon-warning"></i>
                </div>
              </div>
            </div>
            <!-- <div class="center-item-info_wrapper" v-else>
              <div class="center-item-time">{{ item.date }}</div>
              <div class="center-item-tip">
                <div class="center-item-info_img">
                  <el-image
                    :src="imgUrl"
                    :preview-src-list="[imgUrl]"
                    fit="contain"
                  >
                  </el-image>
                </div>
                <div class="error-tip" v-if="item.send">
                  <i class="el-icon-warning"></i>
                </div>
              </div>
            </div> -->
            <!-- 音频 -->
            <!-- <div class="center-item-info_wrapper" v-if="item.audio">
              <div class="center-item-tip">
                <audio :src="item.audio" controls></audio>
              </div>
              <div class="center-item-time">{{ item.date }}</div>
            </div> -->
            <!-- 视频 -->
            <!-- <div class="center-item-info_wrapper" v-if="item.video">
              <div class="center-item-tip">
                <video :src="item.video" controls></video>
              </div>
              <strong></strong>
              <div class="center-item-time">{{ item.date }}</div>
            </div> -->
          </div>
        </div>
        <!-- 下面的输入框，发送 -->
        <div class="center_wrapper_right">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="textarea"
            class="chat-area"
            @keyup.enter.native="sendTextarea"
          ></el-input>

          <div class="icon-list">
            <!-- 表情 -->
            <!-- <div class="icon-item" title="表情"> -->
            <el-popover
              placement="top"
              width="500"
              trigger="click"
              style="z-index: 10000"
              ref="popover"
            >
              <div
                style="
                  width: 100%;
                  height: 280px;
                  display: flex;
                  flex-wrap: wrap;
                  overflow-y: auto;
                "
              >
                <div
                  class="emoji_icon"
                  style="width: 5%; padding: 10px"
                  v-for="(item, index) in emojiList"
                  :key="index"
                  :title="item.symbol"
                  @click="clickEmoji(item.emoji)"
                >
                  {{ item.emoji }}
                </div>
              </div>
              <el-button slot="reference" style="border: none; padding: 0">
                <i class="iconfont icon-biaoqing"></i>
              </el-button>
            </el-popover>
            <!-- </div> -->
            <!-- 上传文件 upload.qiniup.com -->
            <div class="icon-item">
              <el-upload
                class="avatar-uploader"
                :headers="{
                  'Content-Type': 'multipart/form-data;charset=UTF-8',
                }"
                action="string"
                :show-file-list="false"
                :limit="1"
                ref="uploader"
                :http-request="UploadFiles"
                accept=".jpg,.jpeg,.png"
                :on-change="saveImgUrl"
              >
                <div class="icon-item" title="发送文件">
                  <i class="iconfont icon-pic"></i>
                </div>
              </el-upload>
            </div>
            <!-- 聊天记录 -->
            <!-- <div class="icon-item" title="聊天记录" @click="clickHistory">
              <i class="iconfont icon-wenzi"></i>
            </div> -->
            <div class="btn_primary" @click="sendTextarea">发送</div>
            <!-- 模拟消息提醒弹框 -->
            <!-- <el-button
              plain
              @click="notifyByOrder">
              使用 HTML 片段
            </el-button> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 右边的聊天记录 -->
    <!-- <div class="center_whole_wrapper right_whole_wrapper">
      <div class="center_wrapper right_wrapper" v-if="showHistory">
        <div
          class="center_wrapper_center right_wrapper_center"
          style="overflow-y: auto"
          ref="dialogue_record"
        >
          <div class="item loading">
            {{ loadText1 }}<span>{{ "第" + pageNum1 + "页" }}</span>
          </div>
          <div
            v-for="(item, index) in dialogueInfo.dialogueList"
            :key="index"
            :class="
              isOther
                ? center_wrapper_center_item
                : center_wrapper_center_item_reserve
            "
          >
            <div class="left-item-head"><img :src="item.head" /></div>
    
            <div class="center-item-info_wrapper" v-if="item.text">
              <div class="center-item-name">{{ item.name }}</div>
              <div class="center-item-info">{{ item.text }}</div>
              <div class="center-item-time">{{ item.date }}</div>
            </div>
           
            <div class="center-item-info_wrapper" v-if="item.img">
              <div class="center-item-name">{{ item.name }}</div>
              <div class="center-item-info_img">
                <el-image
                  :src="item.img"
                  :preview-src-list="item.srcList"
                  :fit="contain"
                >
                </el-image>
              </div>
              <div class="center-item-time">{{ item.date }}</div>
            </div>
           
            <div class="center-item-info_wrapper" v-if="item.audio">
              <div class="center-item-name">{{ item.name }}</div>
              <audio :src="item.audio" controls></audio>
              <div class="center-item-time">{{ item.date }}</div>
            </div>
            
            <div class="center-item-info_wrapper" v-if="item.video">
              <div class="center-item-name">{{ item.name }}</div>
              <video :src="item.video" controls></video>
              <div class="center-item-time">{{ item.date }}</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import API from "@/api/api_surpport.js";
import { formatDate11, formatDate } from "@/common/formate.js";
import img1 from "@/img/images/default_man.png";
import img2 from "@/img/images/service_blue.png";

var RongIMLib = window.RongIMLib;
var RongIMClient = RongIMLib.RongIMClient;
var RongIMEmoji = RongIMLib.RongIMEmoji;
export default {
  props: {
    showChat: Boolean,
    required: true,
  },
  data() {
    return {
      chatList: [
        {
          targetId: "",
          head: "",
          name: "",
          info: "",
          time: "",
          phone: "",
          number: "",
        },
      ],
      //存储条件查询时 原来的数组
      findList: [],
      // 搜索联系人
      find: "",
      // 发送文字
      textarea: "",
      // 模拟对话
      dialogueInfo: {
        index: 0,
        hasMsg: true,
        targetId: "", //对方的id
        name: "", //对方的名字
        phone: "", //对方的手机号
        head: img2, //对方的头像
        dialogueList: [
          {
            // 判断是对方
            other: true,
            head: img2,
            text: "你好，很高兴为您服务",
            // img: "http://124.128.226.225:8083/upload/1301812392719626241.png",
            date: formatDate11(new Date()),
            send: false,
            name: "",
          },
        ],
      },
      // 右边的聊天记录，用于判断是对方说的，ture,选择样式1
      isOther: true,
      center_wrapper_center_item: "center_wrapper_center_item",
      center_wrapper_center_item_reserve: "center_wrapper_center_item_reserve",
      // 判断是否显示聊天记录
      showHistory: false,
      // 点击的子项
      targetId: "",
      //我的targetId
      mytargetId: "",
      //我的头像
      myhead: img1,
      //我的名字
      myname: "",
      // 上传文件
      fileList: [],
      imageUrl: "",
      // 新增上滚方法
      scrollHeight: 0,
      list: [],
      loadText: "加载中...",
      pageNum: 1,
      loadText1: "加载中...",
      pageNum1: 1,
      // 模拟图片放大
      url: "http://gudian-qpc.oss-cn-hangzhou.aliyuncs.com/2020/1329338563854811138.png",
      srcList: [
        "http://gudian-qpc.oss-cn-hangzhou.aliyuncs.com/2020/1329338563854811138.png",
      ],
      loading: false,
      // 表情包列表
      emojiList: [],
      myKFid: "",
      myName: "",
      loadingArea: true,
      loadingTxt: "加载中...",
      imgUrl: "",
    };
  },
  created() {
    // this.loadComments();
    //   getToken().then(res => {
    //     var appkey = '82hegw5u81yex'
    //     var token = res.data.data;
    //     this.init(appkey, token)
    //     // 默认是当前对话
    //     this.targetId = this.dialogueInfo.targetId
    //   });
    // this.notifyByOrder();
    // 测试获取token
    // var appkey = "82hegw5u81yex";
    // var token =
    //   "23jtqjFHzsVClI+s7q5kHAj++nJYOGLaaokJFvvPgeY=@rtx9.cn.rongnav.com;rtx9.cn.rongcfg.com";
    // this.init(appkey, token);
  },

  // 将滚动条定位到底部
  updated() {
    // 中间的聊天框
    const dialogue_box = this.$refs.dialogue_box;
    dialogue_box.scrollTop = dialogue_box.scrollHeight;
    // 右边的聊天记录
    if (this.$refs.dialogue_record) {
      const dialogue_record = this.$refs.dialogue_record;
      dialogue_record.scrollTop = dialogue_record.scrollHeight;
      this.handleRightScroll();
    }
  },
  mounted() {
    // this.myname = localStorage.getItem("")
    // this.handleCenterScroll();
  },
  methods: {
    // 获取token
    getToken(id, name) {
      var that = this;
      that.myKFid = id;
      that.dialogueInfo.targetId = id;
      let params = {
        userId: "1",
        userName: "admin",
      };
      that.myname = name + "-" + "admin";
      // that.init(res.data.appKey, res.data.token);
      that.init(
        "82hegw5u81yex",
        "IT7gS7iV8/SgA+YBN+nVy069kwZJPnat@rtx9.cn.rongnav.com;rtx9.cn.rongcfg.com"
      );
      // API.getIMtoken(params).then((res) => {
      //   if (res.data.code !== 200) {
      //     that.myname = name + "-" + "admin";
      //     // that.init(res.data.appKey, res.data.token);
      //     that.init(
      //       "ngnav.com;rtx9.cn.rongcfg.com",
      //       "IT7gS7iV8/SgA+YBN+nVy8AdfAWQZepe@rtx9.cn.rongnav.com;rtx9.cn.rongcfg.com"
      //     );
      //   } else {
      //     that.loadingTxt = "连接失败";
      //     setTimeout(() => {
      //       that.loadingArea = false;
      //     }, 800);
      //   }
      // });
    },
    // // 获取token
    // getToken(id, name) {
    //   const that = this;
    //   that.myKFid = id;
    //   that.dialogueInfo.targetId = id;
    //   let params = {
    //     userId: "1",
    //     userName: "admin",
    //   };
    //   API.getIMtoken(params).then((res) => {
    //     if (res.data.code === 200) {
    //       that.myname = name + "-" + res.data.userName;
    // that.init(res.data.appKey, res.data.token);
    //     } else {
    //       that.loadingTxt = "连接失败";
    //       setTimeout(() => {
    //         that.loadingArea = false;
    //       }, 800);
    //     }
    //   });
    // },
    //初始化个人信息
    loadComments() {
      this.myhead = info.content.avatar;
      this.myname = info.content.nick_name;
    },

    //初始化链接方法
    init: function (appkey, token) {
      var _this = this;
      var params = {
        appkey: appkey,
        token: token,
      };
      RongIMEmoji.init();
      this.emojiList = RongIMLib.RongIMEmoji.list;
      console.log(this.emojiList, "this.emojiListthis.emojiList");
      RongIMClient.init(appkey, null, params);
      RongIMClient.setConnectionStatusListener({
        onChanged: function (status) {
          switch (status) {
            case RongIMLib.ConnectionStatus["CONNECTED"]:
            case 0:
              console.log("连接成功");
              _this.loadingTxt = "连接成功";
              setTimeout(() => {
                _this.loadingArea = false;
              }, 800);
              break;
            case RongIMLib.ConnectionStatus["CONNECTING"]:
            case 1:
              console.log("连接中");
              _this.loadingTxt = "连接中...";
              break;
            case RongIMLib.ConnectionStatus["DISCONNECTED"]:
            case 2:
              console.log("当前用户主动断开链接");
              _this.loadingTxt = "用户主动断开链接";
              setTimeout(() => {
                _this.loadingArea = false;
              }, 800);
              break;
            case RongIMLib.ConnectionStatus["NETWORK_UNAVAILABLE"]:
            case 3:
              console.log("网络不可用");
              _this.loadingTxt = "网络不可用";
              setTimeout(() => {
                _this.loadingArea = false;
              }, 800);
              break;
            case RongIMLib.ConnectionStatus["CONNECTION_CLOSED"]:
            case 4:
              console.log("未知原因，连接关闭");
              break;
            case RongIMLib.ConnectionStatus["KICKED_OFFLINE_BY_OTHER_CLIENT"]:
            case 6:
              console.log("用户账户在其他设备登录，本机被踢掉线");
              break;
            case RongIMLib.ConnectionStatus["DOMAIN_INCORRECT"]:
            case 12:
              console.log("当前运行域名错误，请检查安全域名配置");
              break;
          }
        },
      });
      RongIMClient.setOnReceiveMessageListener({
        // 接收到的消息
        onReceived: function (message) {
          // 判断消息类型
          switch (message.messageType) {
            case RongIMClient.MessageType.TextMessage:
              _this.handleGetTxt(message);
              break;
            case RongIMClient.MessageType.ImageMessage:
              _this.handleGetImg(message);
              break;
            // case RongIMClient.MessageType.VoiceMessage:
            //   _this.handleGetVoice(message);
            //   break;
          }
        },
      });
      RongIMClient.connect(
        token,
        {
          onSuccess: function (userId) {
            console.log("链接成功，id：" + userId);
            _this.mytargetId = userId;
            console.log(_this.myKFid);
            _this.loadingArea = false;
            _this.getDialogueInfo(_this.myKFid, true);
          },
          onTokenIncorrect: function () {
            console.log("token无效");
          },
          onError: function (errorCode) {
            switch (errorCode) {
              case RongIMLib.ErrorCode.TIMEOUT:
                console.log("超时");
                _this.loadingArea = false;
                break;
              case RongIMLib.ConnectionState.UNACCEPTABLE_PAROTOCOL_VERSION:
                console.log("不可接受的协议版本");
                _this.loadingArea = false;
                break;
              case RongIMLib.ConnectionState.IDENTIFIER_REJECTED:
                console.log("appkey不正确");
                break;
              case RongIMLib.ConnectionState.SERVER_UNAVAILABLE:
                console.log("服务器不可用");
                break;
            }
          },
        },
        null
      );
    },

    //处理接收到的文字消息
    handleGetTxt: function (message) {
      console.log(message);
      var info = {
        text: RongIMEmoji.symbolToEmoji(message.content.content.text),
        date: formatDate11(new Date()),
        send: false,
      };
      if (message.senderUserId == this.mytargetId) {
        //我发出去的(比如app端发出去的，pc端会收到)
        info.other = false;
        info.head = this.myhead;
        info.name = this.myname;
        // this.chatList[this.dialogueInfo.index].info = RongIMEmoji.symbolToEmoji(
        //   message.content.content
        // );
        this.dialogueInfo.dialogueList.push(info);
      } else if (message.senderUserId == this.dialogueInfo.targetId) {
        //对方发出去的
        info.other = true;
        info.head = this.dialogueInfo.head;
        info.name = this.dialogueInfo.name;
        // this.chatList[this.dialogueInfo.index].info = RongIMEmoji.symbolToEmoji(
        //   message.content.content
        // );
        this.dialogueInfo.dialogueList.push(info);
      }
      // else {
      //   //聊天列表其他人发出去的（不是现在聊天的人）
      //   var flag = false;
      //   for (var i = 0; i < this.chatList.length; i++) {
      //     if (this.chatList[i].targetId == message.senderUserId) {
      //       flag = true;
      //       this.chatList[i].info = RongIMEmoji.symbolToEmoji(
      //         message.content.content
      //       );
      //       if (this.chatList[i].number == "") {
      //         this.chatList[i].number = "1";
      //       } else {
      //         this.chatList[i].number = (
      //           parseInt(this.chatList[i].number) + 1
      //         ).toString();
      //       }
      //       break;
      //     }
      //   }
      //   // if (!flag) {
      //   //   this.getChatList(false);
      //   // }
      // }
      // console.log('新消息 ' + message.targetId + ':' + JSON.stringify(message))
    },

    //处理图片方法
    handleGetImg: function (message) {
      var info = {
        srcList: [message.content.imageUri],
        img: message.content.imageUri,
        date: formatDate11(new Date(message.sentTime)),
        send: false,
      };
      if (message.senderUserId == this.mytargetId) {
        //我发出去的(比如app端发出去的，pc端会收到)
        info.other = false;
        info.head = this.myhead;
        info.name = this.myname;
        this.chatList[this.dialogueInfo.index].info = "[图片]";
        this.dialogueInfo.dialogueList.push(info);
      } else if (message.senderUserId == this.dialogueInfo.targetId) {
        //对方发出去的
        info.other = true;
        info.head = this.dialogueInfo.head;
        info.name = this.dialogueInfo.name;
        this.chatList[this.dialogueInfo.index].info = "[图片]";
        this.dialogueInfo.dialogueList.push(info);
      } else {
        //聊天列表其他人发出去的（不是现在聊天的人）
        var flag = false;
        for (var i = 0; i < this.chatList.length; i++) {
          if (this.chatList[i].targetId == message.senderUserId) {
            flag = true;
            this.chatList[i].info = "[图片]";
            if (this.chatList[i].number == "") {
              this.chatList[i].number = "1";
            } else {
              this.chatList[i].number = (
                parseInt(this.chatList[i].number) + 1
              ).toString();
            }
            break;
          }
        }
        // if (!flag) {
        //   this.getChatList(false);
        // }
      }
    },
    // 上传图片
    UploadFiles(files) {
      const formData = new FormData();
      formData.append("files", files.file);
      API.sendImg(formData).then((res) => {
        if (res.resultCode == "0000") {
          // const dataIMG = JSON.parse(res.data);
          // console.log(dataIMG);
          var url = res.data;
          var info = {
            srcList: [url],
            loading: true,
            img: url,
            date: formatDate11(new Date()),
            send: false,
            other: false,
            head: this.myhead,
            name: this.myname,
          };
          this.dialogueInfo.dialogueList.push(info);

          var index = this.dialogueInfo.dialogueList.length;

          var a = 150;
          var base64 = "";
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          var Img = new Image();
          Img.setAttribute("crossOrigin", "anonymous");
          Img.src = url;
          Img.onload = function () {
            var width = "";
            var height = "";
            if (Img.width > Img.height) {
              width = a;
              height = (a * Img.height) / Img.width;
            } else {
              width = (a * Img.width) / Img.height;
              height = a;
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(Img, 0, 0, width, height);
            base64 = canvas.toDataURL("image/jpeg");
          };
          // var msg = new RongIMLib.ImageMessage({
          //   content: base64,
          //   imageUri: url,
          //   name: this.myname,
          // });
          var msg = new RongIMLib.ImageMessage({
            content: {
              name: this.myname,
              base64,
            },
            imageUri: url,
          });
          var conversationType = RongIMLib.ConversationType.PRIVATE; // 单聊, 其他会话选择相应的会话类型即可
          var targetId = this.dialogueInfo.targetId; // 目标 Id
          var _this = this;
          RongIMClient.getInstance().sendMessage(
            conversationType,
            targetId,
            msg,
            {
              onSuccess: function (message) {
                if (_this.dialogueInfo.dialogueList.length >= index) {
                  _this.dialogueInfo.dialogueList[index - 1].loading = false;
                }
              },
              onError: function (errorCode, message) {
                console.log("发送失败:" + info + errorCode);
              },
            }
          );
        }
        this.$refs.uploader.clearFiles();
      });
    },
    //获取左侧聊天列表方法
    getChatList: function (flag) {
      var _this = this;
      RongIMClient.getInstance().getConversationList(
        {
          onSuccess: function (list) {
            //处理一下图片和语音
            console.log(list);
            list.forEach((item) => {
              switch (item.latestMessage.messageType) {
                case "ImageMessage":
                  item.latestMessage.content.content = "[图片]";
                  break;
                case "HQVoiceMessage":
                  item.latestMessage.content.content = "[语音]";
                  break;
              }
            });
            getChatList(JSON.stringify(list)).then((res) => {
              var chatList = res.data.data;
              chatList.forEach((item) => {
                item.info = RongIMEmoji.symbolToEmoji(item.info);
                if (
                  item.time >
                  new Date(new Date().toLocaleDateString()).getTime()
                ) {
                  item.time = dateFormat(new Date(item.time), "hh:mm");
                } else if (
                  item.time >
                  new Date(new Date().toLocaleDateString()).getTime() - 86400000
                ) {
                  item.time = "昨天";
                } else {
                  item.time = dateFormat(new Date(item.time), "MM-dd");
                }
              });
              _this.chatList = chatList;
              if (flag && _this.chatList.length > 0) {
                _this.clickItem(_this.chatList[0], 0);
              }
            });
          },
          onError: function (error) {
            console.log("getChatList", error);
          },
        },
        null
      );
    },

    //清除未读方法（聊天列表左侧角标）
    clearCount: function (item) {
      var conversationType = RongIMLib.ConversationType.PRIVATE;
      var targetId = item.targetId;
      RongIMClient.getInstance().clearUnreadCount(conversationType, targetId, {
        onSuccess: function () {},
        onError: function (error) {},
      });
      item.number = "";
    },

    //获取聊天记录
    getDialogueInfo: function (targetId, flag) {
      var _this = this;
      var conversationType = RongIMLib.ConversationType.PRIVATE;
      var targetId = targetId; // 想获取自己和谁的历史消息，targetId 赋值为对方的 Id
      var timestrap = flag ? 0 : null; // 默认传 null，若从头开始获取历史消息，请赋值为 0, timestrap = 0;
      var count = 20; // 每次获取的历史消息条数，范围 0-20 条，可以多次获取
      var data = [];
      _this.loadingArea = false;
      RongIMLib.RongIMClient.getInstance().getHistoryMessages(
        conversationType,
        targetId,
        timestrap,
        count,
        {
          onSuccess: function (list, hasMsg) {
            _this.dialogueInfo.hasMsg = hasMsg;
            list.forEach((item) => {
              console.log(item.s);
              var info = {
                date: formatDate(item.sentTime),
                send: false,
              };
              switch (item.messageType) {
                case "TextMessage":
                  info.text = RongIMEmoji.symbolToEmoji(
                    item.content.content.text
                  );
                  break;
                case "ImageMessage":
                  info.srcList = [item.content.imageUri];
                  info.img = item.content.imageUri;
                  break;
                case "HQVoiceMessage":
                  info.video = item.content.remoteUrl;
                  break;
              }
              if (item.senderUserId == _this.mytargetId) {
                info.other = false;
                info.head = _this.myhead;
                info.name = _this.myname;
              } else {
                info.other = true;
                info.head = _this.dialogueInfo.head;
                info.name = _this.dialogueInfo.name;
              }
              data.push(info);
            });
            _this.dialogueInfo.dialogueList = [
              ...data,
              ..._this.dialogueInfo.dialogueList,
            ];
          },
          onError: function (error) {
            console.log(error);
            console.log("GetHistoryMessages, errorcode:" + error);
          },
        }
      );
    },

    //发送文字方法
    sendTxt: function (txt) {
      var msg = new RongIMLib.TextMessage({
        content: {
          text: txt,
          name: this.myname,
        },
      });
      var conversationType = RongIMLib.ConversationType.PRIVATE; // 单聊, 其他会话选择相应的会话类型即可
      var _this = this;
      var targetId = _this.myKFid; // 目标 Id
      var param = {
        other: false,
        head: _this.myhead,
        text: _this.textarea,
        send: false,
        date: formatDate11(new Date()),
        name: "我",
      };
      RongIMClient.getInstance().sendMessage(conversationType, targetId, msg, {
        onSuccess: function (message) {
          console.log(message);
          _this.dialogueInfo.dialogueList.push(param);
        },
        onError: function (errorCode, message) {
          param.send = true;
          _this.dialogueInfo.dialogueList.push(param);
          console.log("发送失败: " + info + errorCode);
        },
      });
    },
    getMore() {
      this.getDialogueInfo(this.dialogueInfo.targetId, false);
      this.pageNum++;
      this.pageNum1++;
    },
    // 搜索框变化
    searchChange() {
      //初始化的时候，给findList赋值
      if (this.findList.length == 0 && this.chatList.length != 0) {
        this.findList = this.chatList;
      }
      if (this.find == null || this.find == "") {
        this.chatList = this.findList;
        this.findList = [];
      } else {
        var list = [];
        this.findList.forEach((item) => {
          if (item.name.indexOf(this.find) != -1) {
            list.push(item);
          }
        });
        this.chatList = list;
      }
    },
    // 点击发送
    sendTextarea() {
      if (this.textarea != "" && this.textarea != null) {
        this.chatList[this.dialogueInfo.index].info = this.textarea;
        this.chatList[this.dialogueInfo.index].time = formatDate11(new Date());
        this.sendTxt(this.textarea);
        this.textarea = "";
      }
    },
    // 点击出现聊天记录
    clickHistory() {
      this.showHistory = !this.showHistory;
    },
    // 上传前校验
    // beforeAvatarUpload(file) {
    //   const isImage = file.type.includes("image");
    //   const isLt2M = file.size / 1024 / 1024 < 20;
    //   if (!isImage) {
    //     this.$message.error("上传文件类型必须是图片!");
    //   }
    //   if (!isLt2M) {
    //     this.$message.error("上传头像图片大小不能超过 2MB!");
    //   }
    //   return isImage && isLt2M;
    // },
    onExceedFile() {
      // this.$set(fileList[0], 'raw', files[0]);
      // this.$set(fileList[0], 'name', files[0].name);
      // this.$refs['rebateUpload'].clearFiles();//清除文件
    },
    // 上传成功
    handleAvatarSuccess(res, file) {
      var url = res.data.url;
      var info = {
        srcList: [url],
        loading: true,
        img: URL.createObjectURL(file.raw),
        date: dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
        send: false,
        other: false,
        head: this.myhead,
        name: this.myname,
      };
      // this.chatList[this.dialogueInfo.index].info = "[图片]";
      // this.chatList[this.dialogueInfo.index].time = dateFormat(
      //   new Date(),
      //   "hh:mm"
      // );
      this.dialogueInfo.dialogueList.push(info);

      var index = this.dialogueInfo.dialogueList.length;

      var a = 150;
      var base64 = "";
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var Img = new Image();
      Img.setAttribute("crossOrigin", "anonymous");
      Img.src = url;
      Img.onload = function () {
        var width = "";
        var height = "";
        if (Img.width > Img.height) {
          width = a;
          height = (a * Img.height) / Img.width;
        } else {
          width = (a * Img.width) / Img.height;
          height = a;
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(Img, 0, 0, width, height);
        base64 = canvas.toDataURL("image/jpeg");
      };
      var msg = new RongIMLib.ImageMessage({
        content: base64,
        imageUri: url,
      });
      var conversationType = RongIMLib.ConversationType.PRIVATE; // 单聊, 其他会话选择相应的会话类型即可
      var targetId = this.dialogueInfo.targetId; // 目标 Id
      var _this = this;
      RongIMClient.getInstance().sendMessage(conversationType, targetId, msg, {
        onSuccess: function (message) {
          if (_this.dialogueInfo.dialogueList.length >= index) {
            _this.dialogueInfo.dialogueList[index - 1].loading = false;
          }
        },
        onError: function (errorCode, message) {
          console.log("发送失败:" + info + errorCode);
        },
      });
    },
    // 中间聊天框的上滚事件
    handleCenterScroll() {
      // this.getMore();
      this.loadingArea = false;
      const dialogue_box = this.$refs.dialogue_box;
      //这里的定时是为了列表首次渲染后获取scrollHeight并滑动到底部
      setTimeout(() => {
        this.scrollHeight = dialogue_box.scrollHeight;
        dialogue_box.scrollTo(0, this.scrollHeight);
      }, 10);

      dialogue_box.addEventListener("scroll", (e) => {
        //这里的2秒钟定时是为了避免滑动频繁，节流
        setTimeout(() => {
          if (!this.dialogueInfo.hasMsg) {
            this.loadText = "加载完成";
            return;
          }
          //滑到顶部时触发下次数据加载
          if (e.target.scrollTop == 0) {
            //将scrollTop置为10以便下次滑到顶部
            e.target.scrollTop = 10;
            //加载数据
            this.getMore();
            setTimeout(() => {
              e.target.scrollTo(0, this.scrollHeight - 30); //-30是为了露出最新加载的一行数据
            }, 100);
          }
        }, 2000);
      });
    },
    // 右边聊天记录的上滚事件
    handleRightScroll() {
      // this.getMore();
      const dialogue_record = this.$refs.dialogue_record;
      //这里的定时是为了列表首次渲染后获取scrollHeight并滑动到底部
      // setTimeout(() => {
      //   this.scrollHeight = dialogue_record.scrollHeight;
      //   dialogue_record.scrollTo(0, this.scrollHeight);
      // }, 10);
      dialogue_record.addEventListener("scroll", (e) => {
        //这里的2秒钟定时是为了避免滑动频繁，节流
        setTimeout(() => {
          if (!this.dialogueInfo.hasMsg) {
            // this.loadText = "加载完成";
            this.loadText1 = "加载完成";
            return;
          }
          //滑到顶部时触发下次数据加载
          if (e.target.scrollTop == 0) {
            //将scrollTop置为10以便下次滑到顶部
            e.target.scrollTop = 10;
            //加载数据
            this.getMore();
            //这里的定时是为了在列表渲染之后才使用scrollTo。
            setTimeout(() => {
              e.target.scrollTo(0, this.scrollHeight - 30); //-30是为了露出最新加载的一行数据
            }, 100);
          }
        }, 2000);
      });
    },
    // 点击单个表情包
    clickEmoji(emoji) {
      this.textarea += emoji;
      this.$refs.popover.doClose();
    },
    // 关闭对话页面
    close() {
      this.$emit("showOrHide");
    },
    saveImgUrl(event) {
      var URL = null;
      if (window.createObjectURL != undefined) {
        // basic
        URL = window.createObjectURL(event.raw);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        URL = window.URL.createObjectURL(event.raw);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        URL = window.webkitURL.createObjectURL(event.raw);
      }
      this.imgUrl = URL;
    },
    drag() {
      // this.$emit("drag");
    },
  },
};
</script>


<style scoped lang="scss">
.whole-wrapper {
  display: flex;
  background: #fff;
  border-radius: 10px;
}

/* 左边部分 */
.left_whole_wrapper {
  display: flex;
  flex-direction: column;
  width: 24%;
}

.left_wrapper {
  flex: 1;
}

.left-item {
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
}

.active {
  background: #ebebeb;
}

.left-item-left {
  display: flex;
}

.left-item-head > img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.left-item-info {
  margin-left: 12px;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.left-item-info_title {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #292929;
  /* 文字超出处理 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-item-info_content {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #909090;
  margin-top: 4px;
  /* 文字超出处理 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-item-time {
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  margin-left: 20px;
}

.left-item-time-time {
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  color: #909090;
}

.left-item-time-number {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background: #ff4949;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
}

.search {
  padding: 36px 24px;
  height: 5%;
}

/* 中间部分*/
.center_whole_wrapper {
  // position: absolute;
  // right: 0;
  // bottom: 30px;
  width: 400px;
  border-radius: 6px 6px 0px 0px;
  background: #f8f8f8;
  // box-shadow: rgba(0, 0, 0, 0.2) 0 1px 5px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.center_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.center_wrapper_top {
  display: flex;
  height: 80px;
  background-color: #5f79ff;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 20px;
  flex: 1;
  border-radius: 6px 6px 0 0;
  .center_wrapper_top_name {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #fff;
    p {
      font-size: 12px;
      color: inherit;
      margin-top: 7px;
    }
  }
  .close-icon {
    float: right;
    color: inherit;
    cursor: pointer;
    font-size: 34px;
  }
}

.center_wrapper_center {
  // flex: 10;
  padding: 30px 24px 0px 24px;
  // border-top: 1px solid #c7c7c7;
  // border-bottom: 1px solid #c7c7c7;
  height: 420px;
  background-color: #f5faf9;
}

.center_wrapper_center_item {
  display: flex;
  margin-bottom: 32px;
  .center-item-info {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 2px 20px 20px 20px;
    color: #15161a;
    // height: 40px;
    box-sizing: border-box;
    line-height: 1.6;
    padding: 10px;
  }
}

.center_wrapper_right {
  flex: 4;
  padding: 20px 10px 10px 20px;
  position: relative;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
}

.center-item-info_wrapper {
  margin: 0px 16px;
}

.center-item-tip {
  display: flex;
  align-items: center;
}

.error-tip > img {
  width: 20px;
  margin: 0 10px;
}
.error-tip > i {
  color: red;
}
.center-item-info {
  max-width: 336px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #909090;
  line-height: 40px;
  background: #f1f1f1;
  padding: 0 16px;
}

.center-item-time {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #909090;
  line-height: 22px;
  // margin-top: 12px;
}

/* 反向样式开始*/
.center_wrapper_center_item_reserve {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 32px;
}

.center_wrapper_center_item_reserve .center-item-time {
  text-align: right;
}

.center_wrapper_center_item_reserve {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 32px;
  .center-item-info {
    background-color: #5f79ff;
    border: 1px solid #f5f5f5;
    border-radius: 20px 2px 20px 20px;
    color: #fff;
    line-height: 1.6;
    padding: 10px;
    // height: 40px;
    // padding: 13px;
  }
}

.center_wrapper_center_item_reserve .center-item-tip {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  box-sizing: border-box;
}

.center_wrapper_center_item_reserve .error-tip > img {
  width: 20px;
  margin: 0 10px;
}

/* 反向样式结束*/

/* 右边样式 */
.right_whole_wrapper {
  background: #fff;
}

.right_wrapper {
  flex: 1;
}

.right_wrapper_center {
  border: none;
  color: #909090;
}

.center-item-name {
  font-size: 12px;
}

.center-item-info {
  margin-top: 5px;
}

.center-item-info_img {
  max-width: 150px;
  /* height: 100px; */
}
.icon-list .btn_primary {
  text-align: center;
  width: 64px;
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  margin-left: auto !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
  background: #5f79ff;
  border: transparent;
  color: #fff;
}

.center-item-info_img > img {
  width: 100%;
  height: 100%;
}

/* 几个图标 */
.icon-list {
  display: flex;
  align-items: center;
}

.icon-list > div:not(:first-child) {
  margin-left: 15px;
}

.icon-item-img {
  width: 25px;
}

.loading {
  text-align: center;
  color: #909090;
}

.loading span {
  margin-left: 10px;
}
.emoji_icon {
  cursor: pointer;
}
.center_wrapper_center::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
  scrollbar-arrow-color: #15161a;
}
.center_wrapper_center::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
  scrollbar-arrow-color: #15161a;
}
.center_wrapper_center::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 0;
  background: transparent;
}
</style>

