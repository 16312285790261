<template>
  <div class="app">
    <div
      class="mask"
      @touchmove.stop.prevent
      v-if="showMenus && maskDig"
      @click="changeMask"
    ></div>
    <div class="toggleContent">
      <div>
        <img src="@/img/mobile/LOGO@3x.png" class="logoImg" />
      </div>
      <div>
        <img
          src="@/img/mobile/icon_nav@2x.png"
          class="logoRight"
          @click="showMenus"
          v-if="!showMenu"
        />
        <img
          src="@/img/mobile/icon_navx@3x.png"
          class="logoRight"
          v-if="showMenu"
          @click="showMenus"
        />
      </div>
    </div>
    <div class="menuList" v-if="showMenu">
      <div class="listContent">
        <div v-for="(item, index) in menuList" :key="index">
          <div class="menu" @click="pullUp(item)">
            <div class="left">{{ item.title }}</div>
            <div class="right" v-if="item.children">
              <img
                src="@/img/mobile/v_black@3x.png"
                class="icon"
                v-if="!pullList.includes(item)"
              />
              <img src="@/img/mobile/V@3x.png" class="icon" v-else />
            </div>
          </div>
          <div
            class="childrenList"
            v-if="item.children && pullList.includes(item)"
          >
            <div
              class="menu"
              v-for="(val, i) in item.children"
              :key="i"
              @click="pullUp(val)"
            >
              <div class="left">{{ val.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
      maskDig: false,
      menuList: [
        {
          title: "首页",
          path: "/HomeM",
        },
        {
          title: "走进云服",
          path: "/YuServiceM",
        },
        {
          title: "产品与服务",
          path: "/ServicesM",
        },
        {
          title: "解决方案",
          children: [
            { title: "智慧服务", path: "/ProgrammeM" },
            { title: "一站式智慧站点", path: "/ProgrammeBM" },
          ],
        },
        {
          title: "资源下载",
          path: "/SupportM",
        },
        {
          title: "新闻资讯",
          path: "/NewsM",
        },
        {
          title: "关于我们",
          path: "/AboutM",
        },
      ],
      pullList: [],
    };
  },
  methods: {
    changeMask() {
      this.maskDig = false;
      this.showMenu = false;
    },
    showMenus() {
      this.showMenu = !this.showMenu;
      this.maskDig = !this.maskDig;
    },
    pullUp(row) {
      if (row.children) {
        var index = this.pullList.findIndex((item) => item.title == item.title);
        if (index < 0) {
          this.pullList.push(row);
        } else {
          this.pullList.splice(index, 1);
        }
      } else {
        this.$router.push({ path: row.path });
        this.changeMask();
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
.mask {
  position: fixed;
  top: 9rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2022;
}
.toggleContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.4rem 4rem;
  background: #ffffff;
  box-shadow: 0px 0.2rem 0.8rem 0px rgba(184, 190, 204, 0.2);
}
.logoImg {
  width: 27rem;
  height: 5rem;
}

.logoRight {
  width: 4rem;
  height: 4rem;
}
.childrenList {
  background: #f9faff;
  .menu {
    margin: 0 !important;
    margin-left: 10rem !important;
    .left {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  }
}
.app {
  position: fixed;
  width: 100%;
  z-index: 99999999999;
  .menuList {
    background: #ffffff;
    width: 100%;
    position: absolute;
    left: 0rem;
    z-index: 9999999;
    top: 9rem;
    // padding-top: 4rem;
    // padding-left: 6rem;
    .listContent {
      display: flex;
      flex-direction: column;
      padding-bottom: 4rem;
      .menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;
        margin-top: 4rem;
        margin-left: 4rem;
        margin-right: 4rem;
        position: relative;
        img {
          width: 2rem;
          height: 1.2rem;
        }
        .left {
          font-size: 2.8rem;
          font-family: PingFang HK;
          font-weight: 500;
          color: #101635;
        }
      }
    }
    // padding: 4.2rem 4rem 7rem 6rem;
  }
}
</style>
