<template>
  <div class="box">
    <div class="banner">
      <div class="bannerBox">
        <div class="bannerTitle">
          <span class="keyTxt">资源下载</span>
        </div>
        <div class="bannerFront">
          最新的文档、工具及软件的下载。为业务执行与实施提供强有力的帮助，如有遗漏，请联系在线客户。每日更新请保持关注。
        </div>
      </div>
    </div>

    <div class="body">
      <div class="pro-advantage">
        <div class="left">
          <div
            v-for="(item, index) in typeList"
            :class="{ choice: index == nowIndex }"
            :key="index"
            class="choiceBox"
            @click="changeClass(index, item.key, item.name)"
          >
            <!-- <div class="dian" :class="{ choicedian: index == nowIndex }"></div> -->
            <div class="name">{{ item.name }}下载</div>
          </div>
        </div>
        <!-- 文档列表 -->
        <div class="right">
          <div
            v-for="(item, index) in documentList"
            :key="index"
            class="rightBox"
            @click="showDown(item.id)"
          >
            <div class="Rright">
              <div class="title">{{ item.name }}</div>
              <div class="imgBox">
                <img src="@/img/images/support_icon_1@2x.png" />
              </div>
            </div>
            <div class="Rleft">
              <div class="xq">
                <img src="@/img/mobile/more@2x.png" />
              </div>
            </div>
          </div>
          <div v-if="docPage.total > 0" class="pagination-box">
            <el-pagination
              layout="total,prev, pager, next"
              @current-change="changeDocPage"
              :page-size="docPage.pageSize"
              background
              :total="docPage.total"
              :current-page="docPage.pageNum"
              class="pagination"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" @click="concatUs">
      <img src="@/img/index/icon_service@2x.png" />
      在线咨询
    </div>

    <myDig ref="dig" :docData="fileData"></myDig>
    <!-- <el-dialog :visible.sync="showImage" width="900px" :before-close="closede">
      <img style="width: 100%; height: 100%" :src="imageUrl" alt="" />
    </el-dialog> -->
  </div>
</template>

<script>
import API from "@/api/api_surpport";
import myDig from "@/pages/mobile/components/myDig.vue";
export default {
  components: { myDig },
  data() {
    return {
      downVisible: false,
      arr: [
        {
          name: "文档下载",
        },
        {
          name: "工具下载",
        },
        {
          name: "软件下载",
        },
        // {
        // 	name: '资讯情报',
        // },
      ],
      ku: [
        {
          name: "软件漏洞库",
        },
        {
          name: "版本库",
        },
      ],
      nowIndex: 0,
      nowIndex1: 0,
      brr: [1, 2, 3, 4],
      crr: [1, 2],
      activeName: "first",
      input: "",
      tableData: [
        {
          date: "2021-12-06",
          name: "Taocms SQL注入漏洞   ",
          province: "CNNVD-202112-101",
        },
        {
          date: "2021-12-05",
          name: "Renesas Electronics Renesas RX65和RX65N 安全漏洞",
          province: "CNNVD-202112-085",
        },
        {
          date: "2021-12-05",
          name: "WordPress 跨站脚本漏洞      ",
          province: "CNNVD-202112-101",
        },
        {
          date: "2021-12-04",
          name: "Taocms SQL注入漏洞   ",
          province: "CNNVD-202112-101",
        },
        {
          date: "2021-12-04",
          name: "WordPress 跨站脚本漏洞     ",
          province: "CNNVD-202112-085",
        },
        {
          date: "2021-12-03",
          name: "Taocms SQL注入漏洞   ",
          province: "CNNVD-202112-101",
        },
      ],
      names: "",
      // 文档
      fetchParams: {
        con: {
          attachment: "",
          description: "",
          id: 0,
          name: "",
          type: "",
        },
        customerCode: "",
        customerSite: "",
        // pageNum: 1,
        // pageSize: 10,
      },
      docPage: {
        pageNum: 1,
        pageSize: 4,
        total: 0,
      },
      documentList: [],
      docDetail: {},
      typeList: [], //类型
      keyType: "1",
      typeName: "",
      showImage: false,
      fileData: {},
    };
  },
  created() {
    var that = this;
    that.getType();
    that.getResourcesList();
    // that.getToolList();
    // that.getSoftList();
  },
  methods: {
    concatUs() {
      window.open("https://v.egeel.vip/cs/c/46v4xpx6", "_blank");
      setTimeout(() => {
        $('img[type="talk"]').parent().parent().css("z-index", "-1000");
      }, 1000);
    },
    getType() {
      API.findDictionaryList({
        con: {
          key: "resourceType",
        },
      }).then((res) => {
        this.typeList = JSON.parse(res.data);
      });
    },
    // 文档列表
    getResourcesList() {
      var params = {
        con: {
          attachment: "",
          description: "",
          id: 0,
          name: "",
          type: this.keyType,
        },
        customerCode: "",
        customerSite: "",
        pageNum: this.docPage.pageNum,
        pageSize: 4,
      };

      API.resourcesList(params).then((res) => {
        if (res.code == "0000") {
          var templist = res.data.rows;
          // var templist = res.data.rows || [];
          this.documentList = templist;
          this.docPage.total = res.data.total;
        }
      });
    },

    // 分页
    changeDocPage(e) {
      var that = this;
      that.docPage.pageNum = e;
      that.getResourcesList();
    },

    changeClass(index, key, name) {
      var that = this;
      that.nowIndex = index;
      this.keyType = key;
      this.typeName = name;
      that.docPage.pageNum = 1;
      that.getResourcesList();
    },
    handleClick(tab, event) {},
    table(index) {
      this.nowIndex1 = index;
    },
    // 详情 go(row_id)
    // showDown(id) {
    //   this.$refs.dig.showDig(id);
    // },
    async showDown(row_id) {
      var that = this;
      var params = row_id;
      // {
      //   id: ,
      // };
      const res = await API.resourceDetail(params).then((res) => {
        if (res.code == "0000") {
          var templist = res.data;
          templist.attachment = JSON.parse(res.data.attachment);
          this.docDetail = templist;
          this.fileData = res.data;
        }
      });
      this.$refs.dig.showDig();
      // that.downVisible = true;
    },
    downTemp(e) {
      // const params = new URLSearchParams();
      // var e = JSON.parse(demoFile)[0];
      API.downFile({
        fileName: e.name,
        fileUrl: e.url,
      }).then((res) => {
        if (res.resultCode) {
          return;
        }
        var allStr = res;
        var export_blob = new Blob([allStr]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([allStr]), e.name);
        } else {
          var save_link = document.createElement("a");
          save_link.href = window.URL.createObjectURL(export_blob);
          save_link.download = e.name;
          save_link.click();
          // this.$message.success("下载成功！");
          setTimeout(() => {
            // this.$message.success("文件下载成功！");
          }, 500);
        }
      });
    },
    // 新闻咨询详情页
    // go(name, province, data) {
    //   var name = name;
    //   var province = province;
    //   var data = data;
    //   this.$router.push({
    //     // path: '/SupportDetails',
    //     name: `SupportDetails`,
    //     query: {
    //       name: name,
    //       province: province,
    //       data: data,
    //     },
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped="scoped">
// 翻页
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #3e68ff;
}
/deep/ .el-pagination.is-background .el-pager li:hover {
  color: #3e68ff;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  // background-color: #409EFF;
  color: #fff;
}
// 在线客服
.agent {
  position: fixed;
  right: 2rem;
  bottom: 10%;
  z-index: 999;
  bottom: 78px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 46px;
  height: 120px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  line-height: 1.1;
  text-align: center;
  cursor: pointer;

  img {
    width: 31px;
    height: 31px;
  }
  span {
    width: 12px;
    line-height: 1.1;
  }
}
// 资源下载
.down-btn {
  width: 200px;
  height: 56px;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 28px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  // margin-bottom: 20px;
}

.fileBox {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  img {
    width: 22px;
    height: 24px;
    margin-right: 10px;
  }
  .downLoad {
    cursor: pointer;
    color: #3e68ff;
    display: inline-block;
    margin-bottom: 15px;
    padding-top: 2px;
  }
}
.down-body {
  min-height: 260px;
}
/deep/ .el-dialog {
  border-radius: 30px;
  .el-dialog__body {
    padding: 0 64px 48px;
  }
  .dialog-footer {
    text-align: center;
  }
}
.down-box {
  margin-bottom: 88px;
  .title {
    margin-bottom: 35px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    span {
      font-size: 28px;
      font-family: PingFang HK;
      font-weight: 600;
      color: #3d4566;
    }
  }
  p {
    font-size: 14px;
    font-family: PingFang HK;
    font-weight: 400;
    color: #3d4566;
    line-height: 1.7;
    // padding: 0 10px;
  }
}
.close_img {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
.box {
  // padding-bottom: 100px;
  width: 100%;
  padding-top: 10rem;
  /* border: 1px solid red; */
}

.banner {
  // padding-top: 8.7rem;
  background: #eceff8;
  background: url(@/img/mobile/ziyuan2@2x.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  padding-top: 8.7rem;
  padding-bottom: 47.1rem;
  margin: 0 auto;
  width: 87.2%;
  text-align: center;
}
.bannerTitle {
  font-size: 4.2rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  padding-bottom: 3.2rem;
}
.keyTxt {
  // color: #3e68ff;
  color: #3d4566;
}
.bannerFront {
  width: 74%;
  margin: 0 auto;
  font-size: 2.8rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  padding: 0;
  line-height: 1.8;
  text-align: justify;
  text-align-last: center;
}
.body {
  width: 100%;
  margin: 0px auto;
  padding: 6rem 0 10.1rem;
  // padding: 8rem 0 20.9rem;

  // min-width: 820px;
  // /* border: 1px solid red; */
}
.pro-advantage {
  box-sizing: border-box;
  width: 91.47%;
  margin: 0 auto;
}
.bodyBox {
  padding: 4rem 4.9rem 4rem 3rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  background: #f4f8fb;
  border-radius: 0.6rem;
  margin-bottom: 3rem;
}

.left {
  display: flex;
  justify-content: space-between;
  padding: 0 4.1rem;
}

.choiceBox {
  box-sizing: border-box;
  padding: 2.6rem 3.3rem;
  display: flex;
  align-items: center;
  // background: rgba(62, 104, 255, 0.06);
  cursor: pointer;
}

// .dian {
//   width: 8px;
//   height: 8px;
//   background: linear-gradient(90deg, #ccd7ff, #f0f3ff);
//   border-radius: 50%;
//   margin: 28px 0 28px 40px;
// }

// .choicedian {
//   width: 8px;
//   height: 8px;
//   background: #ffffff;
//   border-radius: 50%;
//   margin: 28px 0 28px 40px;
// }

.name {
  font-size: 3rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
}

.choice {
  .name {
    color: #fff !important;
  }

  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 0.3rem 0.4rem 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 5rem;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 4.8rem;
}

.rightBox {
  box-sizing: border-box;
  padding: 4.8rem 3rem 5.1rem 4.1rem;
  background: #ffffff;
  border: 1px solid #e6ebfa;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  cursor: pointer;
}
.rightBox:hover {
  // box-shadow: 4px 7px 20px 0px rgba(55, 99, 170, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(184, 190, 204, 0.16);
  animation: load 0.4s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.rightBoxH {
  height: 210px;
}

.imgBox {
  width: 30px;
  height: 30px;
  // margin: 41px 0 0 31px;
}

.imgBox img {
  width: 100%;
  height: 100%;
}

.Rright {
  width: 100%;
}

.title {
  // margin: 36px 0 0 0px;
  font-size: 3rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3d4566;
  margin-bottom: 4rem;
}

.xq {
  // font-size: 16px;
  // font-family: PingFang HK;
  // font-weight: 400;
  // color: #8f94b2;
  // line-height: 32px;
  // cursor: pointer;
}

.xq img {
  width: 8rem;
  height: 8rem;
  // margin-top: 10px;
  // margin-left: 3px;
}

.mid {
  width: 200%;
  border-bottom: 1px solid #e6ebfa;
  height: 46px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  /* line-height: 32px; */
  margin-top: 19px;
}

.zxqbHead {
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  height: 40px;
}

.zxqbChoiceBox {
  /* border: 1px solid red; */
  display: flex;
}

.zxqb1 {
  text-align: center;
  width: 100px;
  line-height: 30px;
  margin-right: 20px;
  height: 30px;
  color: #8f94b2;
  cursor: pointer;
  border-bottom: 1px solid #8f94b2;
}

.inputBox {
  margin-left: 250px;
  width: 400px;
  display: flex;
}

.table {
  width: 100%;
  /* border: 1px solid blue; */
  margin-top: 40px;
}

.zxqbChoice {
  color: #3e68ff;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 600;
  border-bottom: 2px solid #3e68ff;
  /* line-height: 44px; */
  cursor: pointer;
}

td {
  height: 64px;
  background: #ffffff;
  border: 1px solid #e6ebfa;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 28px;
  padding-left: 20px;
}

th {
  text-align: left;
  padding-left: 20px;
  height: 40px;
  background: #f0f3ff;
  border: 1px solid #e6ebfa;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 44px;
}

.td1 {
  width: 440px;
  cursor: pointer;
}

.td2 {
  width: 280px;
  cursor: pointer;
}

.td3 {
  width: 182px;
  cursor: pointer;
}
// 分页
.pagination-box {
  width: 100%;
  .pagination {
    text-align: right;
    padding-top: 2rem;
    // padding: 2rem 0 0 1rem;
  }
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #4970ff;
}
</style>
