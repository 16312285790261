<template>
  <div class="box">
    <div class="banner">
      <img src="../img/images/news_banner.png" />
      <div class="bannerBox">
        <div class="bannerTitle">
          <span class="keyTxt">新闻资讯</span>
        </div>
        <div class="bannerFront">服务成就卓越，记录精彩，创享未来！</div>
      </div>
    </div>
    <!-- :class="Animate1 ? 'body myanimation' : 'body'"
      ref="animateBox" -->
    <div class="body">
      <!-- documentList -->
      <div v-for="(item, index) in documentList" :key="index" class="bodyBox">
        <div class="left">
          <div class="leftBox">
            <div class="day">{{ item.day }}</div>
            <div class="time">{{ item.date }}</div>
          </div>
        </div>

        <div class="right" @click="go(item.id)">
          <div class="rightHead">{{ item.title }}</div>
          <div class="rightBody" v-imgtoken="item.content"></div>
          <!-- <div class="rightBody" v-html="item.content"></div> -->
          <!-- @click="go(item.name, item.province, item.date) -->
          <div class="rightFoot">
            <div class="ckxq">查看详情</div>

            <img class="rightImg" src="../img/all_icon_arrow_grey.png" />
            <!-- <div class="rightImg"></div> -->
          </div>
        </div>
      </div>
      <div v-if="docPage.total > 0" class="pagination-box">
        <el-pagination
          layout="total,prev, pager, next"
          @current-change="changeDocPage"
          :page-size="docPage.pageSize"
          background
          :total="docPage.total"
          :current-page="docPage.pageNum"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" onClick="__egeelTalk()">
      <img src="../img/index/icon_service@2x.png" />
      <span>在线咨询</span>
    </div>
    <DetailDialog
      :visible="newsVisible"
      :newsData="newsDetail"
      @cancel="newsVisible = false"
    />
  </div>
</template>

<script>
import { isElementNotInViewport } from "../common/utils.js";
import API from "@/api/api_surpport";
import DetailDialog from "./children/DetailsDialog.vue";
// 引入富文本编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor, Quill } from "vue-quill-editor";
export default {
  components: {
    DetailDialog,
    quillEditor,
  },
  data() {
    return {
      Animate1: false,
      newsVisible: false,
      data: [],
      titleArr: [
        {
          name: "微软 SQL Server 2008/R2 将停止支持",
        },
        {
          name: "运维资讯：SD-WAN和运维的那点事",
        },
        {
          name: "阿特斯太阳能与高盛签订长期运维服务协议",
        },
        {
          name: "阿特斯太阳能与高盛签订长期运维服务协议",
        },
      ],
      // 文档
      fetchParams: {
        con: {
          attachment: "",
          description: "",
          id: 0,
          name: "",
          type: "",
        },
        customerCode: "",
        customerSite: "",
        // pageNum: 1,
        // pageSize: 10,
      },
      docPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      documentList: [],
      newsDetail: {},
    };
  },
  created() {
    var that = this;
    that.getResourcesList();
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.animateBox);
    },

    getResourcesList() {
      var params = {
        con: {
          content: "",
          id: 0,
          title: "",
        },
        customerCode: "",
        customerSite: "",
        pageNum: this.docPage.pageNum,
        pageSize: 10,
      };
      API.newsList(params).then((res) => {
        if (res.code == "0000") {
          var templist = res.data.rows;
          templist.forEach((item) => {
            // item.updatedDate = item.updatedDate.replace(/-/g, "/").slice(0, 10);
            // item.date = item.updatedDate.slice(0, 7);
            // item.day = item.updatedDate.slice(8, 10);
            // pushDate
            item.pushDate = item.pushDate.replace(/-/g, "/").slice(0, 10);
            item.date = item.pushDate.slice(0, 7);
            item.day = item.pushDate.slice(8, 10);
          });
          // var templist = res.data.rows || [];
          this.documentList = templist;
          this.docPage.total = res.data.total;
        }
      });
    },

    // 分页
    changeDocPage(e) {
      var that = this;
      that.docPage.pageNum = e;
      that.getResourcesList();
    },
    // 详情
    go(row_id) {
      var that = this;
      API.newsDetail({ id: row_id }).then((res) => {
        if (res.code == "0000") {
          this.newsDetail = res.data;
        }
      });
      that.newsVisible = true;
    },
    // 新闻资讯详情 弹窗
    // go(name, province, data) {
    //   this.data = data;
    //   this.newsVisible = true;
    //   // var name = name;
    //   // var province = province;
    //   // var data = data;
    // },
  },
};
</script>

<style lang="scss" scoped="scoped">
// 翻页
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #3e68ff;
}
/deep/ .el-pagination.is-background .el-pager li:hover {
  color: #3e68ff;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  // background-color: #409EFF;
  color: #fff;
}
// 新闻body
.myanimation {
  animation: load 0.6s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 20px;
  bottom: 10%;
  z-index: 999;
  top: 465px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  height: 140px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  cursor: pointer;

  img {
    width: 41px;
    height: 41px;
  }
  span {
    width: 16px;
    line-height: 1.1;
  }
}
.box {
  background-color: #fbfcff;
  padding-bottom: 148px;
}

.banner {
  width: 1903px;
  height: 400px;
  padding-top: 70px;
  background: #eceff8;
  // padding-top: 70px;
  // background: url(../img/index/index_banner@2x.png) no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  /* height: 100%; */
  width: 63%;
  margin: 0 auto;
  // width: 750px;
  // padding: 0px 0 0 360px;

  /* border: 1px solid red; */
  margin-top: -310px;
}
.bannerTitle {
  font-size: 48px;
  font-family: Source Han Sans SC;
  font-weight: 800;
  color: #101635;
}
.keyTxt {
  color: #3e68ff;
}
.bannerFront {
  /* width: 410px;
  height: 18px; */
  width: 63%;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #101635;
  padding: 26px 15px 58px 0;
  line-height: 1.7;
}
.body {
  width: 63%;
  // padding: 0 360px;
  margin: 0px auto;
  padding-top: 80px;
  min-width: 820px;
  /* border: 1px solid red; */
}
.bodyBox {
  width: 100%;
  display: flex;
}
.leftBox {
  width: 152px;
  height: 152px;
  background: #f0f3ff;
  text-align: center;
  margin-top: 40px;
}
.day {
  padding-top: 54px;
  font-size: 24px;
  font-family: Mont;
  font-weight: normal;
  color: #3d4566;
}
.time {
  font-size: 14px;
  font-family: Mont;
  font-weight: normal;
  color: #8f94b2;
}
.right {
  width: 940px;
  /* border: 1px solid yellow; */
  margin-left: 108px;
  padding: 40px 0;
  border-bottom: 1px solid #ebebeb;
  .rightBody {
    /deep/ p {
      img {
        width: 20% !important;
        height: 20% !important;
      }
    }
  }
}
.right:hover {
  cursor: pointer;
  .rightHead,
  .ckxq {
    color: #3e68ff;
  }
}
.rightHead {
  font-size: 20px;
  color: #3d4566;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 44px;
}
.rightBody {
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3d4566;
  line-height: 32px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
}
.rightFoot {
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ckxq {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 30px;
  margin-right: 17px;
}
.rightImg {
  width: 14px;
  height: 8px;
  // margin-top: 8px;
  // margin-left: 20px;
  text-align: center;
  img {
  }
}
// 分页
.pagination-box {
  width: 100%;
  .pagination {
    text-align: right;
    padding-right: 22px;
    padding-top: 68px;
  }
}
@media screen and (max-width: 479px) {
  .box {
    width: 230%;
  }
  .banner {
    width: 100%;
    height: 130px;
  }
  .bannerBox {
    height: 100%;
    padding: 0px 0 0 20px;
    margin-top: -110px;
  }
  .bannerImg {
    width: 100%;
    height: 100%;
  }
}
</style>
