<template>
  <div class="box">
    <div class="banner">
      <div class="bannerBox">
        <div class="bannerTitle">
          <div class="keyTxt">公司简介</div>
          <div class="enTitle">introduction</div>
        </div>
        <!-- <div class="bannerFront">
          最新的文档、工具及软件的下载。为业务执行与实施提供强有力的帮助，如有遗漏，请联系在线客户。每日更新请保持关注。
        </div> -->
      </div>
      <div class="intro-box">
        <p class="p-one">
          全栈云服(重庆)科技有限公司成立于2021年，总部位于重庆市两江新区大竹林街道星光大道62号海王星科技园内。
        </p>
        <p>
          全栈云服向客户提供专业、高效、安全的信息化统一闭环服务，自主研发云服平台软件，联接了用户、生态伙伴、技术专家等，实现对客户资产、服务、工单、质量等的统一管理，满足客户及时获得专业标准的服务，平台构建行业服务标准体系，通过IT手段拉通各类技术工程师和专家，让技术专家通过远程落地客户。
        </p>
      </div>
    </div>

    <div class="content">
      <!-- 公司简介 -->
      <!-- <div class="contentbox contentbox1 contentbox3">
        <div class="contentTitlebox">
          <div class="contentTitle" style="padding-top: 82px">公司简介</div>
          <div class="contentTitleS">introduction</div>
          <div class="pro-advantage pro-intro">
            <div class="intro-box">
              <p class="p-one">
                全栈云服(重庆)科技有限公司成立于2021年，总部位于重庆市两江新区大竹林街道星光大道62号海王星科技园内。
              </p>
              <p>
                全栈云服向客户提供专业、高效、安全的信息化统一闭环服务，自主研发云服平台软件，联接了用户、生态伙伴、技术专家等，实现对客户资产、服务、工单、质量等的统一管理，满足客户及时获得专业标准的服务，平台构建行业服务标准体系，通过IT手段拉通各类技术工程师和专家，让技术专家通过远程落地客户。
              </p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="contentbox contentbox1">
        <div class="contentTitlebox">
          <div class="contentTitle">企业文化</div>
          <div class="contentTitleS">CORPORATE CULTURES</div>
        </div>
        <!-- 可提供的服务 -->
        <!-- 企业文化 -->
        <div class="pro-advantage">
          <div class="monitor">
            <div class="monitor-content" style="margin-bottom: 3.1rem">
              <div class="contentBox">
                <div><img src="@/img/images/about_icon1@2x.png" /></div>

                <div class="contentRight">
                  <div class="title">使命</div>
                  <div class="monitor-time">
                    专注与解决ICT基础资源领域，致力于提供专业、高效、安全的ICT全栈服务平台，为客户解决问题。
                  </div>
                </div>
              </div>
            </div>
            <div class="monitor-content" style="margin-bottom: 3.1rem">
              <div class="contentBox">
                <div><img src="@/img/images/about_icon2@2x.png" /></div>

                <div class="contentRight">
                  <div class="title">愿景</div>
                  <div class="monitor-time">让优质服务触手可及。</div>
                </div>
              </div>
            </div>
            <div
              class=""
              :class="
                Animate1 ? 'monitor-content myanimation' : 'monitor-content'
              "
              ref="oneBox"
              style="margin-bottom: 3.1rem"
            >
              <div class="contentBox">
                <div><img src="@/img/images/about_icon3@2x.png" /></div>

                <div class="contentRight">
                  <div class="title">理念</div>
                  <div class="monitor-time">
                    以技术服务人才为本，依托数字化技术和服务体系构建信息化服务领域价值链、供应链枢纽，打造信息化服务产业链核心平台。
                  </div>
                </div>
              </div>
            </div>
            <div
              class=""
              :class="
                Animate1 ? 'monitor-content myanimation' : 'monitor-content'
              "
              ref="oneBox"
              style="margin-bottom: 3.1rem"
            >
              <div class="contentBox">
                <div><img src="@/img/images/about_icon4@2x.png" /></div>

                <div class="contentRight">
                  <div class="title">责任</div>
                  <div class="monitor-time">
                    为客户创造价值，提高效率，降低成本。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 企业资质 -->
      <div class="contentbox contentbox1 contentbox2" style="padding: 0">
        <div
          class=""
          :class="Animate2 ? 'contentTitlebox myanimation1' : 'contentTitlebox'"
          ref="twoBox"
        >
          <div class="contentTitle">企业资质</div>
          <div class="contentTitleS">ENTERPRISE QUALIFICATION</div>
          <div class="pro-qual">
            <!-- <div class="img-box" v-for="(item, index) in imgArr" :key="index">
              <div class="img">
                <img :src="item.image" />
              </div>
            </div> -->

            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide img-box"
                  v-for="(item, index) in imgArr"
                  :key="index"
                >
                  <div class="img">
                    <img :src="item.image" />
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <!-- <div class="swiper">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <swiper :options="swiperOption" ref="mySwiper" class="swiper">
                    <swiper-slide
                      v-for="(item, index) in imgArr"
                      :key="index"
                      class="img-box"
                    >
                      <div class="img">
                        <img :src="item.image" />
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>

                <div class="swiper-scroll-bar"></div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- 合作伙伴 -->
      <div class="contentbox contentbox1 contentbox4">
        <div
          class=""
          :class="Animate3 ? 'contentTitlebox myanimation' : 'contentTitlebox'"
          ref="threeBox"
        >
          <div class="contentTitle">联系我们</div>
          <div class="contentTitleS">Contact us</div>
        </div>
        <!-- :class="Animate3 ? 'contentTitlebox myanimation' : 'contentTitlebox'"
          ref="threeBox" -->
        <div
          class=""
          :class="
            Animate4
              ? 'pro-advantage contact myanimation1'
              : 'pro-advantage contact'
          "
          ref="fourBox"
        >
          <div class="pro-qual pro-contact">
            <div class="contact-way">
              <div><img src="@/img/images/contac_1@2x.png" /></div>
              <div>support@fullstackcs.com</div>
            </div>
            <div class="contact-way">
              <div><img src="@/img/images/contact_icon2@2x.png" /></div>
              <div>023-65792053</div>
            </div>
            <div class="contact-way">
              <div><img src="@/img/images/contact_icon3@2x.png" /></div>
              <div>重庆市两江新区大竹林街道星光大道62号海王星科技园内</div>
            </div>
          </div>
          <!-- 引入地图 -->
          <!-- <div class="map" id="map"></div> -->
          <baidu-map
            :center="center"
            :zoom="zoom"
            @ready="handler"
            class="map"
            ak="QrKw6BpO274b6mXQ5T1uSrMt2kZGLE5E"
          >
            <bm-marker
              :position="{ lng: 106.516346, lat: 29.620283 }"
              :dragging="true"
              :icon="{
                url: require('@/img/images/icon_location@2x.png'),
                backgroundPosition: center,
                top: 0,
                size: { width: 20.5, height: 23.5 },
                opts: {
                  imageSize: { width: 20.5, height: 23.5 },
                },
              }"
              animation="BMAP_ANIMATION_BOUNCE"
            >
            </bm-marker
          ></baidu-map>
        </div>
      </div>
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" @click="concatUs">
      <img src="@/img/index/icon_service@2x.png" />
      在线咨询
    </div>
  </div>
</template>

<!-- <script type="text/javascript" src="https://api.map.baidu.com/api?v=2.0&ak=QrKw6BpO274b6mXQ5T1uSrMt2kZGLE5E"></script> -->
<script>
import Swiper from "@/static/js/swiper.js";
import "@/assets/swiper.css";
import { isElementNotInViewport } from "@/common/utils.js";
// import { Swiper } from "swiper";
// import { swiper, swiperSlide } from "vue-awesome-swiper";

import "../../../node_modules/swiper/dist/css/swiper.css";
import image1 from "@/img/images/client.jpg";
import image2 from "@/img/images/IT.jpg";
import image3 from "@/img/images/jingying.jpg";
import image4 from "@/img/images/cuiruoxing.jpg";

import { BaiduMap, BmMarker } from "vue-baidu-map";
export default {
  name: "App",
  components: {
    Swiper,

    BaiduMap,
    BmMarker,
  },
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      center: { lng: 0, lat: 0 },
      zoom: 3,
      imgArr: [
        {
          image: image1,
        },
        {
          image: image2,
        },
        {
          image: image3,
        },
        {
          image: image4,
        },
      ],
      // 轮播
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 25,
        loop: false,
        nextButton: ".swiper-button-next", //绑定下一页的控制按钮
        prevButton: ".swiper-button-prev", //绑定上一页的控制按钮
        autoplay: false,
        // 显示分页
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true, //允许分页点击跳转
        // },
        // 设置点击箭头
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      },
    };
  },
  computed: {
    // swiper() {
    //   // return this.$refs.mySwiper.swiper;
    // },
  },

  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    var mySwiper = new Swiper(".swiper-container", {
      loop: true,
      centeredSlides: true,
      slidesPerView: "auto",
      initialSlide: 1,
      pagination: {
        el: ".swiper-pagination",
      },
    });
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    concatUs() {
      window.open("https://v.egeel.vip/cs/c/46v4xpx6", "_blank");
    },
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.oneBox);
      this.Animate2 = !isElementNotInViewport(this.$refs.twoBox);
      this.Animate3 = !isElementNotInViewport(this.$refs.threeBox);
      this.Animate4 = !isElementNotInViewport(this.$refs.fourBox);
    },
    handler({ BMap, map }) {
      // this.center.lng = 113.953477;
      // this.center.lat = 22.551217;
      this.center.lng = 106.516;
      this.center.lat = 29.62036;
      this.zoom = 17;
    },
    // 最新新闻-查看详情
    viewDetail() {},
  },
};
</script>

<style lang="scss" scoped="scoped">
//产品优势 title
.myanimation {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.myanimation1 {
  animation: load1 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load1 {
  0% {
    opacity: 0;
    transform: translateY(150px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 2rem;
  bottom: 10%;
  z-index: 999;
  bottom: 78px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 46px;
  height: 120px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  line-height: 1.1;
  text-align: center;
  cursor: pointer;

  img {
    width: 31px;
    height: 31px;
  }
  span {
    width: 12px;
    line-height: 1.1;
  }
}
.BMap_Marker {
  div {
    position: absolute;
    top: 0 !important;
  }
}
.box {
  // padding-bottom: 100px;
  width: 100%;
  /* border: 1px solid red; */
}

.banner {
  position: relative;
  // padding-top: 8.7rem;
  background: #eceff8;
  background: url(@/img/mobile/TOPBG@2x.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  padding-top: 18.7rem;
  padding-bottom: 57.9rem;
  margin: 0 auto;
  width: 87.2%;
  text-align: center;
}
.bannerTitle {
}
.keyTxt {
  font-size: 4.2rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  padding-bottom: 2rem;
  color: #3d4566;
}
.enTitle {
  font-size: 2.4rem;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #ffffff;
}
// .bannerFront {
//   width: 74%;
//   margin: 0 auto;
//   font-size: 2.8rem;
//   font-family: PingFang HK;
//   font-weight: 400;
//   color: #8f94b2;
//   padding: 0;
//   line-height: 1.8;
//   text-align: justify;
//   text-align-last: center;
// }
.intro-box {
  box-sizing: border-box;
  position: absolute;
  top: 32.5rem;
  left: 4.265%;
  padding: 6.4rem 6.8rem 9.4rem;
  width: 91.47%;
  margin: 0 auto;
  // transform: translateY(-51.5rem);
  border-radius: 0.4rem;
  background: #ffffff;
  font-size: 2.8rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3d4566;
  text-align: left;
  p {
    line-height: 1.7;
  }
  .p-one {
    margin-bottom: 3rem;
  }
}
.title1 {
  font-size: 32px;
  font-family: DOUYU;
  font-weight: normal;
  color: #ffffff;
  margin: 168px 0 0 360px;
}

.title2 {
  margin: 22px 0 0 359px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 300;
  color: #ffffff;
}

.content {
  width: 100%;
  background: #f7f9fd;
  padding-bottom: 8rem;
}

.btn {
  width: 144px;
  height: 48px;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 4px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 24px;
  margin: 59px 0 0 360px;
}

.dian {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  float: left;
  margin: 20px 0 0 20px;
}

.font {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  margin-right: 60px;
}

.More {
  margin-left: 51px;
}

.contentTitle {
  font-size: 4.2rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  // line-height: 44px;
  // padding-top: 98px;
  /* margin-top: 98px; */
}

.contentTitleS {
  font-size: 2.4rem;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #8f94b2;
  margin-top: 2rem;
}

.contentBox {
  // margin-top: 91px;
  // display: flex;
  // flex-wrap: wrap;
}

.contentBoxS {
  height: 170px;
  width: 210px;
  margin: 0 50px 0 50px;
}

.contentBoxSs {
  margin-left: 20%;
  display: flex;
}
.contentbox1 {
  text-align: center;
  margin: 0 auto;
  width: 91.47%;
  padding: 28.9rem 0 12.2rem;
  // height: 700px;
  // background: #eef6ff;
}
.contentbox2 {
  width: 100%;
  .pro-qual {
    margin-top: 5.8rem;
    margin-bottom: 12rem;
  }
}
.contenttitle {
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  line-height: 44px;
}

.contentDetails {
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 30px;
  text-align: left;
}
// 公司简介
// .contentbox3 {
//   height: 540px;
//   padding-top: 70px;
//   background-image: url("~@/img/images/about_banner.png");
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   background-position: center center;
// }
// .pro-intro {
//   padding-top: 35px !important;
// }

/* 可提供的服务 */
.pro-advantage {
  width: 100%;
  margin: 0 auto;
}
.monitor {
  margin-top: 6.3rem;
  display: flex;
  flex-direction: column;
}
.monitor-content {
  box-sizing: border-box;
  display: flex;
  // flex-direction: column;
  padding: 2.2rem 5.6rem 5.2rem 1.7rem;
  width: 100%;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 2px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    4px 7px 20px 0px rgba(55, 99, 170, 0.1);
  border-radius: 0.6rem;
}
.contentBox {
  display: flex;
  justify-content: space-between;
  img {
    width: 7.2rem;
    height: 7.2rem;
  }
}
// .contentTop {
//   // padding: 0 5px 13px;
//   height: 55px;
//   line-height: 55px;
//   .title {
//     font-size: 20px;
//     font-family: PingFang HK;
//     font-weight: 500;
//     color: #3d4566;
//   }
// }
.contentRight {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 1.1rem;
  margin-left: 2.4rem;
  .title {
    font-size: 3.2rem;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
  }
}
.monitor-time {
  line-height: 1.8;
  font-size: 2.6rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #3d4566;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  padding-top: 2.7rem;
}
//  企业资质
.pro-qual {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.contact {
  box-sizing: border-box;
  margin-top: 6.4rem;
  padding: 2.4rem 4rem 5.6rem;
  // padding: 2.4rem 4rem 16.6rem;
  background: #ffffff;
  box-shadow: 0px 2rem 6rem 0px rgba(184, 190, 204, 0.2);
  border-radius: 0.6rem;
  // border: 1px solid red;
}
// TODO
.img-box {
  box-sizing: border-box;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 2px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    8px 14px 60px 0px rgba(55, 99, 170, 0.16);
  border-radius: 6px;
  border: 2px solid #ffffff;
  border-radius: 6px;
  text-align: center;
  .img {
    // width: 231px;
    // height: 343px;
    width: 44rem;
    height: 63rem;
    margin: auto;
    padding: 18px 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.swiper-container {
  width: 100%;
  padding-bottom: 6.8rem;
  // border: 1px solid red;
}
.swiper-slide {
  width: 62%;
  transform: scale(0.85);
}

.swiper-slide-active {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/deep/.swiper-pagination-bullet {
  background: #d6ecff !important;
  opacity: 1;
}
/deep/.swiper-pagination-bullet-active {
  background: #4070f1 !important;
}
.pro-qual .swiper {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.swiper-wrapper {
  // display: flex;
  // justify-content: space-between !important;
  // position: relative;
  // overflow: hidden;
  .img-box:nth-child(4) {
    // margin-left: 0;
    margin-right: 35px !important;
  }
  .swiper-container {
    overflow: hidden !important;
    padding: 30px;
  }
}
.swiper-container {
  overflow: hidden;
  // overflow: initial !important;
}
.pro-qual .swiper .swiper-button-prev,
.pro-qual .swiper .swiper-button-next {
  width: 64px;
  height: 64px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 50%;
  right: -80px;
  background-size: 11px 20px;
  background-repeat: no-repeat;
  background-position: 9px 30px;
}
.pro-qual .swiper .swiper-button-prev {
  left: -81px !important;
}
.swiper-button-disabled {
  opacity: 0.35 !important;
  cursor: auto !important;
  pointer-events: none !important;
}
.pro-qual .swiper .swiper-button-prev {
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2027%2044%27%3E%3Cpath%20d%3D%27M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z%27%20fill%3D%27%233E68FF%27%2F%3E%3C%2Fsvg%3E);
  background-position: center center;
}

.pro-qual .swiper .swiper-button-next {
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2027%2044%27%3E%3Cpath%20d%3D%27M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z%27%20fill%3D%27%233E68FF%27%2F%3E%3C%2Fsvg%3E);
  background-position: center center;
}

// 联系我们
.contentbox4 {
  padding: 0;
  .pro-contact {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 4.9rem;
    .contact-way {
      padding: 4rem 0;
      display: flex;
      align-items: center;
      text-align: left;
      line-height: 1.8;
      border-bottom: 1px solid #ebebeb;
      div {
        font-size: 2.6rem;
        font-family: PingFang HK;
        font-weight: 400;
        color: #3d4566;
      }
      img {
        width: 6.4rem;
        height: 6.4rem;
        margin-right: 2rem;
      }
    }
  }
  .map {
    width: 100%;
    height: 28rem;
    // background: red;
  }
}
.cloudBox {
  width: 55%;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  margin: 80px auto;
  justify-content: space-between;
}

.cloudLeft {
  width: 250px;
  height: 200px;
  /* margin-left: 10%; */
}

.cloudLeft img {
  width: 100%;
  height: 100%;
}

.cloudRight {
  /* border: 1px solid; */
  width: 543px;
  height: 176px;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 32px;
  /* margin-left: 179px; */
}

.cooperationBanner {
  display: flex;
  width: 100%;
  height: 500px;
  background-image: url(@/img/index_part3_logos_png.png);
}

.cooperationBox {
  width: 524px;
  height: 284px;
  background: #f9faff;
  /* border: 1px solid red; */
  text-align: center;
  margin: 108px auto;
  /* 不透明度 */
  opacity: 0.8;
}

.cooperationTitle {
  margin-top: 94px;
  font-size: 28px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  /* line-height: 44px; */
}

.cooperationTitleEnglish {
  margin-top: 13px;
  font-size: 14px;
  font-family: JI-Sludge;
  font-weight: 600;
  color: #8f94b3;
  /* line-height: 44px; */
}

.cooperationFoot {
  margin-top: 29px;
  font-size: 12px;
  font-family: PingFang HK;
  font-weight: 600;
  color: #3d4566;
  /* line-height: 44px; */
}
</style>
