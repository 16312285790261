<template>
  <div class="box">
    <div class="banner">
      <div class="bannerBox">
        <div class="bannerTitle">
          <span class="keyTxt">产品与服务</span>
        </div>
        <div class="bannerFront">
          一站式服务平台+专业化服务体系，全方位解决客户ICT管理问题。
        </div>
        <img src="@/img/mobile/proTOPBG@2x.png" />
      </div>
    </div>
    <div class="content" style="background: #fbfcff">
      <!-- 产品优势 -->
      <div class="products">
        <div class="pro-introduce">
          <!-- <div class="pro-title">
            <div class="pro-font">产品介绍</div>
            <div class="pro-subtitle">PRODUCT INTRODUCTION</div>
          </div> -->
          <!-- <div v-if="teps == 1" class="pro-content">
            <div class="content-bg"></div>
            <div style="font-size: 20px">拓展平台</div>
            <div style="color: #8f94b2; margin-top: 20px; line-height: 2">
              拓展平台计算机网络的拓扑结构是引用拓扑学中研究与大小，形状无关的点、线关系的方法。把网络中的计算机和通信设备抽象为一个点，把传输介质抽象为一条线，由点和线组成的几何图形就是计算机网络的拓扑结构。网络的拓扑结构反映出网中各实体的结构关系，是建设计算机网络的第一步，是实现各种网络协议的基础，它对网络的性能，系统的可靠性与通信费用都有重大影响。拓扑在计算机网络中即是指连接各结点的形式与方法。
              把网络中的工作站和服务器等网络单元抽象为“点”。网络中的电缆等抽象为“线”。影响网络性能、系统可靠性、通信费用。
            </div>
          </div> -->
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide pro-content"
                v-for="(item, index) in manageArr"
                :key="index"
              >
                <div class="content-bg">
                  <img src="@/img/index_icon_part2_icon2.png" alt="" />
                </div>
                <div class="title">{{ item.title }}</div>
                <div class="line"></div>
                <div class="content">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>

          <!-- <div v-if="teps == 2" class="pro-content">
            <div class="content-bg"></div>
            <div class="title">展示平台</div>
            <div class="content">
              数据可视化展示平台，以表格、图表等多种方式对企业的数据进行统计和分析，并直观的提现，帮助企业挖掘数据中的价值，保证企业数据管理有效运转，协助企业经营者辅助决策。
            </div>
          </div>
          <div v-if="teps == 3" class="pro-content">
            <div class="content-bg"></div>
            <div class="title">安管平台</div>
            <div class="content">
              对设备进行标准化以及规范化的管理，24小时实时监测设备状态，设备异常智能告警，根据设备和应用进行在线巡检、漏洞扫描、补丁修复等安全措施。
            </div>
          </div>
          <div v-if="teps == 4" class="pro-content">
            <div class="content-bg"></div>
            <div class="title">资产平台</div>
            <div class="content">
              帮助医院实现各类资产的统一管理，并对医疗设备从科室入出库、维修保养到报废处置进行全生命周期的数字化管理，全面满足医院规范管理和质量控制的更多要求，建立资产和耗材备件台账，规范资产借用、归还、转科、维修、报废等处置流程。
            </div>
          </div>
          <div v-if="teps == 5" class="pro-content">
            <div class="content-bg"></div>
            <div class="title">工单平台</div>
            <div class="content">
              基于智能化、数字化的工单平台，支持全程服务可视、可管、可回溯，实现服务商、云端专家快速响应。支持基于通话自动创建工单，支持一键创建工单；支持工单服务内容互动、服务内容确认，快速完成服务内容；做到服务全程留痕、信息充分共享、进度实时掌握。
            </div>
          </div> -->
        </div>
      </div>
      <div class="pro-advantage">
        <!-- <img src="@/img/mobile/proDECObg@2x.png" alt="" class="adimg" /> -->
        <div class="advantageCont">
          <div
            :class="Animate1 ? 'adTitle myanimation1' : 'adTitle'"
            ref="proTitleBox"
          >
            <div class="pro-font">产品优势</div>
            <div class="pro-subtitle">ADVANTAGES</div>
          </div>
          <div
            class=""
            :class="Animate2 ? 'monitor myanimation' : 'monitor'"
            ref="animateBox"
          >
            <div class="monitor-content">
              <div>
                <img src="@/img/index_icon_part2_icon2.png" alt="" />
              </div>
              <div>
                <div class="monitor-title">服务标准化</div>
                <div class="monitor-time">
                  参照ITSS等标准，及服务专家经验，将ICT服务过程进行标准化拆解，形成标准服务目录，实现服务过程标准化管理。
                </div>
              </div>
            </div>
            <div class="monitor-content">
              <div>
                <img src="@/img/index_icon_part2_icon2.png" alt="" />
              </div>
              <div>
                <div class="monitor-title">流程可视化</div>
                <div class="monitor-time">
                  依托于平台强大的工单系统，联接用户、生态服务伙伴及云端专家服团队。实现全过程可视、可管、可控、可考核、可追溯，保障服务质量。
                </div>
              </div>
            </div>
            <div class="monitor-content">
              <div>
                <img src="@/img/index_icon_part2_icon2.png" alt="" />
              </div>
              <div>
                <div class="monitor-title">服务专业化</div>
                <div class="monitor-time">
                  联接客户、生态服务伙伴及云端专家团队，实现专业服务快速可达，使客户可低成本地享受贴身的、快速的、可靠的的ICT专业服务。
                </div>
              </div>
            </div>
            <div class="monitor-content">
              <div>
                <img src="@/img/index_icon_part2_icon2.png" alt="" />
              </div>
              <div>
                <div class="monitor-title">管理智能化</div>
                <div class="monitor-time">
                  通过对服务过程评价的统计，可灵活对生态服务伙伴服务周期内的服务质量，进行综合智能评估，并向用户提供服务报表；通过支持智慧项目、需求及排班管理，实现服务全流程智慧管理。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 流程介绍 -->
      <div class="process">
        <div
          :class="Animate3 ? 'adTitle myanimation' : 'adTitle'"
          ref="ceTitleBox"
        >
          <div class="pro-font">申请使用流程介绍</div>
          <div class="pro-subtitle">PROCEDURE</div>
        </div>
        <div
          class=""
          :class="Animate4 ? 'procedure myanimation' : 'procedure'"
          ref="procedureBox"
        >
          <div class="left-box">
            <img src="@/img/mobile/product_left_img@2x.png" alt="" />
          </div>

          <div class="apply">
            <div class="tip" v-for="item in applyList" :key="item.id">
              <div class="circular">
                {{ item.id }}
                <div class="dot-line"></div>
              </div>
              <div>{{ item.tip }}</div>
            </div>
            <!-- <div class="dotted-box"></div> -->
          </div>
        </div>
        <div class="apply-btn" @click="concatUs">申请试用</div>
      </div>
    </div>
    <!-- 在线客服 暂时隐藏-->
    <div class="agent" @click="concatUs">
      <img src="@/img/index/icon_service@2x.png" />
      在线咨询
    </div>
    <ContactDialog :visible="contactVisible" @cancel="contactVisible = false" />
  </div>
</template>

<script>
import Swiper from "@/static/js/swiper.js";
import "@/assets/swiper.css";
import { isElementNotInViewport } from "@/common/utils.js";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import ContactDialog from "./children/ContactDialog.vue";
export default {
  components: {
    Swiper,
    ContactDialog,
    // swiper,
    // swiperSlide,
  },
  data() {
    return {
      // 轮播
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 25,
        loop: false,
        nextButton: ".swiper-button-next", //绑定下一页的控制按钮
        prevButton: ".swiper-button-prev", //绑定上一页的控制按钮
        autoplay: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      contactVisible: false,
      teps: 1,
      list: ["监控平台", "展示平台", "安管平台", "资产平台", "工单平台"],
      applyList: [
        {
          id: "01",
          tip: "申请使用流程介绍",
        },
        {
          id: "02",
          tip: "填写公司相关资料",
        },
        {
          id: "03",
          tip: "获取测试账号",
        },
        {
          id: "04",
          tip: "产品试用",
        },
        {
          id: "05",
          tip: "签约合同",
        },
        {
          id: "06",
          tip: "系统部署安装",
        },
        {
          id: "07",
          tip: "获取授权",
        },

        {
          id: "08",
          tip: "正式使用产品",
        },
      ],
      manageArr: [
        {
          title: "监控平台",
          content:
            "监控平台主要为应用监控和设备监控，对企业的关键业务的应用和设备进行监测，实时获取应用和设备的健康信息、性能信息，提高企业应用和设备的可用性，保证关键核心业务的正常运转。",
        },
        {
          title: "展示平台",
          content:
            "数据可视化展示平台，以表格、图表等多种方式对企业的数据进行统计和分析，并直观的提现，帮助企业挖掘数据中的价值，保证企业数据管理有效运转，协助企业经营者辅助决策。",
        },
        {
          title: "资产平台",
          content:
            " 帮助医院实现各类资产的统一管理，并对医疗设备从科室入出库、维修保养到报废处置进行全生命周期的数字化管理，全面满足医院规范管理和质量控制的更多要求，建立资产和耗材备件台账，规范资产借用、归还、转科、维修、报废等处置流程。",
        },
        {
          title: "工单平台",
          content:
            "基于智能化、数字化的工单平台，支持全程服务可视、可管、可回溯，实现服务商、云端专家快速响应。支持基于通话自动创建工单，支持一键创建工单；支持工单服务内容互动、服务内容确认，快速完成服务内容；做到服务全程留痕、信息充分共享、进度实时掌握。",
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
      // return this.$refs.bannerSwiper.swiper;
    },
  },
  created() {
    var that = this;

    that.test();
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.$nextTick(() => {
      var mySwiper = new Swiper(".swiper-container", {
        loop: false,
        centeredSlides: false,
        slidesPerView: "auto",
        initialSlide: 1,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination",
        },
      });
    });
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1 = !isElementNotInViewport(this.$refs.proTitleBox);
      this.Animate2 = !isElementNotInViewport(this.$refs.animateBox);
      this.Animate3 = !isElementNotInViewport(this.$refs.ceTitleBox);
      this.Animate4 = !isElementNotInViewport(this.$refs.procedureBox);
    },
    concatUs() {
      window.open("https://v.egeel.vip/cs/c/46v4xpx6", "_blank");
    },
    test() {
      // document
      //   .getElementsByTagName("head")[0]
      //   .appendChild(document.createElement("script")).src =
      //   (location.protocol.toLocaleLowerCase() == "https:"
      //     ? "https:"
      //     : "http:") + "//v.fmkefu.com/cs/js?u=4482_4535&c=auto";
      setTimeout(() => {
        $('img[type="talk"]').parent().parent().css("z-index", "-1000");
      }, 1000);
    },
    // 平台右边内容切换
    showContent(item, index) {
      this.teps = index;
    },
    go() {
      this.$router.push({ path: "/Expert" });
    },
    // 在线客服
    showAgent() {
      var that = this;
      that.contactVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
//产品优势 title
.myanimation {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.myanimation1 {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
// 在线客服
.agent {
  position: fixed;
  right: 2rem;
  bottom: 10%;
  z-index: 999;
  bottom: 78px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 46px;
  height: 120px;
  background: linear-gradient(180deg, #f3f5f8, #ffffff);
  border: 1px solid #ffffff;
  box-shadow: -4px -7px 20px 0px #ffffff,
    0px 10px 30px 0px rgba(56, 89, 207, 0.2);
  border-radius: 28px;
  padding: 8px 8px 0;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3e68ff;
  line-height: 1.1;
  text-align: center;
  cursor: pointer;

  img {
    width: 31px;
    height: 31px;
  }
  span {
    width: 12px;
    line-height: 1.1;
  }
}
.box {
  /* padding-bottom: 100px; */
  width: 100%;
  padding-top: 10rem;
  /* border: 1px solid red; */
}

.banner {
  position: relative;
  background: linear-gradient(
    130deg,
    #d7e3fc,
    #e4effc,
    #dceffc,
    #d2eefc,
    #e6f1fc
  );
  // background: url(@/img/mobile/TOPBG@2x.png) no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
}
.banner img {
  width: 100%;
  height: 100%;
}
.bannerBox {
  padding-top: 8.7rem;
  padding-bottom: 10rem;
  margin: 0 auto;
  width: 76.3%;
  text-align: center;
}
.bannerTitle {
}
.keyTxt {
  font-size: 4.2rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  padding-bottom: 2rem;
  color: #3d4566;
}
.enTitle {
  font-size: 2.4rem;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #ffffff;
}
.bannerFront {
  width: 82.2%;
  margin: 0 auto;
  margin-top: 3.1rem;
  margin-bottom: 14rem;
  font-size: 2.8rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 1.8;
  text-align: justify;
  text-align-last: center;
  padding-bottom: 4rem;
}
.contentbox {
  width: 100%;
  height: 222px;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
}
.contentBox {
  margin-top: 91px;
  display: flex;
}
.teps {
  padding: 0 368px;
  // width: 54%;
  /* height: 0; */
  // margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.tepsBox {
  height: 104px;
  width: 75px;
  /* border: 1px solid red; */
  text-align: center;
  cursor: pointer;
}
.tepsImgs {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
.tepsImgs img {
  position: relative;
  top: 14px;
}
.tepsImg {
  border: 1px solid #3e68ff;
}
.choiceImg {
  background: #fafbff;
  box-shadow: 0px 10px 20px 0px rgba(184, 188, 204, 0.3);
  border-radius: 50%;
}
.tepsName {
  width: 73px;
  height: 17px;
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
  line-height: 44px;
}
.lineS {
  background-color: #e6ebff;
  height: 2px;
  width: 100px;
  margin: 35px 23px 0px 23px;
}
.line {
  background-color: #3e68ff;
  /* height: 2px;
	width: 100px;
	margin: 35px 23px 0px 23px; */
}
.tepsFootBox {
  height: 24px;
  width: 55.2%;
  border-left: 1px solid #e6ebff;
  border-bottom: 1px solid #e6ebff;
  border-right: 1px solid #e6ebff;
  /* border: 1px solid red; */
  margin: 15px auto;
  border-radius: 1px;
}
.products {
  box-sizing: border-box;
  // overflow-x: hidden;
  position: relative;
  width: 100%;
  background: #eef1f7;
  padding-bottom: 63.1rem;
  // padding-left: 3.2rem;
}
.pro-introduce {
  position: absolute;
  /* padding: 90px 0; */
  // /* width: 63%; */
  top: -10rem;
  margin: 0 auto;
  display: flex;
  // margin-top:10rem;
  z-index: 99;
  flex-direction: column;
}
.adTitle {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.pro-subtitle {
  font-size: 2.4rem;
  font-family: JI-Sludge;
  font-weight: 400;
  color: #8f94b2;
  margin-top: 2.1rem;
}
.pro-font {
  font-size: 4.4rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
}
.pro-content {
  box-sizing: border-box;
  position: relative;
  width: 89.3% !important;
  background: #ffffff;
  box-shadow: 0px 3rem 9rem 0px rgba(184, 190, 204, 0.3);
  border-radius: 2rem;
  padding: 11.5rem 5.8rem 16.1rem 4rem;
  position: relative;
  z-index: 1;
  .title {
    font-size: 3.6rem;
    font-family: PingFang HK;
    font-weight: 500;
    color: #3d4566;
  }
  .line {
    width: 4rem;
    height: 0.6rem;
    margin: 2.9rem 0 3rem;
    background: #3e68ff;
  }
  .content {
    line-height: 1.8;
    font-size: 2.6rem;
    font-family: PingFang HK;
    font-weight: 400;
    color: #8f94b2;
    text-align: justify;
  }
}
.content-bg {
  // display: inline-block;
  // width: 161px;
  // height: 200px;
  // background-image: url("@/img/index_icon_part2_icon2.png");
  box-sizing: border-box;
  display: table-cell;
  position: absolute;
  left: 4rem;
  top: -5rem;
  width: 10rem;
  height: 10rem;
  line-height: 10rem;
  text-align: center;
  background: #fafbff;
  box-shadow: 0px 1.6rem 4rem 0px rgba(194, 202, 232, 0.3);
  border-radius: 50%;
  img {
    width: 6rem;
    height: 6rem;
    vertical-align: middle;
  }
  // z-index: -1;
}
.pro-advantage {
  width: 100%;
  margin: 0 auto;
  background: url(@/img/mobile/proDECObg@2x.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.adimg {
  position: relative;
  z-index: 0;
  height: 56rem;
}
.advantageCont {
  width: 91.47%;
  margin: 0 auto;
  padding: 14rem 0 6rem;
}
.monitor {
  margin-top: 61px;
  display: flex;
  flex-direction: column;
}
.monitor-content {
  box-sizing: border-box;
  display: flex;
  padding: 4.9rem 4rem 5rem;
  background: #ffffff;
  margin-bottom: 3rem;
  background: linear-gradient(180deg, #f5f7fa, #ffffff);
  border: 0.1rem solid #ffffff;
  box-shadow: -4px -7px 20px 0px rgba(255, 255, 255, 0.5),
    4px 7px 20px 0px rgba(55, 99, 170, 0.1);
  border-radius: 1.2rem;
}
.monitor-content img {
  margin-right: 4rem;
  width: 4.4rem;
  height: 4.4rem;
}
.monitor-title {
  margin-bottom: 2.9rem;
  font-size: 3.2rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #3d4566;
}
.monitor-time {
  font-size: 2.6rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #8f94b2;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* 流程 */
.process {
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  padding: 11.9rem 0 0;
  // padding: 11.9rem 0 12.9rem;
  .pro-subtitle {
    margin-top: 2.1rem !important;
  }
}
.procedure {
  box-sizing: border-box;
  position: relative;
  width: 91.47%;
  margin: 0 auto;
  margin-top: 6.5rem;
  background: #f7fafe;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  padding: 5rem 0 2.3rem 4.6rem;
  .left-box {
    position: absolute;
    right: 0;
    bottom: 0;
    img {
      width: 48rem;
      height: 40rem;
    }
  }
}
/* 按钮 */
.apply-btn {
  // position: absolute;
  // left: 103px;
  // bottom: 36px;
  margin: 0 auto;
  margin-top: 3.8rem;
  margin-bottom: 13rem;
  width: 20rem;
  height: 8rem;
  line-height: 8rem;
  background: linear-gradient(0deg, #3e68ff, #5c7fff);
  box-shadow: 0px 3px 8px 0px rgba(62, 104, 255, 0.3),
    0px 1px 3px 0px rgba(91, 127, 255, 0.4);
  border-radius: 4rem;
  font-size: 2.8rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.apply {
  position: relative;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  // width: 368px;
  // height: 280px;
  // border: 1px dotted #5f79ff;
  // border-top: none;
  // border-radius: 0px 0px 20px 20px;
  // margin-top: 50px;

  .tip {
    display: flex;
    align-items: center;
    margin-bottom: 6.3rem;
    font-size: 2.8rem;
    font-family: PingFang HK;
    font-weight: 400;
    color: #3d4566;
  }
  .dotted-box {
    // position: absolute;
    // width: 363px;
    // height: 242px;
    // border: 1px dotted #5f79ff;
    // border-top: none;
    // border-radius: 0px 0px 20px 20px;
    // left: 151px;
    // top: 22px;
  }
}
.circular {
  position: relative;
  width: 4.2rem;
  height: 4.2rem;
  line-height: 4.2rem;
  background: #4971ff;
  border-radius: 50%;
  font-size: 2.2rem;
  font-family: Mont;
  font-weight: normal;
  color: #f7fafe;
  text-align: center;
  // margin: 0 42px 0 140px;

  margin-right: 1.9rem;
}
.dot-line {
  position: absolute;
  width: 0.1rem;
  height: 6rem;
  border-left: 0.1rem dotted #4971ff;
  left: 2rem;
}
.apply > .tip:last-child .dot-line {
  display: none;
}
.apply-content {
  width: 598px;
  height: 48px;
}

// 团队轮播
.swiper-container {
  width: 100vw;
  padding-bottom: 3.8rem;
  // border: 1px solid red;
}
.swiper-slide {
  // width: 68%;
  width: 56rem !important;
  height: 64rem !important;
  transform: scale(0.85);
}

// /deep/.swiper-slide-active {
//   -webkit-transform: scale(1);
//   -moz-transform: scale(1);
//   -ms-transform: scale(1);
//   -o-transform: scale(1);
//   transform: scale(1);
// }
</style>
