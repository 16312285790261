export const isElementNotInViewport = function(el) {
    if (el) {
        let rect = el.getBoundingClientRect();
        // alert(rect.top, 'rect.toprect.toprect.toprect.toprect.top')
        return (
            rect.top >=
            (window.innerHeight || document.documentElement.clientHeight)
            // || rect.bottom <= 0
        );
    }
};